import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

import PayloadInterface from './payload.type'

export interface ExternalViewsData {
  title: string
  url: string
}

export interface ExternalViewInterface {
  id: string
  content: ExternalViewsData
  created: {
    date: string
    timezone: string
    timezone_type: number
  }
}

export interface ExternalViewsPayload extends PayloadInterface {
  data: Array<ExternalViewInterface>
}

type ExternalViewsType = {
  projectName: string
}

export const externalViewsApi = createApi({
  reducerPath: 'externalViewsApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getExternalViews: builder.query<ExternalViewInterface, ExternalViewsType>({
      query: (params: ExternalViewsType) => ({
        url: `/v1/${params.projectName}/external-views${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

const processExternalViewsData = (data: Array<ExternalViewInterface>) => {
  const externalViewsList: Array<ExternalViewsData> = data.map(
    (viewData: ExternalViewInterface) => {
      const tour: ExternalViewsData = {
        title: viewData?.content?.title,
        url: viewData?.content?.url,
      }
      return tour
    }
  )
  return externalViewsList
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  externalViewsData: processExternalViewsData(data?.data || []),
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetExternalViewsQuery } = externalViewsApi
