import React from 'react'

export interface OptionsInerface {
  value: string | number
  label: string | number
}

export interface badgeGroupProps {
  label?: string
  options: Array<OptionsInerface>
  defaultChecked: string
  name: string
  color?: string
  getSelectedValue: (arg: any) => void
}

const BadgeGroup = ({
  label,
  options,
  defaultChecked,
  name,
  color = 'white',
  getSelectedValue,
}: badgeGroupProps) => {
  const [selected, setSelected] = React.useState<any>(defaultChecked)

  React.useEffect(() => {
    setSelected(defaultChecked)
  }, [defaultChecked])

  return (
    <div className="my-5 flex items-center ipad-l:my-8">
      <span className={`text-${color} mr-auto text-lg`}>{label}</span>
      <div className="grid grid-cols-2 gap-y-10">
        {options.map((res: OptionsInerface) => (
          <label className="badge mr-2" key={res.label}>
            <input
              type="radio"
              name={`badge-group-${name}`}
              value={res.value}
              onChange={() => {
                setSelected(res.value)
                getSelectedValue(res.value)
              }}
              checked={res.value === selected}
            />
            <span className="md:text-sm">{res.label}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

export default BadgeGroup
