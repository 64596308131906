import React from 'react'

import type { Configurations, ProjectIdentity } from '@store/types'
import { UnitFilterInterface } from '@store/types'

import Range from '@components/range'
import { ChevronDownSvg, CloseCircleSvg, CompassSvg } from '@components/svg'

import FirebaseControlQuery from '@utilities/firebase-control-query'

export interface ConfigPopupProps {
  projectIdentity: ProjectIdentity
  isOpen: boolean
  toggle: (arg: boolean) => void
  unitFilter: UnitFilterInterface
  aspects: Array<string>
}

const Filter = ({
  projectIdentity,
  isOpen,
  toggle,
  unitFilter,
  aspects,
}: ConfigPopupProps): React.ReactElement => {
  const rangeRef = React.useRef<{ reset: () => void }>()

  const { configurations } = unitFilter

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [isAspectOpen, toggleAspect] = React.useState(false)

  const handleConfigurations = (conf: string, opt: 'sub' | 'add') => {
    let count = configurations[conf as keyof Configurations]
    if (opt === 'add' && count < 5) {
      count += 1
    }
    if (opt === 'sub' && count > 0) {
      count -= 1
    }
    firebaseControlQuery.updateCollection(
      `building.unitFilter.configurations.${conf}`,
      count
    )
  }

  const handleOnlyShowAvailable = (isAvailable: boolean) =>
    firebaseControlQuery.updateCollection(
      'building.unitFilter.showAvailable',
      isAvailable
    )

  const resetFilter = async (projectValues: ProjectIdentity) =>
    firebaseControlQuery.update({
      [`building.unitFilter`]: {
        availability: 'available',
        range: {
          min: projectValues.prices.min,
          max: projectValues.prices.max,
        },
        configurations: {
          bedroom: 0,
          study: 0,
          bathroom: 0,
          powderRoom: 0,
          carpark: 0,
        },
        aspect: 'All',
        showAvailable: false,
        apply: false,
      },
    })

  const applyFilter = () =>
    firebaseControlQuery.updateCollection('building.unitFilter.apply', true)

  const handleAspect = (aspect: string) =>
    firebaseControlQuery.updateCollection('building.unitFilter.aspect', aspect)

  const handleRangeOnchange = (value: { max: number; min: number }) => {
    firebaseControlQuery.updateCollection('building.unitFilter', {
      ...unitFilter,
      range: value,
    })
  }

  React.useEffect(() => {
    resetFilter(projectIdentity)
  }, [projectIdentity.prices.max, projectIdentity.prices.min])

  return (
    <div
      className={`transform-all absolute top-0 rounded bg-white shadow-2xl duration-200 ease-in-out ${
        isOpen ? 'w-full p-4' : 'w-0 overflow-hidden'
      }`}
    >
      <div className="flex items-center justify-center overflow-hidden pl-5">
        <span className="mr-auto font-GTWalsheimPro text-xl">Filter</span>
        <button
          onClick={async () => {
            toggle(false)
            toggleAspect(false)
          }}
          type="button"
          className="flex items-center"
        >
          <CloseCircleSvg
            size="s"
            className="cursor-pointer"
            fill="hsl(1, 0%, 0%)"
          />
        </button>
      </div>
      <div className="h-full pl-5 pt-5 font-GTWalsheimPro">
        {projectIdentity.showPrice && (
          <div className="flex h-28 items-center justify-center overflow-hidden px-5 shadow shadow-sm">
            <Range
              ref={rangeRef}
              min={projectIdentity.prices.min}
              max={projectIdentity.prices.max}
              minValue={unitFilter?.range.min}
              maxValue={unitFilter?.range.max}
              onChange={handleRangeOnchange}
            />
          </div>
        )}
        <div className="mb-2 mt-5 overflow-hidden">
          <span className="text-xl">Configuration</span>
        </div>
        <div className="h-auto overflow-hidden shadow shadow-sm">
          <div className="grid grid-cols-3 gap-4 py-5">
            <div className="text-left">Bedroom</div>
            <div className="text-center text-sky-500">
              {unitFilter.configurations.bedroom || 'Any'}
            </div>
            <div className="flex items-center justify-center text-center">
              <button
                onClick={() => {
                  handleConfigurations('bedroom', 'sub')
                }}
                type="button"
                className="rounded-l-full border border-gray-300 px-3"
              >
                -
              </button>
              <button
                onClick={() => {
                  handleConfigurations('bedroom', 'add')
                }}
                type="button"
                className="rounded-r-full border border-gray-300 px-3"
              >
                +
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 py-5">
            <div className="text-left">Study</div>
            <div className="text-center text-sky-500">
              {unitFilter.configurations.study || 'Any'}
            </div>
            <div className="flex items-center justify-center text-center">
              <button
                onClick={() => {
                  handleConfigurations('study', 'sub')
                }}
                type="button"
                className="rounded-l-full border border-gray-300 px-3"
              >
                -
              </button>
              <button
                onClick={() => {
                  handleConfigurations('study', 'add')
                }}
                type="button"
                className="rounded-r-full border border-gray-300 px-3"
              >
                +
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 py-5">
            <div className="text-left">Bathroom</div>
            <div className="text-center text-sky-500">
              {unitFilter.configurations.bathroom || 'Any'}
            </div>
            <div className="flex items-center justify-center text-center">
              <button
                onClick={() => {
                  handleConfigurations('bathroom', 'sub')
                }}
                type="button"
                className="rounded-l-full border border-gray-300 px-3"
              >
                -
              </button>
              <button
                onClick={() => {
                  handleConfigurations('bathroom', 'add')
                }}
                type="button"
                className="rounded-r-full border border-gray-300 px-3"
              >
                +
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 py-5">
            <div className="text-left">Powder Room</div>
            <div className="text-center text-sky-500">
              {unitFilter.configurations.powderRoom || 'Any'}
            </div>
            <div className="flex items-center justify-center text-center">
              <button
                onClick={() => {
                  handleConfigurations('powderRoom', 'sub')
                }}
                type="button"
                className="rounded-l-full border border-gray-300 px-3"
              >
                -
              </button>
              <button
                onClick={() => {
                  handleConfigurations('powderRoom', 'add')
                }}
                type="button"
                className="rounded-r-full border border-gray-300 px-3"
              >
                +
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 py-5">
            <div className="text-left">Car Park</div>
            <div className="text-center text-sky-500">
              {unitFilter.configurations.carpark || 'Any'}
            </div>
            <div className="flex items-center justify-center text-center">
              <button
                onClick={() => {
                  handleConfigurations('carpark', 'sub')
                }}
                type="button"
                className="rounded-l-full border border-gray-300 px-3"
              >
                -
              </button>
              <button
                onClick={() => {
                  handleConfigurations('carpark', 'add')
                }}
                type="button"
                className="rounded-r-full border border-gray-300 px-3"
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div className="relative w-full">
          <button
            onClick={() => toggleAspect(!isAspectOpen)}
            type="button"
            className="relative mt-5 flex w-full items-center px-5 text-gray-500 shadow shadow-sm"
          >
            <div className="flex items-center">
              <CompassSvg fill="gray" size="xs" deg="135deg" />
              <span>
                {unitFilter.aspect === 'All' ? 'Aspect' : unitFilter.aspect}
              </span>
            </div>
            <ChevronDownSvg
              className="transform-all ml-auto duration-500 ease-in-out"
              rotate={isAspectOpen ? 'right' : 'left'}
              size="lg"
            />
          </button>
          <ul
            className="absolute left-0 top-12 w-full overflow-hidden overflow-y-auto rounded bg-white text-left shadow transition-all duration-500 ease-in-out"
            style={{
              height: isAspectOpen ? `${(aspects.length + 1) * 40}px` : '0px',
              maxHeight: '160px',
            }}
          >
            <li className="border border-gray-100">
              <button
                type="button"
                className="w-full p-2 pl-16 text-left"
                onClick={() => {
                  handleAspect('All')
                  toggleAspect(false)
                }}
              >
                All
              </button>
            </li>
            {aspects.map((aspect) => (
              <li key={aspect} className="border border-gray-100">
                <button
                  type="button"
                  className="w-full p-2 pl-16 text-left"
                  onClick={() => {
                    handleAspect(aspect)
                    toggleAspect(false)
                  }}
                >
                  {aspect}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex w-full items-center overflow-hidden px-5 py-4">
        <input
          checked={unitFilter.showAvailable}
          onChange={(event) => handleOnlyShowAvailable(event.target.checked)}
          type="checkbox"
          className="mr-2 appearance-none rounded checked:border-transparent checked:bg-sky-500"
        />{' '}
        <span className="text-base text-gray-500">Only Show Available</span>
      </div>
      <div className="flex w-full overflow-hidden px-5 pb-5">
        <button
          onClick={async () => {
            await resetFilter(projectIdentity)
            rangeRef?.current?.reset()
          }}
          type="button"
          className="bold mr-auto"
        >
          Reset all filters
        </button>
        <button
          disabled={unitFilter.apply}
          onClick={applyFilter}
          type="button"
          className={`${
            unitFilter.apply ? 'bg-gray-500' : 'bg-sky-500'
          } bold rounded px-3 py-2 text-white`}
        >
          {unitFilter.apply ? 'Filter Applied' : 'Apply Filters'}
        </button>
      </div>
    </div>
  )
}

export default Filter
