import React from 'react'

import { DataNotFound, SomethingWentWrong } from './errors'

export interface Payload {
  isLoaded: boolean
  isError: boolean
  status: string
  data: any
}

export interface DataHandlerProps {
  children: React.ReactNode
  payload: Payload
  message?: string
}

const DataHandler = ({
  children,
  payload,
  message = 'This page needs to be setup',
}: DataHandlerProps) => {
  const UNINITIALIZED_STATUS = 'uninitialized'
  const PENDING_STATUS = 'pending'
  const FULFILLED_STATUS = 'fulfilled'
  const REJECTED_STATUS = 'rejected'

  const [isLoaded, setIsLoaded] = React.useState(false)
  const [hasData, sethasData] = React.useState(true)

  const checkData = (data: any) => {
    if (!data) {
      sethasData(false)
      return false
    }
    if (Array.isArray(data)) {
      sethasData(data.length > 0)
      return data.length > 0
    }
    if (typeof data === 'object') {
      return Object.keys(data).length > 0
    }
    sethasData(true)
    return true
  }

  const handleInitialLoad = () => {
    const { status, data } = payload
    if (status === FULFILLED_STATUS || status === REJECTED_STATUS) {
      setIsLoaded(true)
      checkData(data)
    }
  }

  const handleRouteSwitch = () => {
    const { status, data } = payload
    if (status === UNINITIALIZED_STATUS && checkData(data)) {
      setIsLoaded(true)
    }
  }

  React.useEffect(() => {
    handleInitialLoad()
    handleRouteSwitch()
  }, [payload])

  if (!isLoaded && !payload.isError) {
    return (
      <div className="fixed inset-0 z-50 bg-gray-100 bg-opacity-50">
        <div
          style={{ borderTopColor: 'transparent' }}
          className="border-white-400 absolute left-50% top-50% h-16 w-16 animate-spin rounded-full border-4 border-solid text-center"
        ></div>
      </div>
    )
  }

  if (isLoaded && !hasData && !payload.isError) {
    return <DataNotFound message={message} />
  }

  if (isLoaded && !hasData && payload.isError) {
    return <SomethingWentWrong />
  }

  return <>{children}</>
}

export default DataHandler
