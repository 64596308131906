import React, { FormEvent } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setRoom } from '@store/actionSlices/appConfig'
import { setProjectIdentity } from '@store/actionSlices/project'
import { setToken } from '@store/actionSlices/token'
import { setUser } from '@store/actionSlices/user'
import { RootStateType } from '@store/store'
import { ProjectIdentity } from '@store/types'

import Container from '@components/container'

import {
  ProjectPayload,
  TokenUserPayloadInterface,
  useAuthUserMutation,
  useAuthViaTokenMutation,
} from '@api/authentication'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import InputHandler from '@utilities/form-util'
import { getQueryStringParams } from '@utilities/helper'

export interface LoginProps {
  projectIdentity: ProjectIdentity
}

const Login = ({ projectIdentity }: LoginProps) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [autoAuth, setAutoAuth] = React.useState(false)

  const [formValid, setFromValid] = React.useState(false)

  const [authFail, setAuthFail] = React.useState(false)

  const [isLoading, setIsLoading] = React.useState(false)

  const [projectId, setProjectId] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [password, setPassword] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [email, setEmail] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [authenticateUser, { data, error }] = useAuthUserMutation()
  const [authenticateViaToken, { data: tokenData, error: tokenError }] =
    useAuthViaTokenMutation()

  const triggerLogin = () =>
    authenticateUser({
      project_canonical_name: projectId.value,
      password: password.value,
      email: email.value,
    })

  React.useEffect(() => {
    if (
      !password.isValid ||
      !email.isValid ||
      !password.value ||
      !email.value ||
      isLoading
    ) {
      setFromValid(false)
      return
    }
    setFromValid(true)
  }, [password, email, isLoading])

  const authProcedure = (
    { refresh_token, access_token }: TokenUserPayloadInterface,
    { id, canonical_name }: ProjectPayload
  ) => {
    const searchQuery = getQueryStringParams(location.search)
    setIsLoading(false)
    dispatch(setToken({ refresh_token, access_token }))
    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        projectUUID: id,
        projectId: canonical_name,
        sessionId: searchQuery?.sessionKey || '',
        disconnect: searchQuery?.disconnect
          ? searchQuery?.disconnect === 'true'
          : true,
      })
    )
    if (searchQuery.room) dispatch(setRoom(searchQuery.room))
  }

  const login = async () => {
    setIsLoading(true)
    if (projectId.value) {
      const exists = await firebaseControlQuery.doesProjectExist(
        projectId.value
      )
      if (exists) {
        triggerLogin()
        return
      }
    }
    setIsLoading(false)
    setAuthFail(true)
  }

  const submitAuthForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    login()
  }

  const autoAuthenticate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { authenticate } = getQueryStringParams(location.search)
    if (authenticate && projectId) {
      setIsLoading(true)
      authenticateViaToken({ token: authenticate, projectId: projectId.value })
    }
  }

  React.useEffect(() => {
    if (tokenData) {
      const {
        data: { user, project },
      } = tokenData
      if (user) {
        authProcedure(user, project)
        dispatch(setUser({ name: user.name, email: user.email }))
      }
    }
  }, [tokenData])

  React.useEffect(() => {
    if (data) {
      const {
        data: { user, project },
      } = data
      if (user) {
        authProcedure(user, project)
        dispatch(setUser({ name: user.name, email: user.email }))
      }
    }
  }, [data])

  React.useEffect(() => {
    if (error) {
      setAuthFail(true)
      setIsLoading(false)
    }
  }, [error])

  React.useEffect(() => {
    if (tokenError) {
      setAuthFail(true)
      setIsLoading(false)
    }
  }, [tokenError])

  React.useEffect(() => {
    const paths = location.pathname.split('/').filter((res) => res)
    const { authenticate } = getQueryStringParams(location.search)
    if (paths.length && authenticate) {
      setProjectId({
        ...projectId,
        value: paths[0],
      })
      setFromValid(true)
      setAutoAuth(true)
    }
  }, [])

  return (
    <Container className="xs:p-10 sm:p-10 md:p-0">
      <div className="grid h-screen w-full place-items-center">
        <form
          className="basic-white-form"
          onSubmit={autoAuth ? autoAuthenticate : submitAuthForm}
        >
          <div className="mb-4 text-center font-GTWalsheimPro">
            Showcase Controller
          </div>
          {!autoAuth && (
            <>
              <div className="mb-4">
                <label className="input-label" htmlFor="projectId">
                  Display ID
                </label>
                <input
                  onChange={(e) => InputHandler(e, setProjectId)}
                  className={`focus:shadow-outline focus:outline-none ${
                    projectId.isValid ? 'input-gray' : 'input-gray-error'
                  }`}
                  id="display-id"
                  type="text"
                  placeholder="id-development"
                  value={projectId.value}
                  required
                  disabled={isLoading || autoAuth}
                />
                <div className="h-4">
                  {projectId.message && (
                    <p className="text-xs italic text-red-500">
                      {projectId.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <label className="input-label" htmlFor="username">
                  Username
                </label>
                <input
                  onChange={(e) => InputHandler(e, setEmail)}
                  className={`focus:shadow-outline focus:outline-none ${
                    email.isValid ? 'input-gray' : 'input-gray-error'
                  }`}
                  id="username"
                  type="email"
                  placeholder="example@gmail.com"
                  value={email.value}
                  required
                  disabled={isLoading}
                />
                <div className="h-4">
                  {email.message && (
                    <p className="text-xs italic text-red-500">
                      Invalid Email Format.
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-6">
                <label className="input-label" htmlFor="password">
                  Password
                </label>
                <input
                  onChange={(e) => InputHandler(e, setPassword)}
                  className={`focus:shadow-outline focus:outline-none ${
                    password.isValid ? 'input-gray' : 'input-gray-error'
                  }`}
                  id="password"
                  type="password"
                  name="password"
                  placeholder="********"
                  autoComplete="on"
                  value={password.value}
                  required
                  disabled={isLoading}
                />
                <div className="h-4">
                  {password.message && (
                    <p className="text-xs italic text-red-500">
                      {password.message}
                    </p>
                  )}
                  {authFail && (
                    <p className="my-2 text-xs italic text-red-500">
                      You entered invalid user credentials.
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
          <div>
            <button
              className={`focus:shadow-outline flex w-full items-center justify-center focus:outline-none ${
                autoAuth || formValid ? 'btn-sky' : 'btn-gray'
              }`}
              type="submit"
              disabled={!formValid || isLoading}
            >
              {isLoading && (
                <div
                  style={{ borderTopColor: 'transparent' }}
                  className="border-white-400 mr-1 h-3 w-3 animate-spin rounded-full border-2 border-dotted"
                ></div>
              )}
              {autoAuth
                ? `Connect to ${projectId.value.toUpperCase()}`
                : 'Sign In'}
            </button>
          </div>
        </form>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity }: RootStateType) => ({
  projectIdentity,
}))(Login)
