import React from 'react'
import { connect } from 'react-redux'

import {
  InteractiveMapItemInterface,
  InteractiveMapListInterface,
  ProjectIdentity,
  RootStateFirebase,
  TabInterface,
} from '@store/types'

import {
  InteractiveMapItem,
  InteractiveMapTab,
} from '@components/interactive-map-control/elements'

import FirebaseControlQuery from '@utilities/firebase-control-query'

export interface InteractiveMapHandlerInterface {
  interactiveMapList: Array<InteractiveMapListInterface>
  interactiveMapSession: string[]
  projectIdentity: ProjectIdentity
}

const InteractiveMapHandler = ({
  interactiveMapList,
  interactiveMapSession,
  projectIdentity,
}: InteractiveMapHandlerInterface) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [tabs, setTabs] = React.useState<Array<TabInterface>>([])

  const activeItems = (): Array<InteractiveMapItemInterface> =>
    interactiveMapList[activeTabIndex].items

  const isItemActives = (groupId: string): boolean => {
    if (
      interactiveMapSession &&
      interactiveMapSession.length > 0 &&
      interactiveMapSession.includes(groupId)
    ) {
      return true
    }
    return false
  }

  const buildTab = () => {
    const myTabs: TabInterface[] = []
    interactiveMapList.forEach(
      (item: InteractiveMapListInterface, index: number) => {
        if (item.label && item.label !== '') {
          myTabs.push({ index, label: item.label })
        }
      }
    )
    setTabs(myTabs)
  }

  const handleTabChange = async (index: number) => {
    setActiveTabIndex(index)
  }

  const handleItemClick = async (groupId: string) => {
    let groupIDS = [...interactiveMapSession]
    if (groupIDS.includes(groupId)) {
      groupIDS = []
    } else {
      groupIDS = [groupId]
    }

    await firebaseControlQuery.update({
      interactiveMap: groupIDS,
    })
  }

  React.useEffect(() => {
    buildTab()
  }, [interactiveMapList])

  React.useEffect(() => {
    if (interactiveMapSession && interactiveMapSession.length) {
      const getActiveTabByMapSession = interactiveMapList.findIndex((tab) =>
        tab.items.find((item) => item.groupId === interactiveMapSession[0])
      )
      if (getActiveTabByMapSession) {
        setActiveTabIndex(getActiveTabByMapSession)
      }
    }
  }, [interactiveMapSession])

  return (
    <>
      <div
        className={`gallery flex w-full items-center font-GTWalsheimPro ${
          tabs.length > 1 ? 'justify-between' : 'justify-end'
        }`}
      >
        {tabs.length > 1 && (
          <InteractiveMapTab
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            onTabChange={handleTabChange}
            className="mb-5"
          />
        )}
      </div>

      <div className="grid grid-cols-2 gap-6 pb-8">
        {activeItems().map(
          (itemData: InteractiveMapItemInterface, index: number) => (
            <InteractiveMapItem
              key={`gallery-item-${index.toString()}`}
              itemData={itemData}
              onItemClick={handleItemClick}
              isActive={isItemActives(itemData.groupId)}
            />
          )
        )}
      </div>
    </>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(InteractiveMapHandler)
