import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import {
  GalleryInterface,
  GalleryItemInterface,
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'
import Navigation from '@components/navigation'
import PanoramicControl from '@components/panoramic-control'
import {
  ArrowTurnSvg,
  ClockWiseSvg,
  PlaySvg,
  PlayerStopSvg,
} from '@components/svg'

import {
  PanoramicDataInterface,
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'

export interface PageProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

const Panoramic = ({ session, projectIdentity }: PageProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const history = useHistory()

  const [panoramicGallery, setPanoramicGallery] = React.useState<
    Array<GalleryInterface>
  >([])
  const [autoRotate, setAutoRotation] = React.useState(false)
  const [rotationDirection, setRotationDirection] =
    React.useState('counter-clockwise')
  const [pitch, setPitch] = React.useState<number>(
    session?.panoramic.pitch ?? 0
  )
  const [yaw, setYaw] = React.useState<number>(session?.panoramic.yaw ?? 0)
  const [activeItemIndex, setActiveItemIndex] = React.useState<number>(0)

  const panoramicPayload = useGetPanoramicQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromPanoramicResult }
  )

  const handleMove = (direction: string) => {
    if (direction === 'up') {
      firebaseControlQuery.updateCollection(
        `panoramic.pitch`,
        Math.min(90, pitch + 25)
      )
    }

    if (direction === 'down') {
      firebaseControlQuery.updateCollection(
        `panoramic.pitch`,
        Math.max(-90, pitch - 25)
      )
    }

    if (direction === 'right') {
      firebaseControlQuery.updateCollection(`panoramic.yaw`, yaw + 25)
    }

    if (direction === 'left') {
      firebaseControlQuery.updateCollection(`panoramic.yaw`, yaw - 25)
    }
  }

  const buildPanoramicGallery = (): void => {
    const galleryItems: Array<GalleryItemInterface> = []
    panoramicPayload.panoramicData.forEach(
      (panoramicData: PanoramicDataInterface) => {
        galleryItems.push({
          title: panoramicData.label,
          imageSource: panoramicData.image,
          assetType: 'new',
        })
      }
    )

    if (galleryItems.length > 0) {
      setPanoramicGallery([{ items: galleryItems }])
    }
  }

  const handlePlayButtonClick = () => {
    firebaseControlQuery.updateCollection(`panoramic.rotationSpeed`, 25)
  }

  const handleStopButtonClick = () => {
    firebaseControlQuery.updateCollection(`panoramic.rotationSpeed`, 0)
  }

  const handleClockWiseButtonClick = () => {
    firebaseControlQuery.updateCollection(`panoramic.rotationSpeed`, -25)
  }

  const handleCounterClockWiseButtonClick = () => {
    firebaseControlQuery.updateCollection(`panoramic.rotationSpeed`, 25)
  }

  const handleBackClick = () => {
    firebaseControlQuery.update({
      [`panoramic.pitch`]: 0,
      [`panoramic.yaw`]: 0,
      [`panoramic.rotationSpeed`]: 0,
      [`panoramic.galleryControl.activeItemIndex`]: 0,
    })

    history.push('building')
    firebaseControlQuery.updateRoute('building')
  }

  React.useEffect(() => {
    buildPanoramicGallery()
  }, [panoramicPayload])

  React.useEffect(() => {
    if (panoramicPayload.panoramicData.length > 0) {
      const data = panoramicPayload.panoramicData[activeItemIndex]
      firebaseControlQuery.update({
        [`panoramic.pitch`]: data.pitch,
        [`panoramic.yaw`]: data.yaw,
      })
    }
  }, [activeItemIndex])

  React.useEffect(() => {
    if (session) {
      const {
        panoramic: {
          galleryControl: { activeItemIndex: firbaseActiveItemIndex },
          rotationSpeed: firebaseRotationSpeed,
          pitch: firebasePitch,
          yaw: firebaseYaw,
        },
      } = session

      setAutoRotation(firebaseRotationSpeed !== 0)
      setRotationDirection(
        firebaseRotationSpeed >= 0 ? 'counter-clockwise' : 'clockwise'
      )
      setActiveItemIndex(firbaseActiveItemIndex)
      setPitch(firebasePitch)
      setYaw(firebaseYaw)
    }
  }, [session])

  return (
    <Container>
      <div className="mx-4 flex h-screen flex-col pb-6 lg:mx-10">
        <Navigation />
        <div className="my-6 border-b-2"></div>
        <DataHandler
          payload={{
            ...panoramicPayload,
            data: panoramicGallery,
          }}
        >
          <div className="flex flex-auto overflow-y-auto">
            <div className="mr-2 mt-2">
              <div className="text-base lg:text-xl">
                <button
                  onClick={handleBackClick}
                  type="button"
                  className="flex items-center rounded-full bg-white p-2 font-GTWalsheimPro"
                >
                  <ArrowTurnSvg className="h-9 w-9 text-black" />
                </button>
              </div>
            </div>
            <div className="mr-2">
              <div className="h-full bg-white bg-opacity-50 px-8 py-1 ipad-p:px-2">
                <div className="relative flex h-full flex-col">
                  <div className="flex items-center justify-center rounded-full bg-gray-500 p-4 ">
                    <button
                      type="button"
                      className="mr-3 rounded-full bg-white px-3"
                      onClick={
                        !autoRotate
                          ? handlePlayButtonClick
                          : handleStopButtonClick
                      }
                    >
                      {!autoRotate ? (
                        <PlaySvg className="h-9 w-9" />
                      ) : (
                        <PlayerStopSvg className="h-9 w-9 text-blue-500" />
                      )}
                    </button>

                    <button
                      type="button"
                      className="mr-3 rounded-full bg-white px-3"
                      onClick={handleClockWiseButtonClick}
                    >
                      <ClockWiseSvg
                        className={`h-9 w-9 pt-2 ${
                          autoRotate && rotationDirection === 'clockwise'
                            ? 'text-blue-500'
                            : ''
                        }`}
                        direction="clockwise"
                        stroke="10"
                      />
                    </button>
                    <button
                      type="button"
                      className="rounded-full bg-white px-3"
                      onClick={handleCounterClockWiseButtonClick}
                    >
                      <ClockWiseSvg
                        className={`h-9 w-9 pt-2 ${
                          autoRotate &&
                          rotationDirection === 'counter-clockwise'
                            ? 'text-blue-500'
                            : ''
                        }`}
                        direction="counter-clockwise"
                        stroke="10"
                      />
                    </button>
                  </div>

                  <div>
                    <PanoramicControl
                      autoRotate={autoRotate}
                      handleClick={handleMove}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-auto flex-grow overflow-y-auto">
              <GalleryHandler
                galleryName="panoramic"
                galleries={panoramicGallery}
                gallerySession={session?.panoramic}
              />
            </div>
          </div>
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(Panoramic)
