import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

const projectIdentity = localStorage.getItem('projectIdentity')

let initialState = {
  disconnect: true,
  projectId: '',
  projectUUID: '',
  sessionId: '',
  sessionList: [],
  longhand: false,
  showPrice: false,
  hideSoldPrice: false,
  hideFilter: false,
  prices: {
    min: 0,
    max: 0,
  },
  onlyShowUnitGalleries: false,
  isSnaploaderActive: false,
  isEnvisionVRActive: false,
} as ProjectIdentity

if (projectIdentity) {
  initialState = JSON.parse(projectIdentity)
}

const projectIdentitySlice = createSlice({
  name: 'projectIdentity',
  initialState,
  reducers: {
    setProjectIdentity: (
      state: ProjectIdentity,
      action: PayloadAction<ProjectIdentity>
    ) => {
      localStorage.setItem('projectIdentity', JSON.stringify(action.payload))
      return {
        ...state,
        ...action.payload,
      }
    },
    removeProjectIdentity: (state: ProjectIdentity) => {
      localStorage.removeItem('projectIdentity')
      return {
        ...state,
        ...{
          projectId: '',
          projectUUID: '',
          sessionId: '',
          longhand: false,
          showPrice: false,
          hideSoldPrice: false,
          hideFilter: false,
          sessionList: [],
          prices: {
            min: 0,
            max: 0,
          },
          onlyShowUnitGalleries: false,
          isSnaploaderActive: false,
          isEnvisionVRActive: false,
        },
      }
    },
  },
})

export const { setProjectIdentity, removeProjectIdentity } =
  projectIdentitySlice.actions

export const selectProject = (state: RootStateTypeExtra) =>
  state.projectIdentity

export default projectIdentitySlice.reducer
