import React from 'react'

import { UnitFilterInterface } from '@store/types'

interface ComponentProps {
  isLoaded: boolean
  levels: Array<string>
  handleClick: (arg: string) => void
}

const LevelList = ({ isLoaded, levels, handleClick }: ComponentProps) => {
  if (!isLoaded)
    return (
      <div className="inline-flex items-center text-3xl text-white">
        <div className="border-white-400 mr-2 h-8 w-8 animate-spin rounded-full border-4 border-solid border-t-transparent"></div>
        Loading level data ...
      </div>
    )

  return (
    <>
      {levels.length > 0 ? (
        levels.map((level: string) => (
          <button
            onClick={() => handleClick(level)}
            key={level}
            type="button"
            className="my-3 mr-3 w-2/5 cursor-pointer rounded-md bg-white px-3 py-4 text-center font-bold text-gray-700"
          >
            Level {level}
          </button>
        ))
      ) : (
        <div className="rounded-md bg-white px-3 py-4 text-center font-bold">
          No level found
        </div>
      )}
    </>
  )
}

export default LevelList
