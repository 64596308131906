import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'

import { TokenInterface } from '@store/actionSlices/token'
import type { ProjectIdentity, RootStateFirebase } from '@store/types'

import { hasToken } from '@utilities/token-helper'

export type SessionActiveRoutes = {
  projectIdentity: ProjectIdentity
  token: TokenInterface
  returnPath: string
  authenticationPath: string
} & RouteProps

function SessionActiveRoutes({
  projectIdentity,
  token,
  returnPath,
  authenticationPath,
  ...routeProps
}: SessionActiveRoutes) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(true)
  const [isSessionActive, setSessionActive] = React.useState(true)

  React.useEffect(() => {
    if (!hasToken(token)) {
      setIsAuthenticated(false)
    }
  }, [token])

  React.useEffect(() => {
    const { sessionId, sessionList } = projectIdentity
    if (!sessionId && sessionList.length === 0) {
      setSessionActive(false)
    }
  }, [projectIdentity])

  if (isSessionActive && isAuthenticated) {
    return <Route {...routeProps} />
  }

  if (isAuthenticated) {
    return <Redirect to={{ pathname: returnPath }} />
  }

  return <Redirect to={{ pathname: authenticationPath }} />
}

export default connect(({ projectIdentity, token }: RootStateFirebase) => ({
  projectIdentity,
  token,
}))(SessionActiveRoutes)
