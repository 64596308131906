import React from 'react'
import { connect } from 'react-redux'

import {
  PlayerControlInterface,
  ProjectIdentity,
  RootStateFirebase,
} from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'

import {
  MinusCircleSvg,
  PauseSvg,
  PlaySvg,
  PlayerStopSvg,
  PlusCircleSvg,
  SpeakerXMarkSvg,
  VolumeSvg,
} from '../svg'
import ScrubBar from './scrub-bar'

export interface ComponentPropsInterface {
  galleryName: string
  playerSession: PlayerControlInterface | undefined
  projectIdentity: ProjectIdentity
}

const PlayerControl = ({
  galleryName,
  playerSession,
  projectIdentity,
}: ComponentPropsInterface) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [isPlayerVisible, setIsPlayerVisible] = React.useState(false)
  const [playerState, setPlayerState] = React.useState('stop')
  const [volume, setVolume] = React.useState(0)
  const [isMuted, setisMuted] = React.useState(true)
  const [fullScreen, setFullScreen] = React.useState(false)

  const handleVolumeUpClick = async () => {
    if (volume < 1) {
      const vol = Math.round((volume + 0.1) * 100) / 100
      await firebaseControlQuery.update({
        [`${galleryName}.playerControl.volume`]: vol,
        [`${galleryName}.playerControl.isMuted`]: false,
      })
    }
  }

  const handleVolumeDownClick = async () => {
    if (volume > 0) {
      const vol = Math.round((volume - 0.1) * 100) / 100
      await firebaseControlQuery.update({
        [`${galleryName}.playerControl.volume`]: vol,
        [`${galleryName}.playerControl.isMuted`]: vol === 0 ? true : isMuted,
      })
    }
  }

  const handleStopClick = async () => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.playerState`]: 'stop',
      [`${galleryName}.playerControl.isPlayerVisible`]: false,
    })
  }

  const handlePlayClick = () => {
    firebaseControlQuery.updateCollection(
      `${galleryName}.playerControl.playerState`,
      'play'
    )
  }

  const handlePauseClick = () => {
    firebaseControlQuery.updateCollection(
      `${galleryName}.playerControl.playerState`,
      'pause'
    )
  }

  const handleSpeakerClick = () => {
    firebaseControlQuery.updateCollection(
      `${galleryName}.playerControl.isMuted`,
      !isMuted
    )
  }

  const handleFullScreenClick = () => {
    firebaseControlQuery.updateCollection(
      `${galleryName}.playerControl.fullScreen`,
      !fullScreen
    )
  }

  React.useEffect(() => {
    if (playerSession) {
      const {
        isPlayerVisible: isPlayerVisibleFirebase,
        playerState: playerStateFirebase,
        volume: volumeFirebase,
        isMuted: isMutedFirebase,
        fullScreen: fullScreenFirebase,
      } = playerSession

      setIsPlayerVisible(isPlayerVisibleFirebase)
      setPlayerState(playerStateFirebase)
      setVolume(volumeFirebase)
      setisMuted(isMutedFirebase)
      setFullScreen(fullScreenFirebase)
    }
  }, [playerSession])

  return isPlayerVisible ? (
    <div className="fixed inset-0 z-40 bg-gray-500 bg-opacity-50">
      <div className="absolute inset-x-20% inset-y-45%">
        <div className="rounded-full bg-white">
          <div className="flex items-center justify-center pt-4">
            <button type="button" onClick={() => handleStopClick()}>
              <PlayerStopSvg
                className="ml-2 mr-2 h-14 w-14 cursor-pointer text-blue-500"
                stroke="1.2"
              />
            </button>
            {playerState === 'pause' ? (
              <button type="button" onClick={() => handlePlayClick()}>
                <PlaySvg
                  className="mr-2 h-14 w-14 cursor-pointer  text-blue-500"
                  stroke="1.2"
                />
              </button>
            ) : (
              <button type="button" onClick={() => handlePauseClick()}>
                <PauseSvg
                  className="mr-2 h-14 w-14 cursor-pointer  text-blue-500"
                  stroke="1.2"
                />
              </button>
            )}
            <button type="button" onClick={() => handleSpeakerClick()}>
              {isMuted ? (
                <SpeakerXMarkSvg
                  className="mr-2 h-14 w-14 cursor-pointer  text-blue-500"
                  stroke="1.2"
                />
              ) : (
                <VolumeSvg
                  className="mr-2 h-14 w-14 cursor-pointer  text-blue-500"
                  stroke="1.2"
                />
              )}
            </button>
            <button type="button" onClick={() => handleVolumeDownClick()}>
              <MinusCircleSvg
                className="mr-2 h-14 w-14 cursor-pointer  text-blue-500"
                stroke="1.2"
              />
            </button>
            <button type="button" onClick={() => handleVolumeUpClick()}>
              <PlusCircleSvg
                className="mr-2 h-14 w-14 cursor-pointer text-blue-500"
                stroke="1.2"
              />
            </button>
          </div>
          <div className="px-8 pb-4 pt-8">
            <ScrubBar galleryName={galleryName} playerSession={playerSession} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(PlayerControl)
