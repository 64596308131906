import React from 'react'

import { TabInterface } from '@store/types'

export interface ComponentPropsInterface {
  tabs: Array<TabInterface>
  activeGalleryTabIndex: number
  onTabChange: (arg: number) => void
  className?: string
}

const GalleryTab = ({
  tabs,
  activeGalleryTabIndex,
  onTabChange,
  className = '',
}: ComponentPropsInterface) => (
  <div className={`no-scrollbar flex overflow-x-auto ${className}`}>
    {tabs.map((tab: TabInterface) => (
      <button
        key={`gallery-tab-${tab.index}`}
        type="button"
        className={`bg-white ${
          activeGalleryTabIndex === tab.index
            ? 'cursor-default text-blue-500'
            : 'cursor-pointer text-black'
        } mr-2 rounded-full px-4 py-1 font-medium`}
        onClick={() => onTabChange(tab.index)}
      >
        {tab.label}
      </button>
    ))}
  </div>
)

export default GalleryTab
