import React from 'react'

import Container from '@components/container'

const PageNotFound = () => (
  <>
    <Container className="grid place-items-center">
      <div
        className="grid h-2/6 w-5/12 place-items-center rounded-xl bg-white"
        style={{ background: 'linear-gradient(20deg, white, transparent)' }}
      >
        <h1 className="font-GTWalsheimPro text-5xl">404 Page Not Found</h1>
      </div>
    </Container>
  </>
)

export default PageNotFound
