import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import {
  InteractiveMapItemInterface,
  InteractiveMapListInterface,
} from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

export interface Layers {
  groupId: string
  label: string
  controlSrc: string
  src: string
  animation?: string
  visible: boolean
  controls: boolean
  type: string
  category: string
}

export interface InteractiveMapData {
  src: string
  layers: Array<Layers>
}

export interface InteractiveMapPayload {
  data: Array<InteractiveMapData>
}

type InteractiveMapType = {
  projectName: string
}

export const interactiveMapApi = createApi({
  reducerPath: 'interactiveMapApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getInteractiveMap: builder.query<InteractiveMapPayload, InteractiveMapType>(
      {
        query: (params: InteractiveMapType) => ({
          url: `/v1/${params.projectName}/interactive-map${roomQueryString()}`,
          method: 'get',
        }),
      }
    ),
  }),
})

const processData = (
  data: InteractiveMapData
): Array<InteractiveMapListInterface> => {
  const layers: Array<Layers> = data?.layers || []
  const categories: string[] = []

  layers.forEach((layer: Layers) => {
    if (!categories.includes(layer.category) && layer.controls) {
      categories.push(layer.category)
    }
  })

  const interactiveMapList: Array<InteractiveMapListInterface> = categories.map(
    (category) => {
      const itemsByCat = layers.filter(
        (layer) => layer.category === category && layer.controls
      )
      const items = itemsByCat.map(
        (layer: Layers): InteractiveMapItemInterface => ({
          label: layer.label ?? 'Untitled',
          controlSrc: layer.controlSrc,
          groupId: layer.groupId,
        })
      )
      return { label: category, items }
    }
  )

  return interactiveMapList
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  interactiveMapData: processData(data?.data || {}),
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetInteractiveMapQuery } = interactiveMapApi
