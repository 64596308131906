import React, { ChangeEvent } from 'react'
import { connect, useDispatch } from 'react-redux'

import { resetAppConfig } from '@store/actionSlices/appConfig'
import {
  removeProjectIdentity,
  setProjectIdentity,
} from '@store/actionSlices/project'
import { removeReleaseInfo } from '@store/actionSlices/releaseInfo'
import { removeToken } from '@store/actionSlices/token'
import { removeUser } from '@store/actionSlices/user'
import type { ProjectIdentity, RootStateFirebase } from '@store/types'

import Container from '@components/container'
import { AlertModal } from '@components/modals'
import { LogoutSvg } from '@components/svg'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getFirebasePort } from '@utilities/helper'
import { removeOfflineResponseFromLocalStorage } from '@utilities/offline-handler-util'

import AssetSettings from './asset-settings'
import OfflineMode from './offline-mode'

export interface DashboardProps {
  projectIdentity: ProjectIdentity
}

const Dashboard = ({ projectIdentity }: DashboardProps) => {
  const dispatch = useDispatch()

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [sessionId, setSessionId] = React.useState('')
  const [sessionName, setSessionName] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [autoConnect, setAutoConnect] = React.useState(false)
  const [showAlertModal, toggleAlertModal] = React.useState(false)
  const [alertModalMessage, setAlertModalMessage] = React.useState('')

  const connectToASession = async () => {
    setIsLoading(true)
    try {
      await firebaseControlQuery.updateConnection(true, sessionId)
      setIsLoading(false)
      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          sessionId,
          sessionList: [
            { sessionName, sessionId: sessionId.replace(/\s/g, '') },
          ],
        })
      )
    } catch (e) {
      setIsLoading(false)
      setAlertModalMessage('This session ID is invalid.')
      toggleAlertModal(true)
    }
  }

  const logout = () => {
    const firebasePort = getFirebasePort()

    dispatch(removeToken())
    dispatch(removeProjectIdentity())
    dispatch(removeUser())
    dispatch(removeReleaseInfo())
    dispatch(resetAppConfig())
    removeOfflineResponseFromLocalStorage()
    if (firebasePort !== '') window.location.reload()
  }

  React.useEffect(() => {
    if (sessionId && autoConnect) {
      connectToASession()
    }
  }, [sessionId, autoConnect])

  React.useEffect(() => {
    if (projectIdentity.sessionId) {
      setSessionId(projectIdentity.sessionId)
      setAutoConnect(true)
    }
  }, [])

  return (
    <Container className="relative">
      <div className="absolute left-0 top-0 w-full">
        <div className="m-auto flex w-11/12 items-center px-2 py-5 font-GTWalsheimPro">
          <h1 className="text-2xl text-white">Showcase Controller</h1>
          <div className="ml-auto flex">
            <AssetSettings />
            <OfflineMode />
            {projectIdentity.disconnect && (
              <button className="text-white" type="button" onClick={logout}>
                <LogoutSvg />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="flex h-full w-full items-center justify-center">
        <div className="w-1/3">
          {projectIdentity.sessionId &&
          projectIdentity.sessionList.length > 0 ? (
            <div className="flex h-full w-full">
              <div
                style={{ borderTopColor: 'transparent' }}
                className="border-white-400 m-auto h-12 w-12 animate-spin rounded-full border-2 border-dotted"
              ></div>
            </div>
          ) : (
            <>
              <div className="mx-4 mt-16 w-auto text-center font-GTWalsheimPro text-white">
                <input
                  type="text"
                  value={sessionId}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setSessionId(e.target.value.toUpperCase())
                  }
                  className="input-gray m-auto mb-7 flex max-w-lg text-center"
                  placeholder="Enter your session Key"
                />
                <input
                  type="text"
                  value={sessionName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setSessionName(e.target.value)
                  }
                  className="input-gray m-auto mb-10 flex max-w-lg text-center"
                  placeholder="Enter your session Name"
                />
                <button
                  className="btn-sky-rnd-full focus:shadow-outline m-auto flex items-center focus:outline-none"
                  type="button"
                  onClick={connectToASession}
                  disabled={!sessionName && !sessionId}
                >
                  {isLoading ? 'Connecting' : 'Connect to a Session'}
                  {isLoading && (
                    <div
                      style={{ borderTopColor: 'transparent' }}
                      className="border-white-400 ml-1 h-3 w-3 animate-spin rounded-full border-2 border-dotted"
                    ></div>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <AlertModal
        isVisible={showAlertModal}
        toggleModal={toggleAlertModal}
        message={alertModalMessage}
      />
    </Container>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(Dashboard)
