import React from 'react'

import AssetModal from '@components/modals/asset-modal'
import { ImageSvg } from '@components/svg'

const AssetSettings = () => {
  const [showAssetSettingsModal, toggleAssetSettingsModal] =
    React.useState(false)

  const handleOnClick = () => {
    toggleAssetSettingsModal(true)
  }

  return (
    <>
      <button
        type="button"
        className="mr-4 flex items-center text-white"
        onClick={handleOnClick}
      >
        <ImageSvg className="h-10 w-10" />
      </button>

      <AssetModal
        isVisible={showAssetSettingsModal}
        toggleModal={toggleAssetSettingsModal}
      />
    </>
  )
}

export default AssetSettings
