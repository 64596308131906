import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { GalleryItemInterface } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

import PayloadInterface from './payload.type'

export interface VisionData {
  gallery: Array<GalleryItemInterface>
  title: string
}

export interface VisionPayload extends PayloadInterface {
  data: VisionData
}

type VisionType = {
  projectName: string
}

export const visionApi = createApi({
  reducerPath: 'visionApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getVision: builder.query<VisionPayload, VisionType>({
      query: (params) => ({
        url: `/v1/${params.projectName}/vision${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})

const setTitleCard = ({ data: { gallery } }: VisionPayload) => {
  let keyCard = 0
  if (gallery.length > 0) {
    const newGalleryRef = gallery.map((gal, key) => {
      if (gal?.config?.isTitleCard) {
        keyCard = key
        return { ...gal }
      }
      return gal
    })
    if (keyCard !== 0) {
      newGalleryRef.splice(0, 0, newGalleryRef.splice(keyCard, 1)[0])
    }
    return newGalleryRef
  }
  return []
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  gallery: data ? setTitleCard(data) : [],
  title: data?.data.title || '',
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetVisionQuery } = visionApi
