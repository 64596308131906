import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import { GalleryInterface, GalleryItemInterface } from '@store/types'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

export interface ImagesInterface {
  label: string
  distance: string
  imageSource: string
  imageName: string
}

export interface LocationDataInterface {
  label: string
  Images: Array<ImagesInterface>
}

export interface LocationPayloadInterface {
  data: Array<LocationDataInterface>
}

type LocationTypeInterface = {
  projectName: string
}

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getLocation: builder.query<LocationPayloadInterface, LocationTypeInterface>(
      {
        query: (params: LocationTypeInterface) => ({
          url: `/v1/${params.projectName}/location${roomQueryString()}`,
          method: 'get',
        }),
      }
    ),
  }),
})

const processGalleryData = (
  data: Array<LocationDataInterface>
): Array<GalleryInterface> => {
  const galleries: Array<GalleryInterface> = []

  data.forEach((locationData: LocationDataInterface) => {
    const items: Array<GalleryItemInterface> = []

    locationData.Images.forEach((image: ImagesInterface) => {
      items.push({
        title: image.imageName ?? 'Untitled',
        imageSource: image.imageSource,
      })
    })

    galleries.push({ label: locationData.label, items })
  })
  return galleries
}

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  locationData: processGalleryData(data?.data || []),
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetLocationQuery } = locationApi
