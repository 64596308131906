import React, { ChangeEvent, useRef } from 'react'

interface RangeSliderProps {
  min: number
  max: number
  minValue: number
  maxValue: number
  onChange: (arg: { min: number; max: number }) => void
}

const Range = React.forwardRef(
  ({ min, max, minValue, maxValue, onChange }: RangeSliderProps, ref) => {
    const [minVal, setMinVal] = React.useState(minValue)
    const [maxVal, setMaxVal] = React.useState(maxValue)
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>()
    const minValRef = useRef<HTMLInputElement>(null)
    const maxValRef = useRef<HTMLInputElement>(null)
    const range = useRef<HTMLDivElement>(null)

    const getPercent = React.useCallback(
      (value: number) => Math.round(((value - min) / (max - min)) * 100),
      [min, max]
    )

    const handleMillionLabel = (value: number) => {
      const suffixes = ['', 'K', 'M', 'B', 'T']
      const suffixNum = Math.floor(`${value}`.length / 3)
      let shortValue: string | number = parseFloat(
        (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(2)
      )
      if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(1)
      }
      return shortValue + suffixes[suffixNum]
    }

    const setLabel = (value: number) => {
      if (value > 999999) {
        return handleMillionLabel(value)
      }

      return `${Math.ceil(value / 1000)}K`
    }

    const reset = () => {
      setMaxVal(maxValue)
      setMinVal(minValue)
    }

    React.useImperativeHandle(ref, () => ({ reset }))

    React.useEffect(() => {
      if (maxValRef.current) {
        const minPercent = getPercent(minVal)
        const maxPercent = getPercent(+maxValRef.current.value)
        if (range.current) {
          range.current.style.left = `${minPercent}%`
          range.current.style.width = `${maxPercent - minPercent}%`
        }
      }
    }, [minVal, getPercent])

    React.useEffect(() => {
      if (minValRef.current) {
        const minPercent = getPercent(+minValRef.current.value)
        const maxPercent = getPercent(maxVal)

        if (range.current) {
          range.current.style.width = `${maxPercent - minPercent}%`
        }
      }
    }, [maxVal, getPercent])

    React.useEffect(() => {
      reset()
    }, [minValue, maxValue])

    React.useEffect(() => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      const timeout = setTimeout(() => {
        onChange({ min: minVal, max: maxVal })
      }, 500)
      setTimeoutId(timeout)
    }, [minVal, maxVal])

    return (
      <div className="relative w-full pt-10">
        <div className="slider">
          <div className="slider__left-value">
            <div className="text-sm text-gray-400">Min</div>
            <div>{setLabel(minVal)}</div>
          </div>
          <div className="slider__right-value">
            <div className="text-right text-sm text-gray-400">Max</div>
            <div>{setLabel(maxVal)}</div>
          </div>
          <input
            type="range"
            min={min}
            max={max}
            value={minVal}
            ref={minValRef}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = Math.min(+event.target.value, maxVal - 1)
              setMinVal(value)
            }}
            className={`thumb thumb--zindex-3 ${
              minVal > max - 100 ? 'thumb--zindex-5' : ''
            }`}
          />
          <input
            type="range"
            min={min}
            max={max}
            value={maxVal}
            ref={maxValRef}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = Math.max(+event.target.value, minVal + 1)
              setMaxVal(value)
            }}
            className="thumb thumb--zindex-4"
          />
          <div className="slider__track"></div>
          <div ref={range} className="slider__range"></div>
        </div>
      </div>
    )
  }
)

export default Range
