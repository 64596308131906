import React from 'react'

export interface NoDataFoundProps {
  message?: string
}

const DataNotFound = ({ message = 'No data found.' }: NoDataFoundProps) => (
  <div
    className="grid h-96 place-items-center rounded-xl bg-white"
    style={{ background: 'linear-gradient(20deg, white, transparent)' }}
  >
    <h1 className="font-GTWalsheimPro text-5xl">{message}</h1>
  </div>
)

export default DataNotFound
