import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'

import { TokenInterface, setToken } from '@store/actionSlices/token'

const hasToken = (token: TokenInterface): boolean => {
  const {
    access_token: { token: accessToken },
    refresh_token: { token: refreshToken },
  } = token

  return accessToken !== '' && refreshToken !== ''
}

const isTokenExpired = (token: TokenInterface): boolean => {
  const {
    access_token: { expires_at },
  } = token

  const currentTime = new Date().getTime()
  const tokenExpiryTime = new Date(expires_at).getTime()
  const timeDifference = tokenExpiryTime - currentTime
  const timeDifferenceInDays = timeDifference / (1000 * 60 * 60 * 24)
  return timeDifferenceInDays <= 7
}

const renewUserAccessToken = async (
  token: TokenInterface,
  api: any,
  extraOptions: any
): Promise<boolean> => {
  let status = true

  const baseUrl = process.env.REACT_APP_API_URL
  const client_id = process.env.REACT_APP_CLIENT_ID
  const client_secret = process.env.REACT_APP_CLIENT_SECRET

  const {
    refresh_token: { token: refreshToken },
  } = token

  const baseQuery = fetchBaseQuery({ baseUrl })

  const response = await baseQuery(
    {
      url: '/v1/user/refresh-token',
      method: 'post',
      body: {
        client_id,
        client_secret,
        refresh_token: refreshToken,
      },
    },
    api,
    extraOptions
  )

  if (response.data) {
    const {
      data: {
        user: { access_token, refresh_token },
      },
    } = response.data as {
      data: { user: TokenInterface }
    }
    api.dispatch(setToken({ access_token, refresh_token }))
  } else {
    console.error('Failed to renew the user token.', response)
    status = false
  }

  return status
}

export { hasToken, isTokenExpired, renewUserAccessToken }
