import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import type { RootStateFirebase, SessionMap } from '@store/types'

import {
  ProtectedRoutes,
  PublicRoutes,
  SessionActiveRoutes,
} from '@components/route-guards'

import PageNotFound from '@pages/404'
import Brochure from '@pages/brochure'
import Building from '@pages/building'
import Dashboard from '@pages/dashboard'
import EnvisionVR from '@pages/envision-vr'
import ExternalViews from '@pages/external-views'
import Gallery from '@pages/gallery'
import {
  AreaView,
  LevelView,
  SnaploaderView,
} from '@pages/interactive-building'
import InteractiveMap from '@pages/interactive-map'
import Location from '@pages/location'
import Login from '@pages/login'
import Panoramic from '@pages/panoramic'
import Shortlist from '@pages/shortlist'
import Team from '@pages/team'
import VideoGallery from '@pages/video-gallery'
import Vision from '@pages/vision'

import { getSession } from '@utilities/firebase-util'

interface RouteProps {
  activeRoute: string
}

const Routes = ({ activeRoute }: RouteProps) => {
  const history = useHistory()
  const location = useLocation()

  const IGNORE_ROUTER_PUSHER = ['/shortlist']

  React.useEffect(() => {
    if (activeRoute) {
      if (IGNORE_ROUTER_PUSHER.includes(location.pathname)) {
        return
      }
      if (activeRoute !== location.pathname) {
        history.push(activeRoute)
      }
    }
  }, [activeRoute])

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        classNames="transition-fade"
        timeout={500}
        unmountOnExit
      >
        <Switch location={location}>
          <SessionActiveRoutes
            exact
            path="/gallery"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <Gallery />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/video-gallery"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <VideoGallery />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/external-views"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <ExternalViews />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/brochure"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <Brochure />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/location"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <Location />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/interactive-map"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <InteractiveMap />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            path="/level-view"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <LevelView />
          </SessionActiveRoutes>

          <SessionActiveRoutes
            exact
            path="/shortlist"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <Shortlist />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/area-view"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <AreaView />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/building"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <Building />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/vision"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <Vision />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/teams"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <Team />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            exact
            path="/panoramic"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <Panoramic />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            path="/snaploader-view"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <SnaploaderView />
          </SessionActiveRoutes>
          <SessionActiveRoutes
            path="/envision-vr"
            authenticationPath="/"
            returnPath="/dashboard"
          >
            <EnvisionVR />
          </SessionActiveRoutes>
          <ProtectedRoutes
            exact
            path="/dashboard"
            authenticationPath="/"
            redirectSessionPath="/vision"
          >
            <Dashboard />
          </ProtectedRoutes>
          <PublicRoutes exact path="/:projectId" authenticatedPath="/dashboard">
            <Login />
          </PublicRoutes>
          <PublicRoutes exact path="/" authenticatedPath="/dashboard">
            <Login />
          </PublicRoutes>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

const handleRoute = (session: SessionMap | undefined) => {
  if (session) {
    const { activeRoute } = session
    return `/${activeRoute}`
  }
  return ''
}

export default connect(({ firestore }: RootStateFirebase) => ({
  activeRoute: handleRoute(getSession(firestore)),
}))(Routes)
