import React from 'react'

export interface SomethingWentWrongProps {
  message?: string
}

const SomethingWentWrong = ({
  message = 'Whoops, looks like something went wrong.',
}: SomethingWentWrongProps) => (
  <div
    className="grid h-96 place-items-center rounded-xl bg-white"
    style={{ background: 'linear-gradient(20deg, white, transparent)' }}
  >
    <h1 className="font-GTWalsheimPro text-5xl sm:text-4xl">{message}</h1>
  </div>
)

export default SomethingWentWrong
