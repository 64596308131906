import React from 'react'

import { SvgProps } from '@components/svg/types'

const ShortListSvg = ({
  width,
  height,
  className,
  size,
  styles,
  fill,
}: SvgProps) => {
  const sizeValue = size || 'm'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.5,
    xs: 0.2,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeValue]})` },
      }}
      width={width || '48px'}
      height={height || '48px'}
      viewBox="0 0 26 50"
    >
      <path
        d="M 0 0 L 0 48 L 13 36.961 L 26 48 L 26 0 Z M 17.592 27.056 L 13 24.651 L 8.408 27.056 L 9.287 21.958 L 5.571 18.346 L 10.704 17.601 L 13 12.961 L 15.296 17.601 L 20.429 18.346 L 16.713 21.958 Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export default ShortListSvg
