import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { filterShortlist, setShortlist } from '@store/actionSlices/shortlist'
import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Filter from '@components/filter'
import FloorPlateGallery from '@components/floor-plate-gallery'
import GalleryControl, {
  SlideshowTransitionProps,
} from '@components/gallery-handler/elements/gallery-control'
import Navigation from '@components/navigation'
import {
  ArrowTurnSvg,
  FilterSvg,
  InfoCircleSvg,
  ShortListSvg,
  VirtualGogglesSvg,
} from '@components/svg'
import UnitListing from '@components/unit-listing'

import {
  Level,
  Unit,
  selectFromResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  PanoramicDataInterface,
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'

import UnitGallery from './unit-gallery'

export interface BuildingProps {
  session: SessionMap | undefined
  shortlist: Array<Unit>
  projectIdentity: ProjectIdentity
}

const Building = ({ projectIdentity, session, shortlist }: BuildingProps) => {
  const STATUS_AVAILABLE = 'available'
  const MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST =
    Number(process.env.REACT_APP_MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST) || 4

  const dispatch = useDispatch()
  const history = useHistory()
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [buildingLabel, setBuildingLabel] = React.useState('')
  const [activeBlock, setActiveBlock] = React.useState('')
  const [activeLevel, setActiveLevel] = React.useState('')
  const [activeUnit, setAciveUnit] = React.useState('')
  const [unitSelected, setUnitSelected] = React.useState<Unit>()
  const [infoPopup, setInfoPopup] = React.useState(false)
  const [isFilterOpen, toggleFilter] = React.useState(false)
  const [units, setUnits] = React.useState<Array<Unit>>([])
  const [floorGalleryItemsCount, setFloorGalleryItemsCount] =
    React.useState<number>(0)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] =
    React.useState<boolean>(false)
  const [isUnitGallerySlideShowPlaying, setUnitGallerySlideShowStatus] =
    React.useState<boolean>(false)
  const [unitGalleryItemLength, setUnitGalleryItemLength] = React.useState(0)

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  const { panoramicData } = useGetPanoramicQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromPanoramicResult }
  )

  const { levels, isLoaded } = buildingPayload

  const handleUnits = () => {
    setUnits(
      levels
        .find((lvl: Level) => lvl.level === activeLevel)
        ?.data.filter((unit: Unit) => {
          if (activeBlock) {
            return unit.blockId === activeBlock
          }
          return unit
        }) || []
    )
  }

  const handleInfoPopup = async () => {
    await firebaseControlQuery.update({
      [`building.unitInfoPopup`]: !infoPopup,
    })
  }

  const handleUnitClick = (unit: Unit) => {
    toggleFilter(false)
    firebaseControlQuery.update({ [`building.activeUnit`]: unit.name })
  }

  const removeUnit = async () => {
    await firebaseControlQuery.update({
      [`building.activeUnit`]: '',
      [`building.unitInfoPopup`]: false,
    })
  }

  const handleBackButtonPress = async () => {
    let route = 'level-view'
    if (projectIdentity.isSnaploaderActive) {
      route = 'snaploader-view'
    }
    if (projectIdentity.isEnvisionVRActive) {
      route = 'envision-vr'
    }
    await firebaseControlQuery.update({
      [`building.activeLevel`]: '',
      [`activeRoute`]: route,
    })

    await firebaseControlQuery.update({
      [`building.galleryControl.activeItemIndex`]: 0,
    })
  }

  const handleLabel = () => {
    let label = ''
    if (activeBlock) {
      label = `VIEWING: ${activeBlock.toUpperCase()} / LEVEL ${activeLevel.toUpperCase()}`
    } else {
      label = `VIEWING: LEVEL ${activeLevel.toUpperCase()}`
    }
    setBuildingLabel(activeUnit ? `${label} / UNIT ${activeUnit}` : label)
  }

  const findPanoramic = (
    type: 'unit' | 'level' | 'block',
    targetName: string
  ) =>
    panoramicData.find(
      (data: PanoramicDataInterface) =>
        data.type === type && data.name.toString() === targetName
    )

  const hasPanoramicPayload = () => {
    if (activeUnit !== '') {
      return findPanoramic('unit', activeUnit.toString()) !== undefined
    }

    if (activeLevel !== '') {
      return findPanoramic('level', activeLevel.toString()) !== undefined
    }

    if (activeBlock !== '') {
      return findPanoramic('block', activeBlock.toString()) !== undefined
    }

    return false
  }

  const handleOnChangeFloorGallerySlideShowStatus = (
    status: boolean,
    data?: SlideshowTransitionProps
  ) => {
    firebaseControlQuery.update({
      [`floorGallery.galleryControl.isPlaying`]: status,
      [`floorGallery.galleryControl.transitionTime`]:
        data?.newTransitionTime ?? 3000,
      [`floorGallery.galleryControl.transitionType`]:
        data?.newTransitionType ?? 'slide',
    })
  }

  const handleOnChangeUnitGallerySlideShowStatus = (
    status: boolean,
    data?: SlideshowTransitionProps
  ) => {
    firebaseControlQuery.update({
      [`unitGallery.galleryControl.isPlaying`]: status,
      [`unitGallery.galleryControl.transitionTime`]:
        data?.newTransitionTime ?? 3000,
      [`unitGallery.galleryControl.transitionType`]:
        data?.newTransitionType ?? 'slide',
    })
  }

  React.useEffect(() => {
    handleLabel()
  }, [activeBlock, activeLevel, activeUnit])

  React.useEffect(() => {
    if (Object.keys(levels).length > 0 && units.length === 0) {
      handleUnits()
    }
  }, [activeBlock, activeLevel, isLoaded])

  React.useEffect(() => {
    const unit = units.find((res) => res.name === activeUnit)
    setUnitSelected(unit)
  }, [activeUnit])

  React.useEffect(() => {
    if (session) {
      const {
        building: {
          activeBlock: firebaseActiveBlock,
          activeLevel: firebaseActiveLevel,
          activeUnit: firebaseActiveUnit,
          unitInfoPopup,
        },
        floorGallery: {
          galleryControl: { isPlaying: isPlayingFloorGalleryFirebase },
        },
        unitGallery: {
          galleryControl: { isPlaying: isPlayingUnitGalleryFirebase },
        },
      } = session
      setActiveBlock(firebaseActiveBlock)
      setActiveLevel(firebaseActiveLevel)
      setAciveUnit(firebaseActiveUnit)
      setInfoPopup(unitInfoPopup)
      setSlideShowPlayingStatus(isPlayingFloorGalleryFirebase)
      setUnitGallerySlideShowStatus(isPlayingUnitGalleryFirebase)
    }
  }, [session])

  return (
    <Container className="overflow-hidden px-10">
      <div className="mx-4 h-full lg:mx-10">
        <Navigation />
        <div className="no-scrollbar my-6 grid h-4/6 grid-cols-2 gap-4 overflow-x-auto  border-t-2 border-white bg-opacity-0 pb-10 sm:h-5/6">
          <DataHandler
            message="There are no available units."
            payload={{
              ...buildingPayload,
              data: buildingPayload.levels,
            }}
          >
            <div className="flex h-full w-full">
              <div className="my-4">
                <button
                  onClick={() => {
                    if (activeUnit) {
                      removeUnit()
                      return
                    }
                    handleBackButtonPress()
                  }}
                  type="button"
                  className="mb-4 flex items-center rounded-full bg-white p-2 font-GTWalsheimPro"
                >
                  <ArrowTurnSvg className="h-10 w-10 text-black" />
                </button>
                {!activeUnit && !projectIdentity.hideFilter && (
                  <button
                    onClick={() => toggleFilter(true)}
                    type="button"
                    className="flex items-center rounded-full bg-white font-GTWalsheimPro"
                  >
                    <FilterSvg className="h-14 w-14 text-black" />
                  </button>
                )}

                {activeUnit === '' &&
                  !isFilterOpen &&
                  floorGalleryItemsCount > 1 && (
                    <GalleryControl
                      galleryName="floorGallery"
                      isSlideShowPlaying={isSlideShowPlaying}
                      onChangeSlideShowStatus={(newStatus, data) =>
                        handleOnChangeFloorGallerySlideShowStatus(
                          newStatus,
                          data
                        )
                      }
                    />
                  )}

                {unitSelected && unitGalleryItemLength > 1 && (
                  <GalleryControl
                    galleryName="unitGallery"
                    isSlideShowPlaying={isUnitGallerySlideShowPlaying}
                    onChangeSlideShowStatus={(newStatus, data) =>
                      handleOnChangeUnitGallerySlideShowStatus(newStatus, data)
                    }
                  />
                )}
              </div>
              <div className="relative m-10 w-full xl:w-3/6">
                {activeUnit === '' && (
                  <div className={`${isFilterOpen ? 'hidden' : ''}`}>
                    <FloorPlateGallery
                      floorGalleryControlSession={session?.floorGallery}
                      activeLevel={activeLevel}
                      activeBlock={activeBlock}
                      floorGalleryItemsCount={(count: number) =>
                        setFloorGalleryItemsCount(count)
                      }
                    />
                  </div>
                )}
                {session && (
                  <Filter
                    projectIdentity={projectIdentity}
                    toggle={toggleFilter}
                    isOpen={isFilterOpen}
                    unitFilter={session.building.unitFilter}
                    aspects={buildingPayload.aspects}
                  />
                )}
              </div>
            </div>
            <div className="h-full w-full">
              <div className="avavenirLight my-4 text-base text-white lg:text-xl">
                {buildingLabel}
              </div>
              {unitSelected ? (
                <>
                  <div className="align-center flex justify-end">
                    {unitSelected.metas.status === STATUS_AVAILABLE &&
                      (shortlist.find((res) => activeUnit === res.name) ? (
                        <button
                          type="button"
                          className="mr-auto flex items-center justify-center rounded-full bg-white px-4 font-GTWalsheimPro text-base text-blue-500 lg:text-xl"
                          onClick={() => dispatch(filterShortlist(activeUnit))}
                        >
                          Shortlisted
                          <ShortListSvg size="s" width="25px" height="45px" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={`font-GTWalsheimPro text-base lg:text-xl ${
                            shortlist.length >=
                            MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST
                              ? 'bg-gray-400'
                              : 'bg-white'
                          } mr-auto flex items-center justify-center rounded-full px-4`}
                          disabled={
                            shortlist.length >=
                            MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST
                          }
                          onClick={() => {
                            const unit = units.find(
                              (res) => res.name === activeUnit
                            )
                            if (unit) {
                              dispatch(setShortlist(unit))
                            }
                          }}
                        >
                          Shortlist
                          <ShortListSvg size="s" width="25px" height="45px" />
                        </button>
                      ))}
                    {hasPanoramicPayload() && (
                      <button
                        onClick={() => {
                          history.push('panoramic')
                          firebaseControlQuery.updateRoute('panoramic')
                        }}
                        type="button"
                      >
                        <VirtualGogglesSvg className="h-12 w-12 text-white" />
                      </button>
                    )}
                    <button onClick={handleInfoPopup} type="button">
                      <InfoCircleSvg
                        className={`h-12 w-12 ${
                          infoPopup ? 'text-blue-500' : 'text-white'
                        }`}
                      />
                    </button>
                  </div>
                  <div className="mb-10 mt-4 w-full xl:w-3/6">
                    <UnitGallery
                      key={unitSelected.id}
                      projectIdentity={projectIdentity}
                      unit={unitSelected}
                      unitGalleryControlSession={session?.unitGallery}
                      getUnitGalleryItemLength={setUnitGalleryItemLength}
                      onlyShowUnitGalleries={
                        projectIdentity.onlyShowUnitGalleries
                      }
                    />
                  </div>
                </>
              ) : (
                session && (
                  <UnitListing
                    units={units}
                    trigger={handleUnitClick}
                    filter={session.building.unitFilter}
                  />
                )
              )}
            </div>
          </DataHandler>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore, shortlist }: RootStateFirebase) => ({
    session: getSession(firestore),
    shortlist,
    projectIdentity,
  })
)(Building)
