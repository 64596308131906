import React from 'react'

import { SvgProps } from '@components/svg/types'

const CompassSvg = ({
  width,
  height,
  className,
  size,
  styles,
  fill,
  rotate,
  deg,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'
  const scale = {
    xl: 3.0,
    lg: 1.5,
    m: 1.0,
    s: 0.8,
    xs: 0.6,
  }

  const rotateDegrees = {
    top: '0deg',
    down: '180deg',
    right: '90deg',
    left: '270deg',
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{
          transform: `rotate(${
            !deg ? rotateDegrees[rotateValue] : deg
          }) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '50'}
      height={height || '50'}
      fill="currentColor"
      viewBox="0 0 512 512"
      stroke="currentColor"
    >
      <path
        fill={fill || 'black'}
        d="M306.7 325.1L162.4 380.6C142.1 388.1 123.9 369 131.4 349.6L186.9 205.3C190.1 196.8 196.8 190.1 205.3 186.9L349.6 131.4C369 123.9 388.1 142.1 380.6 162.4L325.1 306.7C321.9 315.2 315.2 321.9 306.7 325.1V325.1zM255.1 224C238.3 224 223.1 238.3 223.1 256C223.1 273.7 238.3 288 255.1 288C273.7 288 288 273.7 288 256C288 238.3 273.7 224 255.1 224V224zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
      />
    </svg>
  )
}
export default CompassSvg
