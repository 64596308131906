import React from 'react'

import { SvgProps } from '@components/svg/types'

const ChevronDownSvg = ({
  className,
  size,
  styles,
  fill,
  rotate,
}: SvgProps) => {
  const sizeValue = size || 'm'

  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 2.0,
    lg: 1.5,
    m: 1.0,
    s: 0.5,
    xs: 0.25,
  }

  const rotateDegrees = {
    left: '0deg',
    right: '180deg',
    top: '90deg',
    down: '270deg',
  }

  return (
    <svg
      style={{
        ...styles,
        ...{
          height: 'auto',
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeValue]})`,
        },
      }}
      width="auto"
      height="auto"
      className={`h-4 w-4 ${className}`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 9l-7 7-7-7"
      ></path>
    </svg>
  )
}

export default ChevronDownSvg
