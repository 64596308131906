import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setRemoteRefreshTriggerKey } from '@store/actionSlices/appConfig'
import {
  setRemoteReleaseVersion,
  setShowcaseReleaseVersion,
} from '@store/actionSlices/releaseInfo'
import { TokenInterface } from '@store/actionSlices/token'
import {
  ProjectIdentity,
  ReleaseInfoInterface,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import { ConfirmationModal } from '@components/modals'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { generateId, getSession } from '@utilities/firebase-util'
import { hasToken } from '@utilities/token-helper'

export interface ComponentProps {
  map: SessionMap
  releaseInfoFirebase: ReleaseInfoInterface
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  token: TokenInterface
  releaseInfo: ReleaseInfoInterface
  remoteRefreshTriggerKey: string
}

const ReleaseHandler = ({
  map,
  releaseInfoFirebase,
  session,
  projectIdentity,
  token,
  releaseInfo,
  remoteRefreshTriggerKey,
}: ComponentProps) => {
  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
    map,
  })

  const dispatch = useDispatch()

  const [
    initialRemoteVersionUpdatedInLocalState,
    setInitialRemoteVersionUpdatedInLocalState,
  ] = React.useState(false)
  const [showShowcaseVersionReleaseModal, toggleShowcaseVersionReleaseModal] =
    React.useState(false)
  const [showcaseVersionReleaseMessage, setShowcaseVersionReleaseMessage] =
    React.useState('')
  const [showRemoteVersionReleaseModal, toggleRemoteVersionReleaseModal] =
    React.useState(false)
  const [remoteVersionReleaseMessage, setRemoteVersionReleaseMessage] =
    React.useState('')

  const handleRefreshShowcaseButtonClick = async () => {
    const {
      showcase: { version },
    } = releaseInfoFirebase
    dispatch(setShowcaseReleaseVersion(version))
    const { sessionList } = projectIdentity
    sessionList.forEach(
      (mySession: { sessionId: string; sessionName: string }) => {
        firebaseControlQuery.updateCollectionBySessionId(
          mySession.sessionId,
          `showcaseRefreshTriggerKey`,
          generateId(10)
        )
      }
    )
  }

  const handleRefreshRemoteButtonClick = () => {
    dispatch(setRemoteReleaseVersion(releaseInfoFirebase.remote.version))
    window.location.reload()
  }

  React.useEffect(() => {
    if (hasToken(token)) {
      dispatch(setRemoteReleaseVersion(releaseInfoFirebase.remote.version))
      setInitialRemoteVersionUpdatedInLocalState(true)
    }
  }, [token])

  React.useEffect(() => {
    const { sessionId } = projectIdentity
    if (sessionId) {
      if (releaseInfo.showcase.version === '') {
        dispatch(
          setShowcaseReleaseVersion(releaseInfoFirebase.showcase.version)
        )
      }
    } else {
      dispatch(setShowcaseReleaseVersion(''))
    }
  }, [projectIdentity])

  React.useEffect(() => {
    if (hasToken(token)) {
      const {
        showcase: { version: showcaseVersionFirebase },
        remote: { version: remoteVersionFirebase },
      } = releaseInfoFirebase

      const {
        showcase: { version: currentShowcaseVersion },
        remote: { version: currentRemoteVersion },
      } = releaseInfo

      const { sessionId } = projectIdentity
      if (
        sessionId &&
        currentShowcaseVersion &&
        showcaseVersionFirebase !== currentShowcaseVersion
      ) {
        setShowcaseVersionReleaseMessage(
          `A new version (${showcaseVersionFirebase}) of showcase has been released.`
        )
        toggleShowcaseVersionReleaseModal(true)
      }

      if (
        initialRemoteVersionUpdatedInLocalState &&
        currentRemoteVersion &&
        remoteVersionFirebase !== currentRemoteVersion
      ) {
        setRemoteVersionReleaseMessage(
          `A new version (${remoteVersionFirebase}) of remote has been released.`
        )
        toggleRemoteVersionReleaseModal(true)
      }
    }
  }, [releaseInfoFirebase])

  const refreshAppOnRemoteRefreshTriggerKeyChange = (
    remoteRefreshTriggerKeyFirebase: string
  ) => {
    if (
      remoteRefreshTriggerKeyFirebase &&
      remoteRefreshTriggerKeyFirebase !== remoteRefreshTriggerKey
    ) {
      dispatch(setRemoteRefreshTriggerKey(remoteRefreshTriggerKeyFirebase))
      dispatch(setShowcaseReleaseVersion(releaseInfoFirebase.showcase.version))
      window.location.reload()
    }
  }

  React.useEffect(() => {
    if (session) {
      const { remoteRefreshTriggerKey: remoteRefreshTriggerKeyFirebase } =
        session
      refreshAppOnRemoteRefreshTriggerKeyChange(remoteRefreshTriggerKeyFirebase)
    }
  }, [session])

  return (
    <>
      <ConfirmationModal
        id="showcase-update-modal"
        isVisible={showShowcaseVersionReleaseModal}
        toggleModal={(toggle) => toggleShowcaseVersionReleaseModal(toggle)}
        getModalAction={handleRefreshShowcaseButtonClick}
        title="Update Available"
        message={showcaseVersionReleaseMessage}
        showCancelButton={false}
        showCloseOption={false}
        okButtonText="Refresh Showcase"
      />
      <ConfirmationModal
        id="remote-update-modal"
        isVisible={showRemoteVersionReleaseModal}
        toggleModal={(toggle) => toggleRemoteVersionReleaseModal(toggle)}
        getModalAction={handleRefreshRemoteButtonClick}
        title="Update Available"
        message={remoteVersionReleaseMessage}
        showCancelButton={false}
        showCloseOption={false}
        okButtonText="Refresh Remote"
      />
    </>
  )
}

export default connect(
  ({
    firestore,
    projectIdentity,
    token,
    releaseInfo,
    appConfig: { remoteRefreshTriggerKey },
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectIdentity,
    token,
    releaseInfo,
    remoteRefreshTriggerKey,
  })
)(ReleaseHandler)
