import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setProjectIdentity } from '@store/actionSlices/project'
import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Navigation from '@components/navigation'

import {
  selectFromResult as selectFromConfigResult,
  useGetConfigQuery,
} from '@api/config'
import {
  Polygon,
  selectFromResult as selectInteractiveFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'

export interface AreaViewProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const AreaView = ({ projectIdentity, session }: AreaViewProps) => {
  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [blocks, setBlocks] = React.useState<Array<Polygon>>([])

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectInteractiveFromResult }
  )

  const configPayload = useGetConfigQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromConfigResult }
  )

  React.useEffect(() => {
    const { prices, configInfo, isLoaded } = configPayload
    if (isLoaded) {
      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          prices: {
            min: prices.minPrice,
            max: prices.maxPrice,
          },
          showPrice: configInfo?.showPrice,
          hideSoldPrice: configInfo?.hide_sold_price ?? false,
          longhand: configInfo?.longhand ?? false,
          hideFilter: configInfo?.hideFilter ?? false,
          onlyShowUnitGalleries: configInfo?.onlyShowUnitGalleries ?? false,
        })
      )
    }
  }, [configPayload])

  const updateTriggerBuilding = (state: boolean) =>
    firebaseControlQuery.updateCollection('areaView.triggerBuilding', state)

  const handleMapClick = async (poly: Polygon) => {
    if (blocks.length > 1) {
      await firebaseControlQuery.updateCollection(
        'building.activeBlock',
        poly.groupId
      )
    }
    await updateTriggerBuilding(true)
  }

  const resetBuildingMap = () =>
    firebaseControlQuery.update({
      [`building.activeBlock`]: '',
      [`building.activeLevel`]: '',
      [`building.activeUnit`]: '',
      [`building.unitGalleryIndex`]: 0,
    })

  const resetBuildingView = () =>
    firebaseControlQuery.update({
      [`building.sidePanelFolded`]: true,
      [`building.unitFilter`]: {
        availability: 'available',
        range: {
          min: projectIdentity.prices.min,
          max: projectIdentity.prices.max,
        },
        configurations: {
          bedroom: 0,
          study: 0,
          bathroom: 0,
          powderRoom: 0,
          carpark: 0,
        },
        aspect: 'All',
        showAvailable: false,
        apply: false,
      },
    })

  React.useEffect(() => {
    const { maps } = interactivePayload
    if (blocks.length === 0) {
      if (maps.areaView?.polygons) {
        setBlocks(maps.areaView.polygons.map((res: Polygon) => res))
      }
    }
  }, [interactivePayload])

  React.useEffect(() => {
    if (session) {
      const {
        areaView: { triggerBuilding },
        building: { activeLevel, activeUnit },
      } = session
      if (activeLevel || activeUnit) {
        resetBuildingMap()
      }
      if (triggerBuilding) {
        setTimeout(async () => {
          await updateTriggerBuilding(false)
          await resetBuildingView()
          await firebaseControlQuery.updateRoute('level-view')
        }, 1000)
      }
    }
  }, [session])

  return (
    <Container className="overflow-hidden px-10">
      <div className="mx-4 h-full lg:mx-10">
        <Navigation />
        <DataHandler
          payload={{
            ...interactivePayload,
            data: blocks,
          }}
        >
          <DataHandler
            payload={{
              ...configPayload,
              data: configPayload.prices,
            }}
          >
            <div className="background-none my-6 flex h-4/6 flex-wrap items-center justify-center border-t-2 border-white bg-opacity-0 sm:h-5/6 ">
              {blocks.map((res) => (
                <button
                  key={res.groupId}
                  onClick={() => handleMapClick(res)}
                  type="button"
                  className="cursor-hand m-4 rounded-full bg-white px-14 py-7 font-bold text-gray-700 box-shadow"
                >
                  {res.label || res.groupId}
                </button>
              ))}
            </div>
          </DataHandler>
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(AreaView)
