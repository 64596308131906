import React from 'react'
import { connect } from 'react-redux'

import {
  GalleryControlInterface,
  GalleryInterface,
  GalleryItemInterface,
  ProjectIdentity,
  RootStateFirebase,
  TabInterface,
} from '@store/types'

import {
  GalleryControl,
  GalleryItem,
  GalleryTab,
} from '@components/gallery-handler/elements'

import FirebaseControlQuery from '@utilities/firebase-control-query'

import { SlideshowTransitionProps } from './elements/gallery-control'

export interface GallerySessionInterface {
  galleryControl: GalleryControlInterface
}

export interface GalleryHandlerInterface {
  galleryName: string
  gallerySession: GallerySessionInterface | undefined
  galleries: Array<GalleryInterface>
  projectIdentity: ProjectIdentity
  columnGridClassName?: string
  showBorderOnActiveItem?: boolean
  enableSlideShow?: boolean
}

const GalleryHandler = ({
  galleryName,
  galleries,
  gallerySession,
  projectIdentity,
  columnGridClassName = 'grid-cols-4 gap-6',
  showBorderOnActiveItem = false,
  enableSlideShow = false,
}: GalleryHandlerInterface) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [activeGalleryTabIndex, setActiveGalleryTabIndex] = React.useState(0)
  const [activeGalleryItemIndex, setActiveGalleryItemIndex] = React.useState(0)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] =
    React.useState<boolean>(false)

  const [tabs, setTabs] = React.useState<Array<TabInterface>>([])
  const [galleryItemsCount, setGalleryItemsCount] = React.useState<number>(0)

  const activeGalleryItems = (): Array<GalleryItemInterface> =>
    galleries[activeGalleryTabIndex]?.items

  const buildTab = () => {
    const myTabs: TabInterface[] = []
    galleries.forEach((gallery: GalleryInterface, index: number) => {
      if (gallery.label && gallery.label !== '') {
        myTabs.push({ index, label: gallery.label })
      }
    })
    setTabs(myTabs)
  }

  const handleTabChange = async (index: number) => {
    await firebaseControlQuery.update({
      [`${galleryName}.galleryControl.activeTabIndex`]: index,
      [`${galleryName}.galleryControl.activeItemIndex`]: 0,
      [`${galleryName}.galleryControl.isPlaying`]: false,
    })
  }

  const handleItemClick = async (index: number, type: string) => {
    firebaseControlQuery.update({
      [`${galleryName}.galleryControl.isPlaying`]: false,
      [`${galleryName}.galleryControl.activeItemIndex`]: index,
    })
    if (type === 'video') {
      await firebaseControlQuery.update({
        [`${galleryName}.playerControl.isPlayerVisible`]: true,
        [`${galleryName}.playerControl.playerState`]: 'play',
      })
    }
  }

  const handleOnChangeSlideShowStatus = (
    status: boolean,
    data?: SlideshowTransitionProps
  ) => {
    firebaseControlQuery.update({
      [`${galleryName}.galleryControl.isPlaying`]: status,
      [`${galleryName}.galleryControl.transitionTime`]:
        data?.newTransitionTime ?? 3000,
      [`${galleryName}.galleryControl.transitionType`]:
        data?.newTransitionType ?? 'slide',
    })
  }

  React.useEffect(() => {
    buildTab()
  }, [galleries])

  React.useEffect(() => {
    if (gallerySession) {
      const {
        galleryControl: {
          activeItemIndex: activeItemIndexFirebase,
          activeTabIndex: activeTabIndexFirebase,
          isPlaying: isPlayingFirebase,
        },
      } = gallerySession

      setActiveGalleryItemIndex(activeItemIndexFirebase)
      setActiveGalleryTabIndex(activeTabIndexFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
      setGalleryItemsCount(galleries[activeTabIndexFirebase]?.items.length)
    }
  }, [gallerySession])

  React.useEffect(() => {
    if (isSlideShowPlaying) {
      setActiveGalleryItemIndex(-1)
    } else {
      setActiveGalleryItemIndex(0)
    }
  }, [isSlideShowPlaying])

  React.useEffect(
    () => () => {
      firebaseControlQuery.update({
        [`${galleryName}.galleryControl.isPlaying`]: false,
        [`${galleryName}.galleryControl.transitionTime`]: 3000,
        [`${galleryName}.galleryControl.activeTabIndex`]: 0,
        [`${galleryName}.galleryControl.activeItemIndex`]: 0,
        [`${galleryName}.galleryControl.transitionType`]: 'slide',
      })
    },
    []
  )

  return (
    <>
      <div
        className={`gallery flex w-full items-center font-GTWalsheimPro ${
          tabs.length > 1 ? 'justify-between' : 'justify-end'
        }`}
      >
        {tabs.length > 1 && (
          <GalleryTab
            tabs={tabs}
            activeGalleryTabIndex={activeGalleryTabIndex}
            onTabChange={handleTabChange}
            className="mb-5"
          />
        )}

        {enableSlideShow && galleryItemsCount > 1 && (
          <GalleryControl
            galleryName={galleryName}
            isSlideShowPlaying={isSlideShowPlaying}
            onChangeSlideShowStatus={(newStatus, data) =>
              handleOnChangeSlideShowStatus(newStatus, data)
            }
          />
        )}
      </div>

      <div className={`grid ${columnGridClassName}`}>
        {activeGalleryItems().map(
          (galleryItem: GalleryItemInterface, index: number) => (
            <GalleryItem
              key={`gallery-item-${index.toString()}`}
              galleryData={{ index, item: galleryItem }}
              activeGalleryItemIndex={activeGalleryItemIndex}
              onItemClick={handleItemClick}
              showBorderOnActiveItem={showBorderOnActiveItem}
            />
          )
        )}
      </div>
    </>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(GalleryHandler)
