import React from 'react'
import { ReactNotifications } from 'react-notifications-component'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import Routes from '@src/routes'

import { TokenInterface } from '@store/actionSlices/token'
import type {
  ProjectIdentity,
  ReleaseInfoInterface,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import IdleTimeHandler from '@components/idle-time-handler'
import LoadingIndicator from '@components/loading-indicator'
import ReleaseHandler from '@components/release-handler'

import useConnectionIndicator from '@utilities/connection-indicator'
import FirebaseControlQuery from '@utilities/firebase-control-query'
import TokenRenewalHandler from '@utilities/token-renewal-handler'

interface FirebaseStateType extends RootStateFirebase {
  devsuite: SessionMap
  map: SessionMap
  releaseInfoFirebase: ReleaseInfoInterface
  projectIdentity: ProjectIdentity
  token: TokenInterface
  isOnline: boolean
}

const App = ({
  devsuite: session,
  map,
  releaseInfoFirebase,
  projectIdentity,
  token,
  isOnline,
}: FirebaseStateType) => {
  useConnectionIndicator({ token, isOnline })
  TokenRenewalHandler({ token })

  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
    map,
  })

  const handleClientCheck = (client: boolean) => {
    if (!client) {
      firebaseControlQuery.updateCollection('client.remote', true)
    }
  }

  React.useEffect(() => {
    if (session) {
      const {
        client: { remote },
      } = session
      handleClientCheck(remote)
    }
  }, [session])

  return (
    <>
      <ReactNotifications />
      <Routes />
      {releaseInfoFirebase && (
        <ReleaseHandler map={map} releaseInfoFirebase={releaseInfoFirebase} />
      )}
      <IdleTimeHandler map={map} />
      <LoadingIndicator />
    </>
  )
}

export default compose(
  connect(
    ({
      projectIdentity,
      firestore: {
        data: { devsuite, map, releaseInfoFirebase },
      },
      statusIndicator: { isOnline },
      token,
    }: RootStateFirebase) => ({
      devsuite,
      map,
      releaseInfoFirebase,
      projectIdentity,
      token,
      isOnline,
    })
  ),
  firestoreConnect(({ projectIdentity }: RootStateFirebase) => {
    const FirebaseQuery: Array<any> = [
      {
        collection: 'session-base',
        doc: 'map',
        storeAs: 'map',
      },
      {
        collection: 'release',
        storeAs: 'releaseInfoFirebase',
      },
    ]
    if (projectIdentity) {
      const { projectId, sessionId } = projectIdentity
      if (projectId && sessionId) {
        return [
          ...FirebaseQuery,
          {
            collection: 'devsuite',
            doc: projectId,
            subcollections: [{ collection: 'session', doc: sessionId }],
            storeAs: 'devsuite',
          },
        ]
      }
    }
    return FirebaseQuery
  })
)(App) as React.ComponentType
