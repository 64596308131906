import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase } from '@store/types'

export interface LoadingIndicatorInterface {
  isLoading: boolean
}

const LoadingIndicator = ({ isLoading }: LoadingIndicatorInterface) => {
  if (!isLoading) return null

  return (
    <div className="fixed inset-0 z-50 bg-gray-100 bg-opacity-50">
      <div
        style={{ borderTopColor: 'transparent' }}
        className="border-white-400 absolute left-50% top-50% h-16 w-16 animate-spin rounded-full border-4 border-solid text-center"
      ></div>
    </div>
  )
}

export default connect(
  ({ statusIndicator: { isLoading } }: RootStateFirebase) => ({
    isLoading,
  })
)(LoadingIndicator)
