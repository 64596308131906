import { useFirestore } from 'react-redux-firebase'

import type { ProjectIdentity, SessionMap } from '@store/types'

export interface FirebaseControlQueryProps {
  projectIdentity: ProjectIdentity
  map?: SessionMap | undefined
}

const FirebaseControlQuery = ({
  projectIdentity,
  map,
}: FirebaseControlQueryProps): any => {
  const firestore = useFirestore()

  const doesProjectExist = async (projectId: string) => {
    const { exists } = await firestore
      .collection('devsuite')
      .doc(projectId)
      .get()
    return exists
  }

  const updateConnection = async (connected: boolean, sessionId?: string) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(
        (sessionId || projectIdentity.sessionId)
          .toUpperCase()
          .replace(/\s/g, '')
      )
      .update({
        connected,
      })
    return response
  }

  const updateRoute = async (activeRoute: string) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .update({ activeRoute })
    return response
  }

  const refreshSession = async () => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .update({ ...(map || {}), connected: true })
    return response
  }

  const updateSessionOwner = (sessionOwners: Array<string>) => {
    const response = firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .update({ sessionOwners })
    return response
  }

  const updateCollection = async (collection: string, value: any) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .update({
        [collection]: value,
      })
    return response
  }

  const update = async (value: { [key: string]: any }) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(projectIdentity.sessionId)
      .update(value)
    return response
  }

  const updateCollectionBySessionId = async (
    sessionId: string,
    collection: string,
    value: any
  ) => {
    const response = await firestore
      .collection('devsuite')
      .doc(projectIdentity.projectId)
      .collection('session')
      .doc(sessionId)
      .update({
        [collection]: value,
      })
    return response
  }

  return {
    updateConnection,
    doesProjectExist,
    updateRoute,
    refreshSession,
    updateSessionOwner,
    updateCollection,
    update,
    updateCollectionBySessionId,
  }
}

export default FirebaseControlQuery
