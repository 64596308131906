import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

export interface PanoramicDataInterface {
  id: string
  type: string
  name: string
  label: string
  image: string
  pitch: number
  yaw: number
}

export interface PanoramicPayloadInterface {
  data: Array<PanoramicDataInterface>
}

type PanoramicTypeInterface = {
  projectName: string
}

export const panoramicApi = createApi({
  reducerPath: 'panoramicApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getPanoramic: builder.query<
      PanoramicPayloadInterface,
      PanoramicTypeInterface
    >({
      query: (params: PanoramicTypeInterface) => ({
        url: `/v1/${params.projectName}/panoramic`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  panoramicData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})

export const { useGetPanoramicQuery } = panoramicApi
