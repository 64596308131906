import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'
import Navigation from '@components/navigation'

import {
  selectFromResult as selectFromLocationResult,
  useGetLocationQuery,
} from '@api/location'

import { getSession } from '@utilities/firebase-util'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectName: string
}

const Location = ({ session, projectName }: PagePropsInterface) => {
  const locationPayload = useGetLocationQuery(
    { projectName },
    { selectFromResult: selectFromLocationResult }
  )

  return (
    <Container>
      <div className="mx-4 lg:mx-10">
        <Navigation />
        <div className="my-6 border-b-2"></div>
        <DataHandler
          payload={{ ...locationPayload, data: locationPayload.locationData }}
        >
          <GalleryHandler
            galleryName="location"
            galleries={locationPayload.locationData}
            gallerySession={session?.location}
            enableSlideShow
          />
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(
  ({ projectIdentity: { projectId }, firestore }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName: projectId,
  })
)(Location)
