import React from 'react'
import { connect, useDispatch } from 'react-redux'

import {
  setFirebaseHost as storeFirebaseHost,
  setFirebasePort as storeFirebasePort,
  setOfflineMode as storeOfflineMode,
} from '@store/actionSlices/appConfig'
import { RootStateType } from '@store/store'

import { CloseCircleSvg } from '@components/svg'
import ToggleSwitch from '@components/toggle-switch'

import { removeOfflineResponseFromLocalStorage } from '@utilities/offline-handler-util'

export interface ModalInterface {
  visibility: boolean
  toggleModal: (arg: boolean) => void
  currentOfflineMode: boolean
  currentFirebaseHost: string
  currentFirebasePort: string
}

const OfflineModeModal = ({
  visibility,
  toggleModal,
  currentOfflineMode,
  currentFirebaseHost,
  currentFirebasePort,
}: ModalInterface) => {
  const dispatch = useDispatch()

  const [offlineToggleSwitchState, setOfflineToggleSwitchState] =
    React.useState(false)
  const [
    firebaseOfflineToggleSwitchState,
    setFirebaseOfflineToggleSwitchState,
  ] = React.useState(false)
  const [firebasePort, setFirebasePort] = React.useState('')
  const [firebaseHost, setFirebaseHost] = React.useState('localhost')
  const [firebasePortError, setFirebasePortError] = React.useState('')
  const [message, setMessage] = React.useState('')

  const isValidPort = (value: number) => {
    const MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT = 65535
    return value > 0 && value <= MAXIMUM_VALUE_FOR_IPv4_NETWORK_PORT
  }

  const handleOfflineToggleSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { checked: state },
    } = event
    setOfflineToggleSwitchState(state)
    setMessage('')
    if (state) {
      setMessage('This will cache the application data.')
    }
  }

  const handleFirebaseOfflineToggleSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { checked: state },
    } = event
    setFirebaseOfflineToggleSwitchState(state)
  }

  const handleSave = () => {
    setFirebasePortError('')
    if (firebaseOfflineToggleSwitchState && !firebaseHost) {
      setFirebasePortError('Host/IP field is required.')
      return
    }

    if (firebaseOfflineToggleSwitchState && !firebasePort) {
      setFirebasePortError('Port field is required.')
      return
    }

    if (
      firebaseOfflineToggleSwitchState &&
      !isValidPort(Number(firebasePort))
    ) {
      setFirebasePortError('The given port is not valid.')
      return
    }

    dispatch(storeOfflineMode(offlineToggleSwitchState))
    dispatch(
      storeFirebaseHost(firebaseOfflineToggleSwitchState ? firebaseHost : '')
    )
    dispatch(
      storeFirebasePort(firebaseOfflineToggleSwitchState ? firebasePort : '')
    )

    if (!offlineToggleSwitchState) {
      removeOfflineResponseFromLocalStorage()
    }

    window.location.reload()
  }

  const handleClear = () => {
    setFirebaseHost('localhost')
    setFirebasePort('')
    setFirebasePortError('')
  }

  const handleClose = () => {
    setOfflineToggleSwitchState(currentOfflineMode)
    setFirebaseOfflineToggleSwitchState(currentFirebasePort !== '')
    setFirebaseHost(currentFirebaseHost)
    setFirebasePort(currentFirebasePort)
    setFirebasePortError('')
    setMessage('')
    toggleModal(false)
  }

  React.useEffect(() => {
    setOfflineToggleSwitchState(currentOfflineMode)
  }, [currentOfflineMode])

  React.useEffect(() => {
    setFirebaseOfflineToggleSwitchState(currentFirebasePort !== '')
    setFirebaseHost(currentFirebaseHost || 'localhost')
    setFirebasePort(currentFirebasePort)
  }, [currentFirebasePort, currentFirebaseHost])

  return (
    <div
      id="offline-mode-modal"
      aria-hidden="true"
      className={`h-modal fixed left-0 right-0 top-4 z-50 transform items-center justify-center overflow-y-auto overflow-x-hidden duration-500
      ease-in-out md:inset-0 md:h-full ${
        visibility ? 'translate-y-0' : '-translate-y-full'
      }
      `}
    >
      <div className="m-auto mt-32 h-full w-full max-w-2xl px-4 md:h-auto">
        <div className="rounded-lg bg-white shadow dark:bg-gray-700">
          <div className="flex items-center justify-between rounded-t border-b p-5 dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
              Offline Mode Settings
            </h3>
            <button
              type="button"
              onClick={() => handleClose()}
              data-modal-toggle="offline-mode-modal"
            >
              <CloseCircleSvg fill="#000000" size="s" />
            </button>
          </div>
          <div className="space-y-6 p-6">
            <div className="flex items-center">
              <span className="mr-2">Offline Mode:</span>
              <ToggleSwitch
                key="offlineToggleSwitch"
                onChangeCallback={handleOfflineToggleSwitch}
                initialState={offlineToggleSwitchState}
              />
              <span className="ml-2 text-sm">
                [
                {currentOfflineMode
                  ? 'You are using the app in offline mode.'
                  : 'Use the app in offline mode by switching the toggle.'}
                ]
              </span>
            </div>
            <div className="flex items-center">
              <span className="mr-2">Offline Remote:</span>
              <ToggleSwitch
                key="firebaseOfflineToggleSwitch"
                onChangeCallback={handleFirebaseOfflineToggleSwitch}
                initialState={firebaseOfflineToggleSwitchState}
              />
              <span className="ml-2 text-sm">
                [
                {currentFirebasePort !== ''
                  ? `Remote is connected to port ${currentFirebasePort}`
                  : 'Use the remote in offline mode by switching the toggle.'}
                ]
              </span>
            </div>
            <div
              className={`${firebaseOfflineToggleSwitchState ? '' : 'hidden'}`}
            >
              <div className="flex">
                <div className="flex w-3/6">
                  <span className="flex items-center rounded-l border border-r-0 border-solid border-neutral-300 px-3 text-center text-base font-normal text-neutral-700">
                    Host/IP
                  </span>
                  <input
                    type="text"
                    name="firebaseHost"
                    value={firebaseHost}
                    className="w-full border-gray-200 focus:border-gray-200 focus:ring-0"
                    onChange={(event) => setFirebaseHost(event.target.value)}
                    placeholder="localhost"
                  />
                </div>
                <div className="flex w-3/6">
                  <span className="flex items-center border border-r-0 border-solid border-neutral-300 px-3 text-center text-base font-normal text-neutral-700">
                    Port
                  </span>
                  <input
                    type="number"
                    name="firebasePort"
                    value={firebasePort}
                    min={0}
                    className="w-full rounded-r border-gray-200 focus:border-gray-200 focus:ring-0"
                    onChange={(event) => setFirebasePort(event.target.value)}
                    placeholder="8080"
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  {firebasePortError && (
                    <small className="text-red-500">{firebasePortError}</small>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    className="text-xs text-blue-500"
                    onClick={() => handleClear()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between border-t border-gray-200 px-6 py-3">
            <div className="text-red-500">{message}</div>
            <button
              type="button"
              onClick={() => handleSave()}
              className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-0 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    appConfig: {
      offlineMode: currentOfflineMode,
      firebaseHost: currentFirebaseHost,
      firebasePort: currentFirebasePort,
    },
  }: RootStateType) => ({
    currentOfflineMode,
    currentFirebaseHost,
    currentFirebasePort,
  })
)(OfflineModeModal)
