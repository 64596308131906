import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import InteractiveMapControl from '@components/interactive-map-control'
import Navigation from '@components/navigation'

import {
  selectFromResult as selectFromInterctiveMapResult,
  useGetInteractiveMapQuery,
} from '@api/interactive-map'

import { getSession } from '@utilities/firebase-util'

interface SessionMap {
  interactiveMap: Array<string>
}

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectName: string
}

const InteractiveMap = ({ session, projectName }: PagePropsInterface) => {
  const interactiveMapPayload = useGetInteractiveMapQuery(
    { projectName },
    { selectFromResult: selectFromInterctiveMapResult }
  )

  return (
    <Container>
      <div className="mx-4 lg:mx-10">
        <Navigation />
        <div className="my-6 border-b-2"></div>
        <DataHandler
          payload={{
            ...interactiveMapPayload,
            data: interactiveMapPayload.interactiveMapData,
          }}
        >
          <InteractiveMapControl
            interactiveMapList={interactiveMapPayload.interactiveMapData}
            interactiveMapSession={session?.interactiveMap ?? []}
          />
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectName: projectIdentity.projectId,
}))(InteractiveMap)
