import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'

import { TokenInterface } from '@store/actionSlices/token'
import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
  User,
} from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import { hasToken } from '@utilities/token-helper'

export type ProtectedRouteProps = {
  redirectSessionPath: string
  authenticationPath: string
  session: SessionMap | undefined
  user: User
  projectIdentity: ProjectIdentity
  token: TokenInterface
} & RouteProps

function ProtectedRoutes({
  redirectSessionPath,
  authenticationPath,
  session,
  projectIdentity,
  user,
  token,
  ...routeProps
}: ProtectedRouteProps) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(true)
  const [sessionNotActive, setSessionNotActive] = React.useState(true)

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  React.useEffect(() => {
    if (!hasToken(token)) {
      setIsAuthenticated(false)
    }
  }, [token])

  const goToSession = async () => {
    const now = new Date()
    await firebaseControlQuery.updateSessionOwner([
      ...(session?.sessionOwners.filter((res) => res.email !== user.email) ||
        []),
      {
        name: user.name,
        email: user.email,
        lastUpdate: now.toString(),
      },
    ])
    setSessionNotActive(false)
  }

  React.useEffect(() => {
    if (session?.sessionOwners) {
      goToSession()
    }
  }, [session])

  if (isAuthenticated && sessionNotActive) {
    return <Route {...routeProps} />
  }

  if (!sessionNotActive) {
    return <Redirect to={{ pathname: redirectSessionPath }} />
  }

  return <Redirect to={{ pathname: authenticationPath }} />
}

export default connect(
  ({ projectIdentity, token, firestore, user }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectIdentity,
    token,
    user,
  })
)(ProtectedRoutes)
