import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'

export interface InputInterface {
  value: string
  isValid: boolean
  message: string
}

const InputHandler = (
  e: ChangeEvent<HTMLInputElement>,
  setState: Dispatch<SetStateAction<InputInterface>>
) => {
  setState({
    value: e.target.value,
    isValid: e.target.validity.valid,
    message: e.target.validationMessage,
  })
}

export default InputHandler
