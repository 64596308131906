import React from 'react'

import {
  GalleryControlInterface,
  GalleryInterface,
  GalleryItemInterface,
  PlayerControlInterface,
  ProjectIdentity,
} from '@store/types'

import GalleryHandler from '@components/gallery-handler'
import PlayerControl from '@components/player-control'

import { Unit, ViewLineImage } from '@api/building'
import {
  UnitGalleryInterface,
  selectFromResult as selectFromUnitGalleryResult,
  useGetUnitGalleryQuery,
} from '@api/unit-gallery'

export interface UnitGalleryControlSessionInterface {
  galleryControl: GalleryControlInterface
  playerControl: PlayerControlInterface
}
interface UnitGalleryProps {
  projectIdentity: ProjectIdentity
  unit: Unit
  unitGalleryControlSession: UnitGalleryControlSessionInterface | undefined
  getUnitGalleryItemLength: (unitGalleryItemLength: number) => void
  onlyShowUnitGalleries: boolean
}
const UnitGallery = ({
  projectIdentity,
  unit,
  unitGalleryControlSession,
  getUnitGalleryItemLength,
  onlyShowUnitGalleries,
}: UnitGalleryProps) => {
  const BUCKET_URL = process.env.REACT_APP_BUCKET_LEGACY
  const [unitGallery, setUnitGallery] = React.useState<Array<GalleryInterface>>(
    []
  )

  const unitGalleryPayload = useGetUnitGalleryQuery(
    { projectName: projectIdentity.projectId, unitId: unit.id },
    { selectFromResult: selectFromUnitGalleryResult }
  )

  const { unitGalleryData } = unitGalleryPayload

  const buildGallery = () => {
    const projectIdHash = projectIdentity.projectUUID
    const galleryItems: Array<GalleryItemInterface> = []

    const {
      metas: { floorImage, viewLineImages },
    } = unit

    if (!onlyShowUnitGalleries && viewLineImages && viewLineImages.length > 0) {
      viewLineImages.forEach((viewLineImage: ViewLineImage) => {
        galleryItems.push({
          imageSource: `${BUCKET_URL}/${projectIdHash}/views/${viewLineImage.src}`,
        })
      })
    }

    if (!onlyShowUnitGalleries && floorImage) {
      galleryItems.unshift({
        imageSource: `${BUCKET_URL}/${projectIdHash}/floorplans/preview/${floorImage}`,
      })
    }

    if (unitGalleryData.length > 0) {
      unitGalleryData.forEach((galleryItem: UnitGalleryInterface) => {
        const imageSource =
          galleryItem.type === 'video'
            ? galleryItem.thumbnail || ''
            : galleryItem.src
        const videoSource = galleryItem.type === 'video' ? galleryItem.src : ''
        galleryItems.push({ imageSource, videoSource })
      })
    }

    getUnitGalleryItemLength(galleryItems.length)

    if (galleryItems.length > 0) {
      setUnitGallery([{ items: galleryItems }])
    }
  }

  React.useEffect(() => {
    buildGallery()
  }, [unit])

  React.useEffect(() => {
    if (unitGalleryData.length > 0) {
      buildGallery()
    }
  }, [unitGalleryData])

  return (
    <>
      {unitGallery.length > 0 ? (
        <>
          <GalleryHandler
            columnGridClassName="grid-cols-1 gap-6"
            galleryName="unitGallery"
            galleries={unitGallery}
            gallerySession={unitGalleryControlSession}
            showBorderOnActiveItem
          />
          <PlayerControl
            galleryName="unitGallery"
            playerSession={unitGalleryControlSession?.playerControl}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}
export default UnitGallery
