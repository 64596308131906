import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setProjectIdentity } from '@store/actionSlices/project'
import { removeShortlist } from '@store/actionSlices/shortlist'
import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap as SessionMapTypes,
  User,
} from '@store/types'

import { BadgeGroup } from '@components/badge'
import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'
import GalleryControl, {
  SlideshowTransitionProps,
} from '@components/gallery-handler/elements/gallery-control'
import Navigation from '@components/navigation'
import PlayerControl from '@components/player-control'
import { OfflineSvg } from '@components/svg'

import { selectFromResult, useGetVisionQuery } from '@api/vision'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import { getQueryStringParams } from '@utilities/helper'

import RefreshOption from './refresh-option'

export interface VisionProps {
  projectIdentity: ProjectIdentity
  session: SessionMapTypes | undefined
  map: SessionMapTypes | undefined
  user: User
}

const Vision = ({ projectIdentity, session, map, user }: VisionProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity, map })
  const location = useLocation()
  const dispatch = useDispatch()
  const galleryName = 'vision'

  const [sessionModalDefault, setSessionModalDefault] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] = React.useState(false)

  const visionPayload = useGetVisionQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  const toggleOptions = (field: string, isActive: any) =>
    firebaseControlQuery.updateCollection(
      `vision.${field}`,
      isActive === 'true'
    )

  const setSessionList = (
    sessionList: Array<{ sessionId: string; sessionName: string }>,
    sessionId: string
  ) => {
    const newSessionList = sessionList.filter(
      (res) => res.sessionId !== sessionId
    )
    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        sessionList: newSessionList,
        sessionId: newSessionList.length ? newSessionList[0].sessionId : '',
      })
    )
  }

  const disconnectSession = async () => {
    if (!session) {
      return
    }
    if (session.sessionOwners.length <= 1) {
      await firebaseControlQuery.refreshSession()
      await firebaseControlQuery.updateConnection(false)
      return
    }
    if (user.name) {
      await firebaseControlQuery.updateSessionOwner([
        ...(session?.sessionOwners.filter((res) => user.name !== res.name) ||
          []),
      ])
    }
  }

  const resetSession = async () => {
    const { sessionId, sessionList } = projectIdentity

    setIsLoading(true)
    dispatch(removeShortlist())
    await disconnectSession()
    setSessionList(sessionList, sessionId)
    setIsLoading(false)
  }

  const handleOnChangeSlideShowStatus = (
    newStatus: boolean,
    data?: SlideshowTransitionProps
  ) => {
    firebaseControlQuery.update({
      [`${galleryName}.galleryControl.isPlaying`]: newStatus,
      [`${galleryName}.galleryControl.transitionTime`]:
        data?.newTransitionTime ?? 3000,
      [`${galleryName}.galleryControl.transitionType`]:
        data?.newTransitionType ?? 'slide',
    })
  }

  React.useEffect(() => {
    const { sessionId, sessionList } = projectIdentity
    if (session) {
      const { connected } = session
      if (!connected) {
        dispatch(removeShortlist())
        setSessionList(sessionList, sessionId)
      }
    }
  }, [session])

  React.useEffect(() => {
    if (session) {
      const {
        vision: {
          sessionModal,
          galleryControl: { isPlaying: isPlayingFirebase },
        },
      } = session
      setSessionModalDefault(sessionModal)
      setSlideShowPlayingStatus(isPlayingFirebase)
    }
  }, [session])

  React.useEffect(() => {
    const searchQuery = getQueryStringParams(location.search)
    if (searchQuery.disconnect) {
      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          disconnect: searchQuery?.disconnect
            ? searchQuery?.disconnect === 'true'
            : true,
        })
      )
    }
  }, [])

  return (
    <Container className="overflow-hidden px-10">
      <div className="mx-4 lg:mx-10">
        <Navigation />
        <DataHandler
          payload={{
            ...visionPayload,
            data: visionPayload.gallery,
          }}
        >
          <div className="no-scrollbar my-6 h-80vh overflow-x-auto border-t-2 border-white bg-opacity-0">
            <div className="flex w-full">
              <div className="1/2 mr-auto flex items-center justify-start lg:w-2/5 xl:w-2/5">
                <BadgeGroup
                  getSelectedValue={(arg) => toggleOptions('sessionModal', arg)}
                  label="Session Modal"
                  defaultChecked={sessionModalDefault ? 'true' : 'false'}
                  name="sessionModal"
                  options={[
                    {
                      label: 'Open',
                      value: 'true',
                    },
                    {
                      label: 'Close',
                      value: 'false',
                    },
                  ]}
                />
                {visionPayload.gallery.length > 1 && (
                  <GalleryControl
                    galleryName={galleryName}
                    isSlideShowPlaying={isSlideShowPlaying}
                    onChangeSlideShowStatus={(newStatus, data) =>
                      handleOnChangeSlideShowStatus(newStatus, data)
                    }
                  />
                )}
              </div>
              <RefreshOption />
              {projectIdentity.disconnect && (
                <button
                  type="button"
                  className="flex items-center text-white"
                  onClick={resetSession}
                >
                  {isLoading ? (
                    <div
                      style={{ borderTopColor: 'transparent' }}
                      className="border-white-400 mr-2 h-5 w-5 animate-spin rounded-full border-2 border-dotted"
                    ></div>
                  ) : (
                    <OfflineSvg size="lg" className="mr-3 h-5 w-5" />
                  )}
                  {isLoading ? 'Disconnecting' : 'Disconnect'}
                </button>
              )}
            </div>
            <GalleryHandler
              galleryName={galleryName}
              galleries={[
                {
                  label: '',
                  items: visionPayload.gallery,
                },
              ]}
              gallerySession={session?.vision}
            />
            <PlayerControl
              galleryName={galleryName}
              playerSession={session?.vision?.playerControl}
            />
          </div>
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore, shortlist, user }: RootStateFirebase) => ({
    shortlist,
    projectIdentity,
    session: getSession(firestore),
    user,
    map: firestore.data.map,
  })
)(Vision)
