import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ReleaseInfoInterface, RootStateTypeExtra } from '@store/types'

const defaultReleaseInfo = {
  showcase: { version: '' },
  remote: { version: '' },
}

let initialState = defaultReleaseInfo as ReleaseInfoInterface

const releaseInfo = localStorage.getItem('releaseInfo')

if (releaseInfo) {
  initialState = JSON.parse(releaseInfo)
}

const releaseInfoSlice = createSlice({
  name: 'releaseInfo',
  initialState,
  reducers: {
    setShowcaseReleaseVersion: (
      state: ReleaseInfoInterface,
      action: PayloadAction<string>
    ) => {
      const newState = {
        ...state,
        ...{ showcase: { version: action.payload } },
      }
      localStorage.setItem('releaseInfo', JSON.stringify(newState))
      return newState
    },
    setRemoteReleaseVersion: (
      state: ReleaseInfoInterface,
      action: PayloadAction<string>
    ) => {
      const newState = {
        ...state,
        ...{ remote: { version: action.payload } },
      }
      localStorage.setItem('releaseInfo', JSON.stringify(newState))
      return newState
    },
    removeReleaseInfo: () => {
      localStorage.removeItem('releaseInfo')
      return defaultReleaseInfo
    },
  },
})

export const {
  setShowcaseReleaseVersion,
  setRemoteReleaseVersion,
  removeReleaseInfo,
} = releaseInfoSlice.actions

export const selectBuilding = (state: RootStateTypeExtra) => state.releaseInfo

export default releaseInfoSlice.reducer
