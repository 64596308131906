import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { setProjectIdentity } from '@store/actionSlices/project'
import {
  ProjectIdentity,
  RootStateFirebase,
  RouteInterface,
  SessionMap as SessionMapTypes,
} from '@store/types'

import { AddSessionModal, AlertModal } from '@components/modals'

import { Unit as Shortlist } from '@api/building'
import {
  selectFromResult as selectFromConfigResult,
  useGetConfigQuery,
} from '@api/config'
import {
  selectFromResult as selectFromExternalViewsResult,
  useGetExternalViewsQuery,
} from '@api/external-views'
import {
  selectFromResult as selectFromInterctiveMapResult,
  useGetInteractiveMapQuery,
} from '@api/interactive-map'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'

import { ChevronDownSvg, PlusCircleSvg, ShortListSvg } from '../svg'
import MenuItem from './menu-item'

export interface ComponentProps {
  routes?: Array<RouteInterface>
  projectIdentity: ProjectIdentity
  map: SessionMapTypes | undefined
  shortlist: Array<Shortlist>
  hideNavMenu?: boolean
}

const Navigation = ({
  routes,
  projectIdentity,
  map,
  shortlist,
  hideNavMenu = false,
}: ComponentProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { sessionId, sessionList, projectId } = projectIdentity
  const [hasExternalViewsData, setHasExternalViewsData] = React.useState(false)
  const [hasInteractiveMapData, setHasInteractiveMapData] =
    React.useState(false)
  const [showAlertModal, toggleAlertModal] = React.useState(false)
  const [alertModalMessage, setAlertModalMessage] = React.useState('')
  const [showAddSessionModal, toggleAddSessionModal] = React.useState(false)

  const defaultRoutes = [
    { label: 'Home', route: 'vision' },
    { label: 'Interactive Building', route: 'area-view' },
    { label: 'Videos', route: 'video-gallery' },
    { label: 'Gallery', route: 'gallery' },
    { label: 'Team', route: 'teams' },
    { label: 'Brochure', route: 'brochure' },
    { label: 'Location', route: 'location' },
  ]

  const [navRoutes, setNavRoutes] =
    React.useState<RouteInterface[]>(defaultRoutes)

  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromExternalViewsResult }
  )

  const interactiveMapPayload = useGetInteractiveMapQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromInterctiveMapResult }
  )

  const { prices, configInfo, envisionVRConfig, isLoaded } = useGetConfigQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromConfigResult }
  )

  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
    map,
  })

  const [toggleDrowdown, setToggleDrowdown] = React.useState(false)

  const handleClick = (route: string) => firebaseControlQuery.updateRoute(route)

  const getSessionName = () => {
    const sessionName = sessionList.find((res) => res.sessionId === sessionId)
    return sessionName?.sessionName || sessionName?.sessionId
  }

  const switchSession = (sessionToSwitch: string) => {
    dispatch(
      setProjectIdentity({
        ...projectIdentity,
        sessionId: sessionToSwitch,
      })
    )
  }

  const getBuildingRoute = (): string => {
    const { isSnaploaderActive, isEnvisionVRActive } = projectIdentity
    if (isSnaploaderActive) return 'snaploader-view'

    if (isEnvisionVRActive) return 'envision-vr'

    return 'area-view'
  }

  React.useEffect(() => {
    if (isLoaded) {
      const envisionVRActiveState = envisionVRConfig?.active || false
      const envisionVRURL = envisionVRConfig?.url || ''

      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          prices: {
            min: prices.minPrice,
            max: prices.maxPrice,
          },
          showPrice: configInfo?.showPrice,
          hideSoldPrice: configInfo?.hide_sold_price ?? false,
          longhand: configInfo?.longhand ?? false,
          onlyShowUnitGalleries: configInfo?.onlyShowUnitGalleries ?? false,
          isSnaploaderActive: configInfo?.snaploader?.active ?? false,
          isEnvisionVRActive: envisionVRActiveState && envisionVRURL !== '',
        })
      )
    }
  }, [isLoaded])

  React.useEffect(() => {
    if (
      externalViewsPayload &&
      externalViewsPayload.isLoaded &&
      !externalViewsPayload.isError &&
      externalViewsPayload.externalViewsData &&
      externalViewsPayload.externalViewsData.length > 0
    ) {
      setHasExternalViewsData(true)
    }
  }, [externalViewsPayload])

  React.useEffect(() => {
    if (
      interactiveMapPayload &&
      interactiveMapPayload.isLoaded &&
      !interactiveMapPayload.isError &&
      interactiveMapPayload.interactiveMapData &&
      interactiveMapPayload.interactiveMapData.length > 0
    ) {
      setHasInteractiveMapData(true)
    }
  }, [interactiveMapPayload])

  React.useEffect(() => {
    let newRoutes = [...defaultRoutes]
    if (hasExternalViewsData) {
      const externalNav = { label: 'External Views', route: 'external-views' }
      if (!newRoutes.some((droute) => droute.route === externalNav.route)) {
        newRoutes = [
          ...newRoutes.slice(0, 3),
          externalNav,
          ...newRoutes.slice(3),
        ]
      }
    }

    if (hasInteractiveMapData) {
      const interaciveMap = {
        label: 'Interactive Map',
        route: 'interactive-map',
      }
      if (!newRoutes.some((droute) => droute.route === interaciveMap.route)) {
        newRoutes = [...newRoutes, interaciveMap]
        setNavRoutes(newRoutes)
      }
    }
    setNavRoutes(newRoutes)
  }, [hasExternalViewsData, hasInteractiveMapData])

  const checkRoute = (route: string) =>
    (['/level-view', '/building'].includes(location.pathname)
      ? '/area-view'
      : location.pathname) === `/${route}`

  const handleShortlistMenuClick = () => {
    if (shortlist.length > 0) {
      history.push('/shortlist')
      return
    }
    setAlertModalMessage('You dont have any shortlisted units.')
    toggleAlertModal(true)
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="my-4 flex items-center overflow-x-auto whitespace-nowrap">
          {!hideNavMenu &&
            (routes ?? navRoutes).map((route: RouteInterface) => (
              <MenuItem
                key={route.route}
                menu={
                  route.route === 'area-view'
                    ? { ...route, route: getBuildingRoute() }
                    : route
                }
                handleClick={handleClick}
                checkRoute={checkRoute}
              />
            ))}
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div>
          <button
            disabled={sessionList.length <= 1}
            onClick={() => setToggleDrowdown(!toggleDrowdown)}
            onBlur={() => setTimeout(() => setToggleDrowdown(false), 500)}
            type="button"
            className="relative flex items-center justify-center font-normal uppercase text-white"
          >
            Session: {getSessionName()}{' '}
            {sessionList.length > 1 && <ChevronDownSvg className="ml-2" />}
            {toggleDrowdown && (
              <div className="absolute right-0 top-5 z-20 w-36 rounded bg-white py-2 text-black">
                <ol>
                  {sessionList
                    .filter((res) => res.sessionId !== sessionId)
                    .map((res, key) => (
                      <li
                        key={res.sessionId}
                        className="py-2 text-sm hover:bg-gray-300"
                      >
                        <div
                          tabIndex={key}
                          role="button"
                          onKeyDown={() => switchSession(res.sessionId)}
                          onClick={() => switchSession(res.sessionId)}
                        >
                          {res?.sessionName || res?.sessionId}
                        </div>
                      </li>
                    ))}
                </ol>
              </div>
            )}
          </button>
          <div className="flex items-center">
            <div className="mr-4 font-medium uppercase text-white">
              {projectId}
            </div>
            {location.pathname === '/vision' && (
              <button
                onClick={() => toggleAddSessionModal(true)}
                type="button"
                className="flex items-center text-white"
              >
                | <span className="ml-4"> Add Session</span> <PlusCircleSvg />
              </button>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-1 rounded-full bg-white p-2 text-black">
            <ShortListSvg className="h-5 w-5" />
          </div>
          <button
            type="button"
            onClick={handleShortlistMenuClick}
            className="text-white"
          >
            Shortlisted ({shortlist.length})
          </button>
        </div>
      </div>

      <AlertModal
        isVisible={showAlertModal}
        toggleModal={toggleAlertModal}
        message={alertModalMessage}
        title="Message"
      />
      <AddSessionModal
        isVisible={showAddSessionModal}
        toggleModal={toggleAddSessionModal}
      />
    </>
  )
}

export default connect(
  ({ projectIdentity, firestore, shortlist }: RootStateFirebase) => ({
    projectIdentity,
    map: firestore.data.map,
    session: getSession(firestore),
    shortlist,
  })
)(Navigation)
