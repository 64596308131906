import type { Configurations, UnitFilterInterface } from '@store/types'

import { Configuration as ConfigurationInterface, Unit } from '@api/building'

const STATUS_AVAILABLE = 'available'

const compareConfig = (filterConf: number, unitMetaConf: number): boolean => {
  if (filterConf === 0) {
    return true
  }
  return filterConf === unitMetaConf
}

const checkPrice = (
  unit: Unit,
  range: { min: number; max: number },
  showPrice: boolean
) => {
  if (!showPrice) {
    return true
  }
  if (!unit.metas.price) {
    return false
  }
  return (
    Number(unit.metas.price) >= range.min &&
    Number(unit.metas.price) <= range.max
  )
}

const compareConfigurations = (
  filterConf: Configurations,
  unitConf: ConfigurationInterface
): boolean =>
  compareConfig(Number(filterConf.bedroom), Number(unitConf.bed)) &&
  compareConfig(Number(filterConf.study), Number(unitConf.study)) &&
  compareConfig(Number(filterConf.bathroom), Number(unitConf.bath)) &&
  compareConfig(Number(filterConf.powderRoom), Number(unitConf.powderRoom)) &&
  compareConfig(Number(filterConf.carpark), Number(unitConf.car))

const filterUnit = (
  unit: Unit,
  filter: UnitFilterInterface,
  showPrice: boolean
): boolean => {
  const { apply, configurations, range, showAvailable, aspect } = filter

  if (!apply) {
    return true
  }

  const isInPriceRange = checkPrice(unit, range, showPrice)
  const isInConfig = compareConfigurations(
    configurations,
    unit.metas.configurations
  )
  const isAvailable = unit.metas.status === STATUS_AVAILABLE
  const isInAspect = aspect === 'All' || unit.metas.aspect === aspect
  if (showAvailable) {
    return isInAspect && isAvailable && isInPriceRange && isInConfig
  }
  return isInAspect && isInPriceRange && isInConfig
}

export { filterUnit, checkPrice, compareConfigurations }
