interface BackgroundImageInterface {
  backgroundImage: string
}

function setBackground(
  assetRoute: string,
  bgAttributes: {
    gradiant?: number
    linearGradiant?: number
    backgroundGradiant?: number
    type?: string
  } = {}
): BackgroundImageInterface {
  const { gradiant, linearGradiant, backgroundGradiant } = {
    gradiant: 0.5,
    linearGradiant: 0,
    backgroundGradiant: 0,
    ...bgAttributes,
  }

  return {
    backgroundImage: assetRoute
      ? `linear-gradient(rgba(0, 0, 0, ${
          linearGradiant || gradiant
        }),rgba(0, 0, 0, ${backgroundGradiant || gradiant})),url(${assetRoute})`
      : 'none',
  }
}

function setBackgroundGradient(
  gradiant: number | undefined = 0.5,
  linearGradiant = 0,
  backgroundGradiant = 0
): BackgroundImageInterface {
  return {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, ${
      linearGradiant || gradiant
    }),rgba(0, 0, 0, ${backgroundGradiant || gradiant}))`,
  }
}

export { setBackground, setBackgroundGradient }
