import React from 'react'
import { connect } from 'react-redux'

import {
  GalleryControlInterface,
  GalleryInterface,
  GalleryItemInterface,
  PlayerControlInterface,
  ProjectIdentity,
  RootStateFirebase,
} from '@store/types'

import GalleryHandler from '@components/gallery-handler'
import PlayerControl from '@components/player-control'

import {
  FloorPlateGalleryInterface,
  FloorPlateGalleryItemInterface,
  selectFromResult as selectFromFloorGalleryResult,
  useGetFloorPlateGalleryQuery,
} from '@api/floor-plate-gallery'
import {
  selectFromResult as selectFromInteractivePlanResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

export interface FloorGalleryControlSessionInterface {
  galleryControl: GalleryControlInterface
  playerControl: PlayerControlInterface
}

export interface ComponentPropsInterface {
  projectIdentity: ProjectIdentity
  floorGalleryControlSession: FloorGalleryControlSessionInterface | undefined
  activeLevel: string
  activeBlock: string
  floorGalleryItemsCount: (arg: number) => void
}

const FloorPlateGallery = ({
  projectIdentity,
  floorGalleryControlSession,
  activeLevel,
  activeBlock,
  floorGalleryItemsCount,
}: ComponentPropsInterface) => {
  const bucketUrl = process.env.REACT_APP_BUCKET

  const [floorGalleries, setFloorGalleries] = React.useState<
    Array<GalleryInterface>
  >([])
  const [showGalleryControl, setShowGalleryControl] = React.useState(false)

  const interactivePlanPayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromInteractivePlanResult }
  )

  const {
    maps: { floorplan },
  } = interactivePlanPayload

  const floorGalleriePayload = useGetFloorPlateGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromFloorGalleryResult }
  )

  const { floorPlateGalleryData } = floorGalleriePayload

  const buildGallery = () => {
    const galleryItems: Array<GalleryItemInterface> = []

    const mapName = activeBlock ? `${activeBlock}-${activeLevel}` : activeLevel

    if (Object.keys(floorplan ?? {}).length > 0 && floorplan[mapName]) {
      const path = floorplan[mapName].image
      galleryItems.push({
        title: '',
        imageSource: `${bucketUrl}${path}`,
        assetType: 'new',
      })
    }

    if (floorPlateGalleryData.length > 0) {
      let foundData = null

      if (activeBlock && activeLevel) {
        foundData = floorPlateGalleryData.find(
          (value: FloorPlateGalleryInterface) =>
            value.block.toString() === activeBlock.toString() &&
            value.level.toString() === activeLevel.toString()
        )
      } else if (activeLevel) {
        foundData = floorPlateGalleryData.find(
          (value: FloorPlateGalleryInterface) =>
            value.level.toString() === activeLevel.toString()
        )
      }

      const floorAssets = foundData?.assets ?? []

      if (floorAssets.length > 0) {
        floorAssets.forEach((asset: FloorPlateGalleryItemInterface) => {
          galleryItems.push({
            imageSource: asset.imageSrc ?? '',
            videoSource: asset.videoSrc ?? '',
          })
        })
        setShowGalleryControl(true)
      }
    }

    if (galleryItems.length > 0) {
      setFloorGalleries([{ items: galleryItems }])
      floorGalleryItemsCount(galleryItems.length)
    }
  }

  React.useEffect(() => {
    if (
      Object.keys(floorplan ?? {}).length > 0 &&
      (activeLevel !== '' || activeBlock !== '')
    ) {
      buildGallery()
    }
  }, [floorplan, activeLevel, activeBlock])

  React.useEffect(() => {
    if (
      floorPlateGalleryData.length > 0 &&
      (activeLevel !== '' || activeBlock !== '')
    ) {
      buildGallery()
    }
  }, [floorPlateGalleryData, activeLevel, activeBlock])

  return (
    <>
      {showGalleryControl && floorGalleries.length > 0 ? (
        <>
          <GalleryHandler
            columnGridClassName="grid-cols-1 gap-6"
            galleryName="floorGallery"
            galleries={floorGalleries}
            gallerySession={floorGalleryControlSession}
            showBorderOnActiveItem
          />
          <PlayerControl
            galleryName="floorGallery"
            playerSession={floorGalleryControlSession?.playerControl}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(FloorPlateGallery)
