import React from 'react'
import { connect } from 'react-redux'

import {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
  UnitFilterInterface,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Filter from '@components/filter'
import Navigation from '@components/navigation/navigation'
import {
  ArrowTurnSvg,
  CameraOffSvg,
  CameraOnSvg,
  ChevronSvg,
  ClockWiseSvg,
  FilterSvg,
  MagnifyingGlassMinusSVG,
  MagnifyingGlassPlusSVG,
} from '@components/svg'
import ArrowPathSvg from '@components/svg/arrow-path-svg'

import {
  Level,
  Unit,
  selectFromResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  selectFromResult as selectFromInteractivePlanResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { generateId, getSession } from '@utilities/firebase-util'
import { notifyError } from '@utilities/notifier'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

import BuildingList from './building-list'
import LevelList from './level-list'

interface PageProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const EnvisionVR = ({ projectIdentity, session }: PageProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [toggleFilter, setFilterToggle] = React.useState(false)
  const [unitFilter, setUnitFilter] = React.useState<UnitFilterInterface>()

  const [isBuildingDataLoaded, setBuildingDataLoadingState] =
    React.useState(false)
  const [buildingData, setBuildingData] = React.useState<Array<string>>([])
  const [activeBuilding, setActiveBuilding] = React.useState('')

  const [isLevelDataLoaded, setLevelDataLoadingState] = React.useState(false)
  const [levelData, setLevelData] = React.useState<Array<string>>([])
  const [rotateCamera, setRotateCamera] = React.useState(false)

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  const interactivePlanPayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromInteractivePlanResult }
  )

  const handleBackButtonPress = async () => {
    setFilterToggle(false)
    if (activeBuilding !== '') {
      await firebaseControlQuery.update({
        'envisionVR.activeBuilding': '',
      })
      return
    }
    await firebaseControlQuery.updateRoute('vision')
  }

  const handleToggleRotateCamera = async () => {
    await firebaseControlQuery.update({
      'envisionVR.rotateCamera': !rotateCamera,
    })
  }

  const handleRotateCameraBy = async (direction: 'left' | 'right') => {
    await firebaseControlQuery.update({
      'envisionVR.rotateCameraBy.direction': direction,
      'envisionVR.rotateCameraBy.triggerKey': generateId(10),
    })
  }

  const handleZoomCamera = async (action: 'in' | 'out') => {
    await firebaseControlQuery.update({
      'envisionVR.zoomCamera.action': action,
      'envisionVR.zoomCamera.triggerKey': generateId(10),
    })
  }

  const handleBuildingClick = async (arg: string) => {
    await firebaseControlQuery.update({
      'envisionVR.activeBuilding': arg,
      'envisionVR.levels.isLoaded': false,
      'envisionVR.levels.data': [],
    })
  }

  const handleLevelClick = async (levelArg: string) => {
    const selectedLevel = buildingPayload.levels.find(
      (lvl: Level) => levelArg === lvl.level
    )

    if (!selectedLevel) {
      notifyError('Invalid level')
      return
    }

    const blockList = Object.keys(interactivePlanPayload.maps.blocks || {})

    let myBlockId = ''

    if (blockList.length > 1 && activeBuilding !== '') {
      const unit = selectedLevel.data.find(
        (unt: Unit) => unt.blockId === activeBuilding
      )

      if (!unit) {
        notifyError('Invalid block')
        return
      }

      myBlockId = unit.blockId
    }

    if (myBlockId !== '') {
      await firebaseControlQuery.update({
        [`building.activeBlock`]: myBlockId,
        [`building.activeLevel`]: levelArg,
      })
    } else {
      await firebaseControlQuery.updateCollection(
        'building.activeLevel',
        levelArg
      )
    }

    setTimeout(async () => {
      await firebaseControlQuery.updateCollection(
        'building.sidePanelFolded',
        true
      )
      await firebaseControlQuery.updateRoute('building')
    }, 1000)
  }

  const getFilteredData = (levels: Level[]): Array<string> => {
    const filteredLevelList: Array<string> = []

    levels.forEach((level) => {
      level.data.forEach((unit: Unit) => {
        if (!filteredLevelList.includes(level.level) && unitFilter) {
          const response = filterUnitUtil(
            unit,
            unitFilter,
            projectIdentity.showPrice
          )
          if (response) {
            filteredLevelList.push(level.level)
          }
        }
      })
    })

    return filteredLevelList
  }

  const levelsOptions = React.useMemo(
    () =>
      unitFilter?.apply
        ? getFilteredData(buildingPayload.levels).map((item) => item)
        : levelData,
    [levelData, unitFilter?.apply, buildingPayload]
  )

  React.useEffect(() => {
    if (session) {
      const {
        building: { unitFilter: unitFilterFirebase },
        envisionVR: {
          rotateCamera: rotateCameraFirebase,
          buildings: {
            isLoaded: buildingDataLoadingStateFirebase,
            data: buildingDataFirebase,
          },
          activeBuilding: activeBuildingFirebase,
          levels: {
            isLoaded: levelDataLoadingStateFirebase,
            data: levelDataFirebase,
          },
        },
      } = session

      setUnitFilter(unitFilterFirebase)
      setRotateCamera(rotateCameraFirebase)
      setBuildingDataLoadingState(buildingDataLoadingStateFirebase)
      setBuildingData(buildingDataFirebase)
      setActiveBuilding(activeBuildingFirebase)
      setLevelDataLoadingState(levelDataLoadingStateFirebase)
      setLevelData(levelDataFirebase)
    }
  }, [session])

  React.useEffect(
    () => () => {
      firebaseControlQuery.update({
        'envisionVR.isLoaded': false,
        'envisionVR.rotateCamera': false,
        'envisionVR.rotateCameraBy.direction': '',
        'envisionVR.rotateCameraBy.triggerKey': '',
        'envisionVR.zoomCamera.action': '',
        'envisionVR.zoomCamera.triggerKey': '',
        'envisionVR.activeBuilding': '',
        'envisionVR.buildings.isLoaded': false,
        'envisionVR.buildings.data': [],
        'envisionVR.levels.isLoaded': false,
        'envisionVR.levels.data': [],
      })
    },
    []
  )

  return (
    <Container className="overflow-hidden px-10">
      <div className="mx-4 h-full lg:mx-10">
        <Navigation />
        <DataHandler
          payload={{
            ...buildingPayload,
            data: buildingPayload.levels,
          }}
        >
          <div className="no-scrollbar my-6 grid h-4/5 grid-cols-2 gap-4 overflow-x-auto border-t-2 border-white bg-opacity-0">
            <div className="flex h-full w-full">
              <div className="my-4 flex flex-col items-center">
                <button
                  onClick={handleBackButtonPress}
                  title="Back"
                  type="button"
                  className="mb-2 flex items-center rounded-full bg-white p-2 font-GTWalsheimPro"
                >
                  <ArrowTurnSvg className="h-10 w-10 text-black" />
                </button>
                {!projectIdentity.hideFilter && activeBuilding !== '' && (
                  <button
                    onClick={() => setFilterToggle(true)}
                    title="Filter"
                    type="button"
                    className="mb-2 flex items-center rounded-full bg-white font-GTWalsheimPro"
                  >
                    <FilterSvg className="h-14 w-14 text-black" />
                  </button>
                )}
                <button
                  onClick={handleToggleRotateCamera}
                  title="Auto Rotate Camera"
                  type="button"
                  className={`mb-2 flex cursor-pointer items-center rounded-full p-2 font-GTWalsheimPro ${
                    rotateCamera ? 'bg-black' : 'bg-white'
                  }`}
                  disabled={!isBuildingDataLoaded}
                >
                  {rotateCamera ? (
                    <CameraOnSvg className="text-white" />
                  ) : (
                    <CameraOffSvg className="text-black" />
                  )}
                </button>
                <button
                  onClick={() => handleRotateCameraBy('left')}
                  title="Rotate Camera Left"
                  type="button"
                  className="mb-2 cursor-pointer rounded-full bg-white p-2 font-GTWalsheimPro hover:bg-zinc-200"
                  disabled={!isBuildingDataLoaded}
                >
                  <ChevronSvg className="h-10 w-10" stroke="0.5" />
                </button>
                <button
                  onClick={() => handleRotateCameraBy('right')}
                  title="Rotate Camera Right"
                  type="button"
                  className="mb-2 cursor-pointer rounded-full bg-white p-2 font-GTWalsheimPro hover:bg-zinc-200"
                  disabled={!isBuildingDataLoaded}
                >
                  <ChevronSvg
                    className="h-10 w-10"
                    rotate="right"
                    stroke="0.5"
                  />
                </button>
                <button
                  onClick={() => handleZoomCamera('in')}
                  title="Zoom Camera In"
                  type="button"
                  className="mb-2 cursor-pointer rounded-full bg-white p-2 font-GTWalsheimPro hover:bg-zinc-200"
                  disabled={!isBuildingDataLoaded}
                >
                  <MagnifyingGlassPlusSVG className="h-10 w-10" />
                </button>
                <button
                  onClick={() => handleZoomCamera('out')}
                  title="Zoom Camera Out"
                  type="button"
                  className="mb-2 cursor-pointer rounded-full bg-white p-2 font-GTWalsheimPro hover:bg-zinc-200"
                  disabled={!isBuildingDataLoaded}
                >
                  <MagnifyingGlassMinusSVG className="h-10 w-10" />
                </button>
              </div>
              <div className="relative m-10 w-full xl:w-3/6">
                {session && (
                  <Filter
                    projectIdentity={projectIdentity}
                    toggle={setFilterToggle}
                    isOpen={toggleFilter}
                    unitFilter={session.building.unitFilter}
                    aspects={buildingPayload.aspects}
                  />
                )}
              </div>
            </div>
            <div className="no-scrollbar h-5/6 w-full overflow-x-auto lg:h-full">
              {activeBuilding !== '' && (
                <div className="avavenirLight mt-4 text-base font-bold text-white lg:text-xl">
                  VIEWING: Building {activeBuilding}
                </div>
              )}
              <div className="align-center mt-6 flex flex-wrap content-start bg-opacity-0">
                {activeBuilding !== '' ? (
                  <LevelList
                    isLoaded={isLevelDataLoaded}
                    levels={levelsOptions}
                    handleClick={handleLevelClick}
                  />
                ) : (
                  <BuildingList
                    isLoaded={isBuildingDataLoaded}
                    buildings={buildingData}
                    handleClick={handleBuildingClick}
                  />
                )}
              </div>
            </div>
          </div>
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(EnvisionVR)
