import {
  PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'

import appConfig from '@store/actionSlices/appConfig'
import bedConfig from '@store/actionSlices/bedConfig'
import projectIdentity from '@store/actionSlices/project'
import releaseInfo from '@store/actionSlices/releaseInfo'
import shortlist from '@store/actionSlices/shortlist'
import statusIndicator from '@store/actionSlices/statusIndicator'
import token from '@store/actionSlices/token'
import user from '@store/actionSlices/user'

import { userApi } from '@api/authentication'
import { bedConfigApi } from '@api/bed-config'
import { brochureApi } from '@api/brochure'
import { buildingApi } from '@api/building'
import { configApi } from '@api/config'
import { externalViewsApi } from '@api/external-views'
import { floorPlateGalleryApi } from '@api/floor-plate-gallery'
import { galleryApi } from '@api/gallery'
import { interactiveMapApi } from '@api/interactive-map'
import { interactivePlanApi } from '@api/interactive-plan'
import { locationApi } from '@api/location'
import { panoramicApi } from '@api/panoramic'
import { shortlistedApi } from '@api/shortlisted'
import { teamApi } from '@api/team'
import { unitGalleryApi } from '@api/unit-gallery'
import { videoGalleryApi } from '@api/video-gallery'
import { visionApi } from '@api/vision'

import { getFirebaseHost, getFirebasePort } from '@utilities/helper'

import firebaseConfig from '../firebaseConfig'

firebase.initializeApp(firebaseConfig)

firebase.firestore()

const firebaseHost = getFirebaseHost()
const firebasePort = getFirebasePort()

if (firebaseHost && firebasePort) {
  firebase.firestore().useEmulator(firebaseHost, Number(firebasePort))
}

const initialState = {}

const rootReducer = combineReducers({
  user,
  token,
  projectIdentity,
  shortlist,
  bedConfig,
  releaseInfo,
  statusIndicator,
  appConfig,
  [userApi.reducerPath]: userApi.reducer,
  [buildingApi.reducerPath]: buildingApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [shortlistedApi.reducerPath]: shortlistedApi.reducer,
  [bedConfigApi.reducerPath]: bedConfigApi.reducer,
  [panoramicApi.reducerPath]: panoramicApi.reducer,
  [interactivePlanApi.reducerPath]: interactivePlanApi.reducer,
  [videoGalleryApi.reducerPath]: videoGalleryApi.reducer,
  [externalViewsApi.reducerPath]: externalViewsApi.reducer,
  [galleryApi.reducerPath]: galleryApi.reducer,
  [brochureApi.reducerPath]: brochureApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [interactiveMapApi.reducerPath]: interactiveMapApi.reducer,
  [visionApi.reducerPath]: visionApi.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [floorPlateGalleryApi.reducerPath]: floorPlateGalleryApi.reducer,
  [unitGalleryApi.reducerPath]: unitGalleryApi.reducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  ...initialState,
})

export type RootStateType = ReturnType<typeof rootReducer>

export function setupStore(
  preloadedState?: PreloadedState<ReturnType<typeof rootReducer>>
) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        configApi.middleware,
        userApi.middleware,
        buildingApi.middleware,
        shortlistedApi.middleware,
        bedConfigApi.middleware,
        panoramicApi.middleware,
        interactivePlanApi.middleware,
        videoGalleryApi.middleware,
        externalViewsApi.middleware,
        galleryApi.middleware,
        brochureApi.middleware,
        locationApi.middleware,
        interactiveMapApi.middleware,
        teamApi.middleware,
        visionApi.middleware,
        floorPlateGalleryApi.middleware,
        unitGalleryApi.middleware
      ),
    preloadedState,
  })
}

const store = setupStore()

const FirebaseProps = {
  firebase,
  dispatch: store.dispatch,
  config: { useFirestoreForProfile: false },
  createFirestoreInstance,
}

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatchType = typeof store.dispatch

export type AppStore = ReturnType<typeof setupStore>

export default { FirebaseProps, store }
