import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'
import Navigation from '@components/navigation'
import PlayerControl from '@components/player-control'

import {
  selectFromResult as selectFromVideoGalleryResult,
  useGetVideoGalleryQuery,
} from '@api/video-gallery'

import { getSession } from '@utilities/firebase-util'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

const VideoGallery = ({ session, projectIdentity }: PagePropsInterface) => {
  const videoGalleryPayload = useGetVideoGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromVideoGalleryResult }
  )

  return (
    <Container>
      <div className="mx-4 lg:mx-10">
        <Navigation />
        <div className="my-6 border-b-2"></div>
        <DataHandler
          payload={{
            ...videoGalleryPayload,
            data: videoGalleryPayload.videoGalleryData,
          }}
        >
          <GalleryHandler
            galleryName="videoGallery"
            galleries={videoGalleryPayload.videoGalleryData}
            gallerySession={session?.videoGallery}
          />
          <PlayerControl
            galleryName="videoGallery"
            playerSession={session?.videoGallery.playerControl}
          />
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(VideoGallery)
