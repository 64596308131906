import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

export interface ConfigData {
  longhand: boolean
  showPrice: boolean
  hide_sold_price: boolean
  show_carousel_indicator: boolean
  carousel_indicator_location: string
  hideFilter: boolean
  onlyShowUnitGalleries: boolean
}

export interface PriceRange {
  maxPrice: number
  minPrice: number
}

export interface EnvisionVRConfigurationInterface {
  active: boolean
  url: string
}

export interface ConfigPayload {
  data: {
    config: {
      project: ConfigData
    }
    prices: PriceRange
    envisionVr: EnvisionVRConfigurationInterface
  }
}

type ConfigType = {
  projectName: string
}

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getConfig: builder.query<ConfigPayload, ConfigType>({
      query: (params: ConfigType) => ({
        url: `/v1/${params.projectName}/config`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
  status,
  isError,
}: UseQueryStateResult<any, any>) => ({
  configInfo: data?.data.config.project || {},
  envisionVRConfig:
    data?.data.envisionVr || ({} as EnvisionVRConfigurationInterface),
  prices: data?.data.prices || {},
  status,
  isError,
  isLoaded: !isLoading,
})

export const { useGetConfigQuery } = configApi
