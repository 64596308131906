import React from 'react'

import { CloseCircleSvg } from '@components/svg'

export interface ComponentInterface {
  isVisible: boolean
  toggleModal: (arg: boolean) => void
  message: string
  title?: string
  okButtonText?: string
}

const AlertModal = ({
  isVisible = false,
  toggleModal,
  message = '',
  title = 'Error',
  okButtonText = 'Ok',
}: ComponentInterface) => {
  const closeModal = () => {
    toggleModal(false)
  }

  return (
    <div
      id="alert-modal"
      aria-hidden="true"
      className={`h-modal fixed left-0 right-0 top-4 z-20 items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-400 bg-opacity-50 md:inset-0 md:h-full
     ${isVisible ? 'visible' : 'invisible'}
    `}
    >
      <div className="relative m-auto mt-32 h-full w-full max-w-2xl px-4 md:h-auto">
        <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
          <div className="flex items-center justify-between rounded-t border-b p-5 dark:border-gray-600">
            <h3 className="font-GTWalsheimPro text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
              {title}
            </h3>
            <button
              type="button"
              onClick={() => closeModal()}
              data-modal-toggle="default-modal"
            >
              <CloseCircleSvg
                size="s"
                className="cursor-pointer"
                fill="hsl(1, 0%, 0%)"
              />
            </button>
          </div>
          <div className="space-y-6 p-6">
            <p className="font-GTWalsheimPro leading-relaxed text-gray-500  dark:text-gray-400">
              {message}
            </p>
          </div>
          <div className="flex justify-end border-t border-gray-200 p-6">
            <button
              type="button"
              onClick={() => closeModal()}
              className="inline-flex items-center rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              {okButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertModal
