import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import ArrowPathSvg from '@components/svg/arrow-path-svg'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { generateId, getSession } from '@utilities/firebase-util'

export interface ComponentProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  map: SessionMap | undefined
}

const RefreshOption = ({ projectIdentity, session, map }: ComponentProps) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity, map })

  const [clearRefreshTriggerKeyRestTimerId, setRefreshTriggerKeyRestTimerId] =
    React.useState<ReturnType<typeof setTimeout>>()
  const [isRefreshing, setRefreshState] = React.useState(false)

  const setRefreshTriggerKeyOnFirebase = (state: string, key = ''): void => {
    firebaseControlQuery.update({
      [`clearCacheTriggerKey`]: key,
      [`clearCacheKeyState`]: state,
    })
  }

  const handleRefreshClick = () => {
    setRefreshState(true)
    setRefreshTriggerKeyOnFirebase('registered', generateId(10))
  }

  const resetRefreshTriggerKeyTimer = () => {
    setRefreshState(false)
    if (clearRefreshTriggerKeyRestTimerId) {
      clearTimeout(clearRefreshTriggerKeyRestTimerId)
      setRefreshTriggerKeyRestTimerId(undefined)
    }
  }

  const resetRefreshStateAndReload = async (state?: string) => {
    if (state !== 'cleared') return
    await firebaseControlQuery.update({
      [`clearCacheKeyState`]: '',
    })
    window.location.reload()
  }

  const handleRefreshTriggerKeyChange = async (
    refreshTriggerKey?: string,
    myRefreshKeyState?: string
  ) => {
    if (!refreshTriggerKey) {
      resetRefreshTriggerKeyTimer()
      resetRefreshStateAndReload(myRefreshKeyState)
      return
    }

    if (!clearRefreshTriggerKeyRestTimerId) {
      setRefreshTriggerKeyRestTimerId(
        setTimeout(() => {
          setRefreshTriggerKeyOnFirebase('cleared', '')
        }, 30 * 1000)
      )
    }
  }

  React.useEffect(() => {
    if (session) {
      const {
        clearCacheTriggerKey: firebaseRefreshTriggerKey,
        clearCacheKeyState: firebaseRefreshKeyState,
      } = session
      handleRefreshTriggerKeyChange(
        firebaseRefreshTriggerKey,
        firebaseRefreshKeyState
      )
    }
  }, [session])

  return (
    <button
      type="button"
      className="mr-4 flex items-center text-white"
      onClick={handleRefreshClick}
    >
      <ArrowPathSvg
        className={`mr-1 h-6 w-6 ${isRefreshing ? 'animate-spin' : ''}`}
      />
      {isRefreshing ? 'Refreshing' : 'Refresh'}
    </button>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  projectIdentity,
  session: getSession(firestore),
  map: firestore.data.map,
}))(RefreshOption)
