import React from 'react'

import { OfflineModeModal } from '@components/modals'
import { SignalSlashSvg } from '@components/svg'

const OfflineMode = () => {
  const [offlineModalVisibility, setOfflineModalVisibility] =
    React.useState(false)

  const handleOfflineMode = () => {
    setOfflineModalVisibility(true)
  }

  return (
    <>
      <button
        type="button"
        className="mr-4 flex items-center text-white"
        onClick={handleOfflineMode}
      >
        <SignalSlashSvg className="h-10 w-10 text-white" />
      </button>

      <OfflineModeModal
        visibility={offlineModalVisibility}
        toggleModal={() => {
          setOfflineModalVisibility(false)
        }}
      />
    </>
  )
}

export default OfflineMode
