import React from 'react'

import { TokenInterface } from '@store/actionSlices/token'

import {
  notifySuccess,
  notifyWarning,
  removeNotification,
} from '@utilities/notifier'
import { hasToken } from '@utilities/token-helper'

interface ComponentProps {
  token: TokenInterface
  isOnline: boolean
}

const useConnectionIndicator = ({
  token,
  isOnline,
}: ComponentProps): string => {
  const [notificationId, setNotificationId] = React.useState<string>('')

  React.useEffect(() => {
    if (hasToken(token)) {
      if (!isOnline) {
        setNotificationId(
          notifyWarning(
            'Showcase is currently offline. No internet connection.',
            0,
            true
          )
        )
      }

      if (isOnline && notificationId) {
        removeNotification(notificationId)
        notifySuccess('Showcase is back online.', 3000)
        setNotificationId('')
      }
    }
  }, [isOnline])

  return notificationId
}

export default useConnectionIndicator
