import React from 'react'

import { CaretSvg } from '../svg'

export interface PanoramicControlProps {
  autoRotate: boolean
  handleClick: (arg: string) => void
}

const PanoramicControl = ({
  autoRotate,
  handleClick,
}: PanoramicControlProps) => (
  <div className="relative m-auto mt-20 h-64 w-64 overflow-hidden rounded-full ipad-p:h-48 ipad-p:w-48">
    <button
      type="button"
      onClick={(e) => (autoRotate ? e.preventDefault() : handleClick('up'))}
      className={`h-32 w-32 transform bg-gray-500 ipad-p:h-24 ipad-p:w-24 ${
        autoRotate ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-600'
      } absolute -top-8 left-1/2 -translate-x-1/2 rotate-45 ipad-p:-top-6`}
    >
      <span className="flex -rotate-45 transform justify-center">
        <CaretSvg
          className="h-16 w-16 text-white ipad-p:h-12 ipad-p:w-12"
          rotate="top"
        />
      </span>
    </button>
    <button
      type="button"
      onClick={(e) => (autoRotate ? e.preventDefault() : handleClick('down'))}
      className={`h-32 w-32 transform bg-gray-500 ipad-p:h-24 ipad-p:w-24 ${
        autoRotate ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-600'
      } absolute -bottom-8 left-1/2 -translate-x-1/2 rotate-45 ipad-p:-bottom-6`}
    >
      <span className="flex -rotate-45 transform justify-center">
        <CaretSvg
          className="h-16 w-16 text-white ipad-p:h-12 ipad-p:w-12"
          rotate="down"
        />
      </span>
    </button>
    <button
      type="button"
      onClick={(e) => (autoRotate ? e.preventDefault() : handleClick('right'))}
      className={`h-32 w-32 transform bg-gray-500 ipad-p:h-24 ipad-p:w-24 ${
        autoRotate ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-600'
      } absolute -right-8 top-1/2 -translate-y-1/2 rotate-45 ipad-p:-right-6`}
    >
      <span className="flex -rotate-45 transform justify-center">
        <CaretSvg
          className="w-16 text-white ipad-p:h-12 ipad-p:w-12"
          rotate="right"
        />
      </span>
    </button>
    <button
      type="button"
      onClick={(e) => (autoRotate ? e.preventDefault() : handleClick('left'))}
      className={`h-32 w-32 transform bg-gray-500 ipad-p:h-24 ipad-p:w-24 ${
        autoRotate ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-600'
      } absolute -left-8 top-1/2 -translate-y-1/2 rotate-45 ipad-p:-left-6`}
    >
      <span className="flex -rotate-45 transform justify-center">
        <CaretSvg
          className="w-16 text-white ipad-p:h-12 ipad-p:w-12"
          rotate="left"
        />
      </span>
    </button>
    <div className="absolute left-1/2 top-1/2 h-32 w-32 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-white ipad-p:h-24 ipad-p:w-24"></div>
  </div>
)

export default PanoramicControl
