import React from 'react'

import { SvgProps } from '@components/svg/types'

const FilterSvg = ({
  width,
  height,
  className,
  size,
  styles,
  fill,
  stroke,
}: SvgProps) => {
  const sizeValue = size || 'm'

  const scale = {
    xl: 2.5,
    lg: 1.0,
    m: 0.5,
    s: 0.2,
    xs: 0.1,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ transform: `scale(${scale[sizeValue]})` },
      }}
      width={width || '48px'}
      height={height || '48px'}
      viewBox="0 0 24 24"
      strokeWidth={stroke || 1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
        fill={fill || 'hsl(0, 0%, 100%)'}
      ></path>
    </svg>
  )
}

export default FilterSvg
