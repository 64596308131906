import React from 'react'
import { connect } from 'react-redux'

import type { RootStateFirebase, UnitFilterInterface } from '@store/types'

import { AlertModal } from '@components/modals'

import { Unit } from '@api/building'

import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

import UnitCard from './unit-card'

export interface ListingProps {
  units: Array<Unit>
  shortlist: Array<Unit>
  showPrice: boolean
  hideSoldPrice: boolean
  trigger?: (unit: Unit) => void
  filter?: UnitFilterInterface
}

const UnitListing = ({
  units,
  shortlist,
  showPrice,
  hideSoldPrice,
  trigger,
  filter,
}: ListingProps): React.ReactElement => {
  const [filteredUnits, setFilteredUnits] = React.useState<Array<Unit>>([])
  const [showAlertModal, toggleAlertModal] = React.useState(false)
  const [alertModalMessage, setAlertModalMessage] = React.useState('')

  const handleFilter = (unit: Unit) => {
    if (filter) {
      return filterUnitUtil(unit, filter, showPrice)
    }
    return true
  }

  const setShortlistErrorMessage = (message: string) => {
    setAlertModalMessage(message)
    toggleAlertModal(true)
  }

  React.useEffect(
    () => setFilteredUnits(units.filter((unit) => handleFilter(unit))),
    [units, filter]
  )

  return (
    <>
      <div className="align-center flex h-4/6 flex-wrap content-start bg-opacity-0">
        {filteredUnits.length > 0 ? (
          filteredUnits.map((unit: Unit) => (
            <UnitCard
              key={unit.id}
              unit={unit}
              shortlist={shortlist}
              showPrice={showPrice}
              hideSoldPrice={hideSoldPrice}
              trigger={trigger}
              setShortlistErrorMessage={setShortlistErrorMessage}
            />
          ))
        ) : (
          <div className="avavenirLight w-full p-12 text-center text-white">
            No Units Found
          </div>
        )}
      </div>
      <AlertModal
        isVisible={showAlertModal}
        toggleModal={toggleAlertModal}
        message={alertModalMessage}
        title="Message"
      />
    </>
  )
}

export default connect(
  ({
    shortlist,
    projectIdentity: { showPrice, hideSoldPrice },
  }: RootStateFirebase) => ({
    shortlist,
    showPrice,
    hideSoldPrice,
  })
)(UnitListing)
