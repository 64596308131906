import React from 'react'

import { SvgProps } from '@components/svg/types'

const StopCircleSvg = ({
  width,
  height,
  className,
  size,
  styles,
  stroke,
  fill,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ height: 'auto', transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '24'}
      height={height || '24'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill || 'none'}
      viewBox="0 0 24 24"
      stroke={stroke || 'currentColor'}
      strokeWidth="1.5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"
      />
    </svg>
  )
}

export default StopCircleSvg
