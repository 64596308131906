import React from 'react'
import { useDispatch } from 'react-redux'

import { filterShortlist, setShortlist } from '@store/actionSlices/shortlist'

import { ShortListSvg } from '@components/svg'

import { Unit } from '@api/building'

import { numberWithCommas } from '@utilities/helper'

export interface ComponentProps {
  unit: Unit
  shortlist: Array<Unit>
  showPrice: boolean
  hideSoldPrice: boolean
  setShortlistErrorMessage: (arrg: string) => void
  trigger?: (unit: Unit) => void
}

const UnitCard = ({
  unit,
  shortlist,
  showPrice,
  hideSoldPrice,
  setShortlistErrorMessage,
  trigger,
}: ComponentProps): React.ReactElement => {
  const STATUS_AVAILABLE = 'available'
  const STATUS_SOLD = 'sold'
  const MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST =
    Number(process.env.REACT_APP_MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST) || 4

  const dispatch = useDispatch()

  const isShortlisted = (myUnit: Unit) => {
    const foundUnit = shortlist.find(
      (shortlistedUnit) => myUnit.name === shortlistedUnit.name
    )
    return foundUnit !== undefined
  }

  const handleShortlistClick = (myUnit: Unit) => {
    if (myUnit.metas.status !== STATUS_AVAILABLE) {
      setShortlistErrorMessage('This unit is not available.')
      return
    }
    if (isShortlisted(myUnit)) {
      dispatch(filterShortlist(myUnit.name))
      return
    }

    if (shortlist.length < MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST) {
      dispatch(setShortlist(myUnit))
    } else {
      setShortlistErrorMessage(
        `You can shortlist a maximum of ${MAXIMUM_UNIT_LENGTH_FOR_SHORTLIST} units.`
      )
    }
  }

  const canWeShowPrice =
    showPrice && !(unit?.metas.status === STATUS_SOLD && hideSoldPrice)

  return (
    <div className="cursor-hand relative mb-3 mr-3 w-2/5 cursor-pointer rounded-md bg-white py-2 pl-2 pr-10">
      <button
        type="button"
        className="absolute -right-0 top-0 z-10"
        onClick={() => handleShortlistClick(unit)}
      >
        <ShortListSvg
          className={`h-15 w-10 ${
            isShortlisted(unit) ? 'text-blue-500' : 'text-gray-500'
          }`}
        />
      </button>
      <button
        type="button"
        onClick={() => trigger && trigger(unit)}
        className="w-full text-left"
      >
        <div className="flex items-center">
          <span
            className="mr-4 truncate text-lg font-bold text-black"
            title={unit.name || unit.id}
          >
            {unit.name || unit.id}
          </span>
          <span className="font-bold text-gray-400">
            {unit.metas.statusName}
          </span>
        </div>
        <div className="flex h-8 items-end font-bold text-gray-400">
          {Number(unit?.metas?.price) > 0 &&
            canWeShowPrice &&
            `$ ${numberWithCommas(String(unit?.metas?.price))}`}
        </div>
      </button>
    </div>
  )
}

export default UnitCard
