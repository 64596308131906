import React, { BaseSyntheticEvent } from 'react'

import { InteractiveMapItemInterface } from '@store/types'

import AssetHandler from '@utilities/asset-handler'

export interface ComponentPropsInterface {
  itemData: InteractiveMapItemInterface
  onItemClick: (groupId: string) => void
  isActive: boolean
}

const InteractiveMapItem = ({
  itemData,
  onItemClick,
  isActive,
}: ComponentPropsInterface) => {
  const PLACEHOLDER_IMAGE = 'https://via.placeholder.com/480x270.png'

  const onImageError = (e: BaseSyntheticEvent) => {
    e.target.src = PLACEHOLDER_IMAGE
  }

  return (
    <div>
      <div
        className={`relative cursor-pointer ${
          isActive && 'rounded-md border-2 border-blue-500'
        }`}
      >
        <img
          role="none"
          loading="lazy"
          src={
            AssetHandler({
              url: itemData.controlSrc,
              type: 'new',
              noSpliceUrl: true,
            }) ?? PLACEHOLDER_IMAGE
          }
          alt={itemData.label}
          className="w-full rounded object-fill"
          onError={onImageError}
          onClick={() => onItemClick(itemData.groupId)}
        />
      </div>
      {itemData?.label && (
        <div
          className={`${
            isActive ? 'text-blue-500' : 'text-white'
          } mt-2 truncate border-b-2 font-medium`}
          title={itemData?.label}
        >
          {itemData?.label}
        </div>
      )}
    </div>
  )
}

export default InteractiveMapItem
