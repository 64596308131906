import React from 'react'
import { connect } from 'react-redux'

import type {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Filter from '@components/filter'
import Navigation from '@components/navigation'
import { ArrowTurnSvg, FilterSvg } from '@components/svg'

import {
  Level,
  Unit,
  selectFromResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  Polygon,
  selectFromResult as selectInteractiveFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

export interface LevelViewProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  showPrice: boolean
  hideFilter: boolean
}

const LevelView = ({
  projectIdentity,
  showPrice,
  session,
  hideFilter,
}: LevelViewProps): React.ReactElement => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [levels, setLevels] = React.useState<Array<Polygon>>([])
  const [activeBlock, setActiveBlock] = React.useState('')
  const [hasblocks, setHasBlock] = React.useState(false)
  const [isFilterOpen, toggleFilter] = React.useState(false)

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectInteractiveFromResult }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  const handleLevelSelect = async (level: string) => {
    toggleFilter(false)
    const splicdeGroupId = level.split('-')
    if (splicdeGroupId.length > 1) {
      await firebaseControlQuery.update({
        [`building.activeBlock`]: splicdeGroupId[0],
        [`building.activeLevel`]: splicdeGroupId[1],
      })
    } else {
      await firebaseControlQuery.updateCollection('building.activeLevel', level)
    }
    setTimeout(async () => {
      await firebaseControlQuery.updateCollection(
        'building.sidePanelFolded',
        true
      )
      await firebaseControlQuery.updateRoute('building')
    }, 1000)
  }

  const goBackToArea = async () => {
    await firebaseControlQuery.update({
      [`building.activeBlock`]: '',
      [`building.activeLevel`]: '',
    })
    await firebaseControlQuery.updateRoute('area-view')
  }

  const handleLevelFilter = (groupId: string) => {
    const { levels: payloadLevels } = buildingPayload
    let levelActive = false
    if (session && levels.length > 0) {
      const splicdeGroupId = groupId.split('-')

      const foundLevels = payloadLevels.find((res: Level) => {
        if (splicdeGroupId.length > 1) {
          return splicdeGroupId[1] === res.level
        }
        return groupId === res.level
      })

      if (!foundLevels) {
        return false
      }

      foundLevels.data.forEach((unit: Unit) => {
        if (activeBlock && unit.blockId && unit.blockId !== activeBlock) {
          return
        }
        if (splicdeGroupId.length > 1) {
          if (unit.blockId !== splicdeGroupId[0]) {
            return
          }
        }
        if (!levelActive) {
          levelActive = filterUnitUtil(
            unit,
            session.building.unitFilter,
            showPrice
          )
        }
      })
    }

    return levelActive
  }

  React.useEffect(() => {
    const { maps } = interactivePayload
    const blocksArray = Object.keys(maps?.blocks || {})
    if (blocksArray.length === 1) {
      setActiveBlock(blocksArray[0])
      return
    }
    setHasBlock(blocksArray.length > 1)
  }, [interactivePayload])

  React.useEffect(() => {
    const { maps } = interactivePayload
    const blockKeys = Object.keys(maps?.blocks || {})
    if (blockKeys.length === 0) {
      return
    }
    if (blockKeys.find((res) => res === activeBlock)) {
      setLevels(maps.blocks[activeBlock][0].polygons)
      return
    }
    setLevels(maps.blocks[blockKeys[0]][0].polygons)
  }, [activeBlock])

  React.useEffect(() => {
    if (session) {
      const {
        building: { activeBlock: firebaseActiveBlock },
      } = session
      if (firebaseActiveBlock) {
        setActiveBlock(firebaseActiveBlock)
      }
    }
  }, [session])

  return (
    <Container className="overflow-hidden px-10">
      <div className="mx-4 h-full lg:mx-10">
        <Navigation />
        <DataHandler
          payload={{
            ...buildingPayload,
            data: buildingPayload.levels,
          }}
        >
          <div className="no-scrollbar my-6 grid h-4/5 grid-cols-2 gap-4 overflow-x-auto border-t-2 border-white bg-opacity-0">
            <div className="flex h-full w-full">
              <div className="my-4">
                <button
                  onClick={goBackToArea}
                  type="button"
                  className="mb-4 flex items-center rounded-full bg-white p-2 font-GTWalsheimPro"
                >
                  <ArrowTurnSvg className="h-10 w-10 text-black" />
                </button>
                {!hideFilter && (
                  <button
                    onClick={() => toggleFilter(true)}
                    type="button"
                    className="flex items-center rounded-full bg-white font-GTWalsheimPro"
                  >
                    <FilterSvg className="h-14 w-14 text-black" />
                  </button>
                )}
              </div>
              <div className="relative m-10 w-full xl:w-3/6">
                {session && (
                  <Filter
                    projectIdentity={projectIdentity}
                    toggle={toggleFilter}
                    isOpen={isFilterOpen}
                    unitFilter={session.building.unitFilter}
                    aspects={buildingPayload.aspects}
                  />
                )}
              </div>
            </div>
            <div className="no-scrollbar h-5/6 w-full   overflow-x-auto lg:h-full">
              <div className="avavenirLight my-4 text-base text-white lg:text-xl">
                {hasblocks
                  ? `VIEWING: ${activeBlock?.toUpperCase()}`
                  : 'VIEWING LEVELS'}
              </div>
              <DataHandler
                message="There are no Available Units."
                payload={{
                  ...interactivePayload,
                  data: interactivePayload.maps,
                }}
              >
                <div className="align-center flex flex-wrap content-start bg-opacity-0">
                  {buildingPayload.levels.length > 0 ? (
                    levels
                      .filter((lvl) => handleLevelFilter(lvl.groupId))
                      .map((res: Polygon) => (
                        <button
                          onClick={() => handleLevelSelect(res.groupId)}
                          key={res.groupId}
                          type="button"
                          className="my-3 mr-3 w-2/5 cursor-pointer rounded-md bg-white px-3 py-4 text-center font-bold text-gray-700"
                        >
                          Level {res.groupId}
                        </button>
                      ))
                  ) : (
                    <div className="w-full p-12 text-center text-white">
                      No Levels Found
                    </div>
                  )}
                </div>
              </DataHandler>
            </div>
          </div>
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity: { showPrice, hideFilter },
    projectIdentity,
    firestore,
  }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectIdentity,
    showPrice,
    hideFilter,
  })
)(LevelView)
