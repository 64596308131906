import React from 'react'

import { SvgProps } from '@components/svg/types'

const PlayCircleSvg = ({
  width,
  height,
  className,
  size,
  styles,
  stroke,
  fill,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  return (
    <svg
      style={{
        ...styles,
        ...{ height: 'auto', transform: `scale(${scale[sizeKey]})` },
      }}
      width={width || '24'}
      height={height || '24'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill || 'none'}
      viewBox="0 0 24 24"
      stroke={stroke || 'currentColor'}
      strokeWidth="1.5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
      />
    </svg>
  )
}

export default PlayCircleSvg
