import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Navigation from '@components/navigation'

import {
  selectFromResult as selectFromExternalViewsResult,
  useGetExternalViewsQuery,
} from '@api/external-views'

import { getSession } from '@utilities/firebase-util'

import ExternalLinks from './external-links'

export interface PagePropsInterface {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const ExternalViews = ({ projectIdentity, session }: PagePropsInterface) => {
  const externalViewsPayload = useGetExternalViewsQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromExternalViewsResult }
  )

  return (
    <Container className="overflow-hidden px-10">
      <div className="mx-4 h-full lg:mx-10">
        <Navigation />
        <DataHandler
          payload={{
            ...externalViewsPayload,
            data: externalViewsPayload.externalViewsData,
          }}
        >
          <div className="background-none my-6 flex h-4/6 flex-wrap items-center justify-center border-t-2 border-white bg-opacity-0 sm:h-5/6">
            <ExternalLinks
              projectIdentity={projectIdentity}
              externalViews={externalViewsPayload.externalViewsData}
              externalViewIndex={session?.externalView}
            />
          </div>
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(ExternalViews)
