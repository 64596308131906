import React from 'react'
import { connect } from 'react-redux'

import { RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'
import Navigation from '@components/navigation'

import {
  selectFromResult as selectFromBrochureResult,
  useGetBrochureQuery,
} from '@api/brochure'

import { getSession } from '@utilities/firebase-util'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectName: string
}
const Brochure = ({ session, projectName }: PagePropsInterface) => {
  const brochurePayload = useGetBrochureQuery(
    { projectName },
    { selectFromResult: selectFromBrochureResult }
  )

  return (
    <Container>
      <div className="mx-4 lg:mx-10">
        <Navigation />
        <div className="my-6 border-b-2"></div>
        <DataHandler
          payload={{ ...brochurePayload, data: brochurePayload.brochureData }}
        >
          <GalleryHandler
            galleryName="brochure"
            galleries={brochurePayload.brochureData}
            gallerySession={session?.brochure}
            enableSlideShow
          />
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(
  ({ projectIdentity: { projectId }, firestore }: RootStateFirebase) => ({
    session: getSession(firestore),
    projectName: projectId,
  })
)(Brochure)
