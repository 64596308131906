import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'
import React from 'react'

import baseQueryInterceptor from '@api/base-query-interceptor'

export interface ShortlistDocumentResponse {
  data: Array<ShortlistDocument>
}

export interface ShortlistFormData {
  projectId?: string
  title?: string
  name?: string
  mobile?: string
  email: string
  agent?: string
  agentMobile?: string
  agentEmail?: string
  documents?: Array<ShortlistFormDocuments>
  items?: Array<ShortlistFormItems>
}

export interface ShortlistFormDocuments {
  title: string
  url: string
}

export interface ShortlistFormItems {
  name: string
  configuration: string
  price: string
  area?: string
  image: string
}

export interface ShortlistItem {
  name: string
  configuration: string
  image?: string
  price?: number
  area?: number
}

export interface ShortlistDocument {
  config?: string
  contentType: string
  driver: string
  fileName: string
  id: string
  location: string
  meta: ShortlistDocumentMeta
  name: string
  position: number
  subType?: string
  title?: string
  updated: string
}

export interface ShortlistDocumentMeta {
  logo: boolean
  map: boolean
  cover: boolean
}

export const shortlistedApi = createApi({
  reducerPath: 'shortlistedApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    postShortlisted: builder.mutation<
      ShortlistDocumentResponse,
      ShortlistFormData
    >({
      query: (params: ShortlistFormData) => ({
        url: `/v1/${params.projectId}/shortlist`,
        method: 'post',
        body: {
          ...params,
        },
      }),
    }),
    getShortlistDocuments: builder.query<
      ShortlistDocumentResponse,
      { projectId: string }
    >({
      query: (params: { projectId: string }) => ({
        url: `/v1/${params.projectId}/shortlist/documents`,
        method: 'get',
      }),
    }),
  }),
})

export const selectFromResult = ({
  data,
  isLoading,
}: UseQueryStateResult<any, any>) => ({
  documents: data?.data || [],
  isLoaded: !isLoading,
})

export const { usePostShortlistedMutation, useGetShortlistDocumentsQuery } =
  shortlistedApi
