function numberWithCommas(num: string | number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getQueryStringParams(query: string) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: any, param: any) => {
          const newParams = params
          const [key, value] = param.split('=')
          newParams[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return newParams
        }, {})
    : {}
}

const getFirebaseHost = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return 'localhost'
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.firebaseHost || 'localhost'
}

const getFirebasePort = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return ''
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.firebasePort || ''
}

const getAssetPort = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) {
    return ''
  }

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.assetPort || ''
}

const roomQueryString = (): string => {
  const appConfig = localStorage.getItem('appConfig')

  if (!appConfig) return ''

  const appConfigObject = JSON.parse(appConfig)

  return appConfigObject.room ? `?showcaseRoom=${appConfigObject.room}` : ''
}

export {
  getQueryStringParams,
  numberWithCommas,
  getFirebaseHost,
  getFirebasePort,
  getAssetPort,
  roomQueryString,
}
