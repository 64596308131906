import React from 'react'

import { SvgProps } from '@components/svg/types'

const VirtualGogglesSvg = ({
  width,
  height,
  className,
  size,
  styles,
  fill,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 'xs' : 's'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.6,
  }

  return (
    <svg
      style={{ ...styles, ...{ transform: `scale(${scale[sizeKey]})` } }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="currentColor"
      viewBox="0 0 64 34"
      stroke="currentColor"
    >
      <path d="M 0 16 L 0 0 L 28.359 0 L 29.069 0.694 C 29.633 1.247 29.927 1.46 30.508 1.737 C 32.161 2.527 33.839 2.527 35.492 1.737 C 36.073 1.46 36.367 1.247 36.931 0.694 L 37.64 0 L 66 0 L 66 32 L 56.697 32 L 47.395 31.998 L 47.024 31.417 C 44.047 26.737 38.678 23.834 33 23.834 C 27.322 23.834 21.953 26.737 18.976 31.416 L 18.605 31.999 L 9.303 31.999 L 0 32 Z M 17.901 23.876 C 19.611 23.587 21.045 22.856 22.308 21.632 C 24.624 19.386 25.348 16.219 24.231 13.226 C 23.45 11.134 21.518 9.26 19.361 8.503 C 17.514 7.844 15.486 7.844 13.639 8.503 C 11.482 9.261 9.549 11.134 8.769 13.225 C 7.652 16.219 8.376 19.387 10.692 21.632 C 12.631 23.512 15.243 24.325 17.901 23.876 Z M 50.901 23.876 C 52.611 23.587 54.045 22.856 55.308 21.632 C 57.624 19.386 58.348 16.219 57.231 13.226 C 56.45 11.134 54.518 9.26 52.361 8.503 C 50.514 7.844 48.486 7.844 46.639 8.503 C 44.482 9.261 42.549 11.134 41.769 13.225 C 40.652 16.219 41.376 19.387 43.692 21.632 C 45.631 23.512 48.243 24.325 50.901 23.876 Z"></path>
    </svg>
  )
}

export default VirtualGogglesSvg
