import React from 'react'

import { SvgProps } from '@components/svg/types'

const CaretSvg = ({
  width,
  height,
  className,
  size,
  styles,
  rotate,
}: SvgProps) => {
  const { innerWidth } = window
  const defaultWidth = innerWidth > 1024 ? 's' : 'm'
  const sizeKey = typeof size === 'undefined' ? defaultWidth : size
  const rotateValue: 'left' | 'right' | 'top' | 'down' = rotate || 'left'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.8,
    xs: 0.5,
  }

  const rotateDegrees = {
    top: '270deg',
    down: '90deg',
    right: '0deg',
    left: '180deg',
  }

  return (
    <svg
      className={`${className}`}
      style={{
        ...styles,
        ...{
          height: 'auto',
          transform: `rotate(${rotateDegrees[rotateValue]}) scale(${scale[sizeKey]})`,
        },
      }}
      width={width || '48'}
      height={height || '48'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      fill="currentColor"
    >
      <path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z" />
    </svg>
  )
}

export default CaretSvg
