import React from 'react'

import { SvgProps } from '@components/svg/types'

const LogoutSvg = ({ width, height, className, size, styles }: SvgProps) => {
  const sizeValue = size || 'm'

  const scale = {
    xl: 3.0,
    lg: 2.5,
    m: 1.0,
    s: 0.5,
    xs: 0.2,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ height: 'auto', transform: `scale(${scale[sizeValue]})` },
      }}
      width={width || '40'}
      height={height || '38'}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeWidth={1.5}
        strokeLinejoin="round"
        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
      />
    </svg>
  )
}

export default LogoutSvg
