import { Store } from 'react-notifications-component'

const broadcastNotification = (
  title: string,
  type: 'success' | 'danger' | 'warning',
  duration: number,
  showIcon: boolean
): string =>
  Store.addNotification({
    title,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration,
      showIcon,
    },
  })

const notifySuccess = (
  title: string,
  duration = 5000,
  showIcon = false
): string => broadcastNotification(title, 'success', duration, showIcon)

const notifyError = (
  title: string,
  duration = 5000,
  showIcon = false
): string => broadcastNotification(title, 'danger', duration, showIcon)

const notifyWarning = (
  title: string,
  duration = 5000,
  showIcon = false
): string => broadcastNotification(title, 'warning', duration, showIcon)

const removeAllNotifications = (): void => Store.removeAllNotifications()

const removeNotification = (id: string): void => Store.removeNotification(id)

export {
  notifySuccess,
  notifyError,
  notifyWarning,
  removeAllNotifications,
  removeNotification,
}
