import React, { BaseSyntheticEvent } from 'react'

import { GalleryItemInterface } from '@store/types'

import { PlaySvg } from '@components/svg'

import AssetHandler from '@utilities/asset-handler'

export interface ComponentPropsInterface {
  galleryData: {
    index: number
    item: GalleryItemInterface
  }
  activeGalleryItemIndex: number
  onItemClick: (index: number, type: string) => void
  showBorderOnActiveItem: boolean
}

const GalleryItem = ({
  galleryData,
  activeGalleryItemIndex,
  onItemClick,
  showBorderOnActiveItem,
}: ComponentPropsInterface) => {
  const PLACEHOLDER_IMAGE = 'https://via.placeholder.com/480x270.png'

  const isVideo = (item: GalleryItemInterface) =>
    item.videoSource && item.videoSource !== ''

  const onImageError = (e: BaseSyntheticEvent) => {
    e.target.src = PLACEHOLDER_IMAGE
  }

  return (
    <div>
      <div
        className={`relative ${
          isVideo(galleryData.item) ? '' : 'cursor-pointer'
        } ${
          activeGalleryItemIndex === galleryData.index && showBorderOnActiveItem
            ? 'rounded-md border-2 border-blue-500'
            : ''
        }`}
      >
        <img
          role="none"
          loading="lazy"
          src={
            AssetHandler({
              url: galleryData.item.imageSource,
              type: galleryData.item.assetType || 'legacy',
            }) ?? PLACEHOLDER_IMAGE
          }
          alt={galleryData.item.title}
          className="h-48 w-full rounded object-cover"
          onError={onImageError}
          onClick={(e) =>
            isVideo(galleryData.item)
              ? e.preventDefault()
              : onItemClick(galleryData.index, 'image')
          }
        />
        {isVideo(galleryData.item) && (
          <button
            type="button"
            className="absolute inset-x-40% inset-y-30% 2xl:inset-x-45%"
            onClick={() => onItemClick(galleryData.index, 'video')}
          >
            <PlaySvg className="h-12 w-12 text-white" stroke="1.5" />
          </button>
        )}
      </div>
      {galleryData.item?.title && (
        <div
          className={`${
            activeGalleryItemIndex === galleryData.index
              ? 'text-blue-500'
              : 'text-white'
          } mt-2 truncate border-b-2 font-medium`}
          title={galleryData.item?.title}
        >
          {galleryData.item?.title}
        </div>
      )}
    </div>
  )
}

export default GalleryItem
