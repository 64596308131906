import React from 'react'

import { RouteInterface } from '@store/types'

interface ComponentPropsInterface {
  menu: RouteInterface
  checkRoute: (arg: string) => boolean
  handleClick: (arg: string) => void
}

const MenuItem = ({
  menu,
  checkRoute,
  handleClick,
}: ComponentPropsInterface) => (
  <button
    type="button"
    className={`${
      checkRoute(menu.route)
        ? 'cursor-default bg-black text-white box-shadow-active'
        : 'cursor-hand bg-white text-gray-700 box-shadow'
    } mx-2 my-3 rounded-full px-4 py-2 font-bold ipad-p:mx-1 ipad-p:px-5 ipad-air-2-p:px-3 ipad-air-2-p:py-2 ipad-air-2-l:mx-1`}
    onClick={() => handleClick(menu.route)}
  >
    {menu.label}
  </button>
)

export default MenuItem
