import React from 'react'

import { BadgeGroup } from '@components/badge'
import { ConfirmationModal } from '@components/modals'
import { PlayCircleSvg, StopCircleSvg } from '@components/svg'

import InputHandler from '@utilities/form-util'

export interface SlideshowTransitionProps {
  newTransitionTime: number
  newTransitionType: string
}

export interface GalleryControlProps {
  galleryName: string
  isSlideShowPlaying: boolean
  onChangeSlideShowStatus: (
    newStatus: boolean,
    data?: SlideshowTransitionProps
  ) => void
}

const options = [
  { label: 'Slide', value: 'slide' },
  { label: 'Fade In', value: 'fadeIn' },
]

const GalleryControl = ({
  galleryName,
  isSlideShowPlaying,
  onChangeSlideShowStatus,
}: GalleryControlProps) => {
  const [showGalleryTransitionTimeModal, toggleGalleryTransitionTimeModal] =
    React.useState(false)

  const [transitionTime, setTransitionTime] = React.useState({
    value: '3',
    isValid: true,
    message: '',
  })
  const [transitionType, setTransitionType] = React.useState<string>(
    options[0].value
  )

  const handleOnChangeSlideShowStatus = (newStatus: boolean) => {
    if (newStatus) {
      toggleGalleryTransitionTimeModal(true)
    } else {
      onChangeSlideShowStatus(false)
    }
  }

  const handleConfirmTransitionTime = () => {
    if (!transitionTime.isValid) return

    onChangeSlideShowStatus(true, {
      newTransitionTime: Number(transitionTime.value) * 1000,
      newTransitionType: transitionType,
    })
  }

  return (
    <>
      <div className="transition-all">
        {isSlideShowPlaying && (
          <button
            onClick={() => handleOnChangeSlideShowStatus(false)}
            type="button"
            className={`flex items-center justify-center rounded-full bg-white shadow-md ${
              ['floorGallery', 'unitGallery'].includes(galleryName)
                ? 'mt-4 h-14 w-14 '
                : 'h-10 w-10 '
            } ${galleryName === 'vision' ? 'ml-4' : 'mb-5'}`}
          >
            <StopCircleSvg
              className="cursor-pointer font-bold text-blue-900"
              size="m"
              fill="black"
            />
          </button>
        )}
        {!isSlideShowPlaying && (
          <button
            onClick={() => handleOnChangeSlideShowStatus(true)}
            type="button"
            className={`flex items-center justify-center rounded-full bg-white pl-1 shadow-md ${
              ['floorGallery', 'unitGallery'].includes(galleryName)
                ? 'mt-4 h-14 w-14 '
                : 'h-10 w-10 '
            } ${galleryName === 'vision' ? 'ml-4' : 'mb-5'} `}
          >
            <PlayCircleSvg
              className="cursor-pointer text-black"
              size="m"
              fill="black"
            />
          </button>
        )}
      </div>

      <ConfirmationModal
        id="gallery-transition-time-modal"
        title="Slideshow Settings"
        isVisible={showGalleryTransitionTimeModal}
        toggleModal={(toggle) => toggleGalleryTransitionTimeModal(toggle)}
        getModalAction={(action) =>
          action === 'confirmed' && handleConfirmTransitionTime()
        }
        disableActionButton={!transitionTime.isValid}
        message={
          <div className="flex flex-col gap-6 text-gray-800">
            <div>
              <label htmlFor="transition-time">
                Set transition time for slideshow
              </label>
              <div className="flex items-center gap-2">
                <div className="w-50">
                  <input
                    id="transition-time"
                    type="number"
                    onChange={(e) => InputHandler(e, setTransitionTime)}
                    className={`focus:shadow-outline focus:outline-none  ${
                      transitionTime.isValid ? 'input-gray' : 'input-gray-error'
                    }`}
                    min="1"
                    max="100"
                    value={transitionTime.value}
                    required
                  />
                </div>
                <p>seconds</p>
              </div>
              {transitionTime.message && (
                <div className="h-4">
                  <p className="text-xs italic text-red-500">
                    {transitionTime.message}
                  </p>
                </div>
              )}
            </div>

            {galleryName !== 'team' && (
              <div>
                <label htmlFor="transition-type">Set transition type</label>
                <div className="flex justify-start">
                  <BadgeGroup
                    name="transitionType"
                    getSelectedValue={(arg) => setTransitionType(arg)}
                    defaultChecked={transitionType}
                    options={options}
                  />
                </div>
              </div>
            )}
          </div>
        }
        okButtonText="Start Slideshow"
      />
    </>
  )
}
export default GalleryControl
