import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setProjectIdentity } from '@store/actionSlices/project'
import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import { CloseCircleSvg } from '@components/svg'

import FirebaseControlQuery from '@utilities/firebase-control-query'

export interface ModalInterface {
  isVisible: boolean
  toggleModal: (arg: boolean) => void
  projectIdentity: ProjectIdentity
  map: SessionMap | undefined
}

const AddSessionModal = ({
  isVisible = false,
  toggleModal,
  projectIdentity,
  map,
}: ModalInterface) => {
  const firebaseControlQuery = FirebaseControlQuery({
    projectIdentity,
    map,
  })

  const dispatch = useDispatch()

  const { sessionList } = projectIdentity

  const [sessionId, setSessionId] = React.useState('')
  const [sessionIdError, setSessionIdError] = React.useState('')
  const [sessionName, setSessionName] = React.useState('')
  const [isLoading, setLoader] = React.useState(false)

  const validate = (): boolean => {
    if (!sessionId) {
      setSessionIdError('The Session ID field is required.')
      return false
    }

    if (sessionId.length < 6) {
      setSessionIdError('The Session ID should be 6 characters in length.')
      return false
    }

    if (sessionList.find((res) => res.sessionId === sessionId)) {
      setSessionIdError('Please enter a new Session ID')
      return false
    }
    return true
  }

  const updateFirebaseSession = async (
    newSessionId: string,
    newSessionName: string
  ) => {
    try {
      await firebaseControlQuery.updateConnection(true, newSessionId)
      dispatch(
        setProjectIdentity({
          ...projectIdentity,
          sessionId: newSessionId,
          sessionList: [
            ...sessionList,
            {
              sessionId: newSessionId,
              sessionName: newSessionName,
            },
          ],
        })
      )
      return true
    } catch (e) {
      setSessionIdError('The Session ID is invalid.')
      return false
    }
  }

  const clear = () => {
    setSessionIdError('')
    setSessionId('')
    setSessionName('')
    setLoader(false)
  }

  const close = () => {
    clear()
    toggleModal(false)
  }

  const addSession = async () => {
    if (!validate()) {
      return
    }

    setSessionIdError('')

    setLoader(true)
    const response = await updateFirebaseSession(sessionId, sessionName)
    setLoader(false)

    if (response) {
      close()
    }
  }

  return (
    <div
      id="add-session-modal"
      aria-hidden="true"
      className={`h-modal fixed left-0 right-0 top-4 z-20 items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-400 bg-opacity-50 md:inset-0 md:h-full
   ${isVisible ? 'visible' : 'invisible'} font-GTWalsheimPro
  `}
    >
      <div className="relative m-auto mt-32 h-full w-full max-w-2xl px-4 md:h-auto">
        <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
          <div className="flex items-center justify-between rounded-t border-b p-5 dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
              Add Session
            </h3>
            <button
              type="button"
              onClick={close}
              data-modal-toggle="default-modal"
            >
              <CloseCircleSvg
                size="s"
                className="cursor-pointer"
                fill="hsl(1, 0%, 0%)"
              />
            </button>
          </div>
          <div className="space-y-6 p-6">
            <div>
              <label
                htmlFor="sessionID"
                className={`${sessionIdError ? 'text-red-500' : ''}`}
              >
                Session ID
              </label>
              <input
                type="text"
                name="sessionID"
                value={sessionId}
                className={`w-full ${
                  sessionIdError
                    ? 'border-rose-500 focus:border-rose-500'
                    : 'border-gray-200 focus:border-gray-200'
                } rounded-md focus:ring-0`}
                onChange={(e) => setSessionId(e.target.value)}
                placeholder="Enter session ID"
                maxLength={6}
                required
              />
              {sessionIdError && (
                <small className="text-sm italic text-rose-600 dark:text-rose-500">
                  {sessionIdError}
                </small>
              )}
            </div>
            <div>
              <label htmlFor="sessionName">Session Name</label>
              <input
                type="text"
                name="sessionName"
                value={sessionName}
                className="w-full rounded-md border-gray-200 focus:border-gray-200 focus:ring-0"
                onChange={(e) => setSessionName(e.target.value)}
                placeholder="Enter session name"
                maxLength={20}
              />
            </div>
          </div>
          <div className="border-t border-gray-200 px-6 py-3">
            <div className="flex items-center justify-end">
              <button
                type="button"
                onClick={clear}
                className="mr-2 inline-flex items-center rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-0 focus:ring-gray-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                title="Clear the form fields."
              >
                Clear
              </button>
              <button
                type="button"
                onClick={addSession}
                className="inline-flex items-center rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-0 focus:ring-gray-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                title="Add new session."
              >
                Add
                {isLoading && (
                  <div
                    style={{ borderTopColor: 'transparent' }}
                    className="ml-1 mr-2 mt-1 h-4 w-4 animate-spin rounded-full border-2 border-dotted border-black"
                  ></div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  projectIdentity,
  map: firestore.data.map,
}))(AddSessionModal)
