import React from 'react'

import { SvgProps } from '@components/svg/types'

const ClockWiseSvg = ({
  width,
  height,
  className,
  size,
  styles,
  direction,
  stroke,
}: SvgProps) => {
  const directionValue: 'clockwise' | 'counter-clockwise' =
    direction || 'clockwise'

  let scaleValue = '1'

  if (directionValue === 'counter-clockwise') {
    scaleValue = '-1, 1'
  }

  return (
    <svg
      className={`${className}`}
      style={{
        ...styles,
        ...{
          transform: `rotate(0deg) scale(${scaleValue})`,
        },
      }}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="20 -40 650 650"
      stroke="currentColor"
    >
      <path
        d="m619.23 213.52-18.949 4.6602c21.789 88.543-3.6523 179.85-68.047 244.25-100.57 100.56-264.2 100.56-364.76 0-100.55-100.55-100.55-264.18 0.011719-364.74 48.715-48.715 113.48-75.539 182.37-75.539 65.918 0 128.01 24.617 175.93 69.402l-39.871 39.871 93.828-0.011719 0.015625-93.828-40.18 40.176c-51.598-48.473-118.59-75.113-189.72-75.113-74.098 0-143.76 28.852-196.16 81.258-108.18 108.16-108.18 284.16-0.015625 392.32 54.086 54.09 125.12 81.129 196.17 81.129 71.055 0 142.09-27.043 196.17-81.129 69.273-69.266 96.637-167.47 73.211-262.7z"
        strokeWidth={stroke || '1'}
      />
      <path
        d="m343.35 273.57h-109.54v13.004h122.54v-194h-13.008z"
        strokeWidth={stroke || '1'}
      />
    </svg>
  )
}

export default ClockWiseSvg
