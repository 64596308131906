import React from 'react'

import { ProjectIdentity } from '@store/types'

import { ExternalViewsData } from '@api/external-views'

import FirebaseControlQuery from '@utilities/firebase-control-query'

export interface ExternalLinksInterface {
  projectIdentity: ProjectIdentity
  externalViews: Array<ExternalViewsData>
  externalViewIndex?: number
}

const ExternalLinks = ({
  projectIdentity,
  externalViews,
  externalViewIndex,
}: ExternalLinksInterface): React.ReactElement => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [selectedView, setSelectedView] = React.useState(0)

  const handleTrigger = (index: number) => {
    firebaseControlQuery.updateCollection(`externalView`, index)
  }

  React.useEffect(() => {
    if (externalViewIndex !== undefined) {
      setSelectedView(externalViewIndex)
    }
  }, [externalViewIndex])

  return (
    <div className="flex flex-wrap items-center justify-center">
      {externalViews.map((item: ExternalViewsData, index: number) => (
        <button
          className={`mx-2 my-3 rounded-full px-7 py-3 font-bold ipad-p:px-6 ipad-air-2-p:px-4 ipad-air-2-p:py-2 ${
            selectedView !== index
              ? 'cursor-hand bg-white text-gray-700 box-shadow'
              : 'cursor-default bg-black text-white box-shadow-active'
          }`}
          onClick={() => handleTrigger(index)}
          type="button"
          tabIndex={index}
          disabled={selectedView === index}
          key={item.title}
        >
          <span className="whitespace-nowrap">{item.title}</span>
        </button>
      ))}
    </div>
  )
}

export default ExternalLinks
