import React from 'react'

import AssetHandler from '@utilities/asset-handler'
import { setBackground } from '@utilities/asset-util'

export interface ContainerProps {
  children: React.ReactNode
  style?: React.CSSProperties
  background?: {
    url: string
    type: 'legacy' | 'new'
    noSpliceUrl: boolean
  }
  gradiant?: number
  className?: string
}

const Container = ({
  children,
  style,
  background = {
    url: '/images/controller-bg.jpg',
    type: 'new',
    noSpliceUrl: true,
  },
  gradiant = 0.5,
  className,
}: ContainerProps) => (
  <div
    className={`background-cover min-w-684px bg-mainClassic px-2 ${className}`}
    style={{
      ...setBackground(background ? AssetHandler(background) : '', {
        gradiant,
      }),
      ...style,
    }}
  >
    {children}
  </div>
)

export default Container
