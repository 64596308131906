import React from 'react'
import { useDispatch } from 'react-redux'

import { setLoadingStatus } from '@store/actionSlices/statusIndicator'
import { TokenInterface, setToken } from '@store/actionSlices/token'

import { useRenewUserTokenMutation } from '@api/authentication'

import { hasToken, isTokenExpired } from './token-helper'

interface TokenRenewalHandlerProps {
  token: TokenInterface
}

const TokenRenewalHandler = ({ token }: TokenRenewalHandlerProps) => {
  const dispatch = useDispatch()

  const [tokenRenewalIntervalId, setTokenRenewalIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()
  const [tokenRenewalState, setTokenRenewalState] = React.useState(false)

  const [
    renewUserToken,
    {
      isLoading: reefreshTokenIsLoading,
      data: refreshTokenData,
      error: refreshTokenError,
    },
  ] = useRenewUserTokenMutation()

  const handleTokenRenewalProcess = () => {
    if (!isTokenExpired(token)) {
      return
    }

    const {
      refresh_token: { token: refreshToken },
    } = token

    renewUserToken({ refresh_token: refreshToken })
  }

  const resetTokenRenewalInterval = () => {
    if (tokenRenewalIntervalId) {
      clearInterval(tokenRenewalIntervalId)
      setTokenRenewalIntervalId(undefined)
    }
  }

  const setTokenRenewalInterval = () => {
    if (!hasToken(token)) {
      resetTokenRenewalInterval()
      return
    }
    if (!tokenRenewalIntervalId) {
      setTokenRenewalIntervalId(
        setInterval(() => setTokenRenewalState(true), 1000 * 86400)
      )
    }
  }

  React.useEffect(() => {
    dispatch(setLoadingStatus(reefreshTokenIsLoading))
  }, [reefreshTokenIsLoading])

  React.useEffect(() => {
    if (refreshTokenData) {
      const {
        data: { user },
      } = refreshTokenData
      dispatch(setToken(user as TokenInterface))
    }
  }, [refreshTokenData])

  React.useEffect(() => {
    if (refreshTokenError) {
      console.error('Failed to renew the user token.', refreshTokenError)
    }
  }, [refreshTokenError])

  React.useEffect(() => {
    if (tokenRenewalState) {
      handleTokenRenewalProcess()
      setTokenRenewalState(false)
    }
  }, [tokenRenewalState])

  React.useEffect(() => {
    setTokenRenewalInterval()
  }, [token])
}

export default TokenRenewalHandler
