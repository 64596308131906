import React from 'react'

import { SvgProps } from '@components/svg/types'

const MagnifyingGlassMinusSVG = ({
  width,
  height,
  className,
  stroke,
}: SvgProps) => (
  <svg
    width={width || '40'}
    height={height || '40'}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={stroke || 1.5}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6"
    />
  </svg>
)

export default MagnifyingGlassMinusSVG
