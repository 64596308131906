import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'

import { roomQueryString } from '@utilities/helper'

export interface UnitGalleryInterface {
  src: string
  label: string
  type: string
  thumbnail?: string
}
export interface UnitGalleryPayloadInterface {
  data: Array<UnitGalleryInterface>
}
type UnitGalleryTypeInterface = {
  projectName: string
  unitId: string
}
export const unitGalleryApi = createApi({
  reducerPath: 'unitGalleryApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getUnitGallery: builder.query<
      UnitGalleryPayloadInterface,
      UnitGalleryTypeInterface
    >({
      query: (params: UnitGalleryTypeInterface) => ({
        url: `/v1/${params.projectName}/unit-galleries/${
          params.unitId
        }${roomQueryString()}`,
        method: 'get',
      }),
    }),
  }),
})
export const selectFromResult = ({
  data,
  isLoading,
  isError,
  status,
}: UseQueryStateResult<any, any>) => ({
  unitGalleryData: data?.data || [],
  isLoaded: !isLoading,
  isError,
  status,
})
export const { useGetUnitGalleryQuery } = unitGalleryApi
