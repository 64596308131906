import React from 'react'

interface ComponentProps {
  isLoaded: boolean
  buildings: Array<string>
  handleClick: (arg: string) => void
}

const BuildingList = ({ isLoaded, buildings, handleClick }: ComponentProps) => {
  if (!isLoaded)
    return (
      <div className="inline-flex items-center text-3xl text-white">
        <div className="border-white-400 mr-2 h-8 w-8 animate-spin rounded-full border-4 border-solid border-t-transparent"></div>
        Loading building data ...
      </div>
    )

  return (
    <>
      {buildings.length > 0 ? (
        buildings.map((building: string) => (
          <button
            onClick={() => handleClick(building)}
            key={building}
            type="button"
            className="my-3 mr-3 w-2/5 cursor-pointer rounded-md bg-white px-3 py-4 text-center font-bold text-gray-700"
          >
            Building {building}
          </button>
        ))
      ) : (
        <div className="rounded-md bg-white px-3 py-4 text-center font-bold">
          No building found
        </div>
      )}
    </>
  )
}

export default BuildingList
