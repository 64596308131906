import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { setLoadingStatus } from '@store/actionSlices/statusIndicator'
import {
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
  SnaploaderInterface,
  UnitFilterInterface,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Filter from '@components/filter'
import Navigation from '@components/navigation'
import {
  ArrowTurnSvg,
  CameraOffSvg,
  CameraOnSvg,
  FilterSvg,
} from '@components/svg'

import {
  Level,
  Unit,
  selectFromResult,
  useGetBuildingQuery,
} from '@api/building'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'
import { filterUnit as filterUnitUtil } from '@utilities/unit-filter-util'

export interface SnaploaderViewProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

const SnaploaderView = ({ projectIdentity, session }: SnaploaderViewProps) => {
  const dispatch = useDispatch()

  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  const [snaploaderState, setSnaploaderState] =
    React.useState<SnaploaderInterface>()
  const [unitFilter, setUnitFilter] = React.useState<UnitFilterInterface>()
  const [isFilterOpen, toggleFilter] = React.useState<boolean>(false)

  const handleToggleRotateCamera = () => {
    firebaseControlQuery.update({
      'snaploader.rotateCamera': !snaploaderState?.rotateCamera,
    })
  }

  const handleToggleBlock = (viewState: string) => {
    firebaseControlQuery.update({
      'snaploader.activeView': viewState,
      'snaploader.activeBlock':
        viewState.split('-')?.[1]?.replace(/\s/g, '') ?? '',
    })
  }

  const handleLevelSelect = async (level: string) => {
    toggleFilter(false)

    const splicedGroupId = level.split('_')
    const selectedLevel = buildingPayload.levels.find(
      (lvl: Level) => splicedGroupId[1] === lvl.level
    )
    if (!selectedLevel) {
      return
    }
    const unit = selectedLevel.data.find(
      (unt: Unit) =>
        (unt?.blockId || '').replace(/\s/g, '') === splicedGroupId[0]
    )
    if (!unit) {
      return
    }

    if (unit.blockId) {
      await firebaseControlQuery.update({
        [`building.activeBlock`]: unit.blockId,
        [`building.activeLevel`]: splicedGroupId[1],
      })
    } else {
      await firebaseControlQuery.updateCollection('building.activeLevel', level)
    }
    await firebaseControlQuery.updateCollection(
      'building.sidePanelFolded',
      true
    )
    await firebaseControlQuery.updateRoute('building')
  }

  const handleBackButtonPress = async () => {
    await firebaseControlQuery.update({
      [`building.activeBlock`]: '',
      [`building.activeLevel`]: '',
    })
    await firebaseControlQuery.updateRoute('vision')
  }

  const getFilteredData = (levels: Level[]): Array<string> => {
    const filteredLevelList: Array<string> = []

    levels.forEach((level) => {
      level.data.forEach((unit: Unit) => {
        let identifier = ''
        if (unit.blockId && unit.blockId !== '') {
          identifier += `${unit.blockId.replace(/\s/g, '')}_`
        }
        identifier += level.level
        if (!filteredLevelList.includes(identifier) && unitFilter) {
          const response = filterUnitUtil(
            unit,
            unitFilter,
            projectIdentity.showPrice
          )
          if (response) {
            filteredLevelList.push(identifier)
          }
        }
      })
    })

    return filteredLevelList
  }

  React.useEffect(() => {
    if (session) {
      const {
        snaploader,
        building: { unitFilter: unitFilterFirebase },
      } = session

      setSnaploaderState(snaploader)
      setUnitFilter(unitFilterFirebase)

      dispatch(setLoadingStatus(!snaploader.isLoaded))
    }
  }, [session])

  const blockViewsOptions = React.useMemo(
    () =>
      snaploaderState?.data.views.map((item) => ({
        label: item,
        value: item,
      })) ?? [],
    [snaploaderState?.data.views]
  )

  const levelsOptions = React.useMemo(() => {
    let optionsResult = unitFilter?.apply
      ? getFilteredData(buildingPayload.levels).map((item) => ({
          label: item,
          value: item,
        }))
      : snaploaderState?.data.levels.map((item) => ({
          label: item.key,
          value: item.value,
        }))

    if (snaploaderState?.activeBlock) {
      optionsResult = optionsResult?.filter(
        (item) => item.value.split('_')?.[0] === snaploaderState.activeBlock
      )
    }

    return optionsResult
  }, [
    snaploaderState?.data.levels,
    snaploaderState?.activeBlock,
    unitFilter?.apply,
    buildingPayload,
  ])

  return (
    <Container className="overflow-x-hidden">
      <div className="mx-4 flex h-full flex-col lg:mx-10">
        <Navigation />
        <DataHandler
          payload={{
            ...buildingPayload,
            data: buildingPayload.levels,
          }}
        >
          <div className="no-scrollbar my-6 h-4/5 overflow-x-auto border-t-2 border-white bg-opacity-0">
            <div className="flex h-full gap-3">
              <div className="flex h-full w-full flex-1">
                <div className="my-4 flex flex-col gap-4">
                  <button
                    onClick={handleBackButtonPress}
                    type="button"
                    className="flex items-center rounded-full bg-white p-2 font-GTWalsheimPro"
                  >
                    <ArrowTurnSvg className="h-10 w-10 text-black" />
                  </button>
                  {!projectIdentity.hideFilter && (
                    <button
                      onClick={() => toggleFilter(true)}
                      type="button"
                      className="flex items-center rounded-full bg-white font-GTWalsheimPro"
                    >
                      <FilterSvg className="h-14 w-14 text-black" />
                    </button>
                  )}
                  <button
                    onClick={handleToggleRotateCamera}
                    type="button"
                    className={`flex items-center rounded-full p-2 font-GTWalsheimPro ${
                      snaploaderState?.rotateCamera ? 'bg-black' : 'bg-white'
                    }`}
                  >
                    {snaploaderState?.rotateCamera ? (
                      <CameraOnSvg className="text-white" />
                    ) : (
                      <CameraOffSvg className="text-black" />
                    )}
                  </button>
                </div>
                <div className="relative m-10 w-full xl:w-2/3">
                  {session && (
                    <Filter
                      projectIdentity={projectIdentity}
                      toggle={toggleFilter}
                      isOpen={isFilterOpen}
                      unitFilter={session.building.unitFilter}
                      aspects={buildingPayload.aspects}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-1 flex-col gap-4 pt-5">
                <div>
                  <span className="text-2xl text-white">Change View</span>
                  <div className="align-center mt-2 flex flex-wrap content-start gap-3">
                    {blockViewsOptions.map((view) => (
                      <button
                        onClick={() => handleToggleBlock(view.value)}
                        key={view.value}
                        type="button"
                        className={`w-2/5 cursor-pointer rounded-md px-3 py-4 text-center font-bold ${
                          snaploaderState?.activeView === view.value
                            ? 'bg-black text-white'
                            : 'bg-white text-gray-700'
                        }`}
                      >
                        {view.label}
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <span className="text-2xl text-white">Viewing Levels</span>
                  <div className="align-center mt-2 flex flex-wrap content-start gap-3">
                    {levelsOptions?.map((level) => (
                      <button
                        onClick={() => handleLevelSelect(level.value)}
                        key={level.value}
                        type="button"
                        className="w-1/4 cursor-pointer rounded-md bg-white px-3 py-4 text-center font-bold text-gray-700"
                      >
                        {level.label}
                      </button>
                    ))}
                    {levelsOptions?.length === 0 &&
                      snaploaderState?.isLoaded && (
                        <span className="text-lg text-white">
                          No units found
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(SnaploaderView)
