import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  filterShortlist,
  removeShortlist as removeShortlistFromStore,
} from '@store/actionSlices/shortlist'
import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import Navigation from '@components/navigation'
import ShortlistForm from '@components/shortlist-form'
import { ArrowTurnSvg } from '@components/svg'
import UnitListing from '@components/unit-listing'

import { Unit } from '@api/building'

import { getSession } from '@utilities/firebase-util'

export interface ShortlistProps {
  projectIdentity: ProjectIdentity
  shortlist: Array<Unit>
  session: SessionMap | undefined
}

const Shortlist = ({ projectIdentity, shortlist, session }: ShortlistProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    if (shortlist.length === 0 && session) {
      const { activeRoute } = session
      history.push(activeRoute)
    }
  }, [shortlist])

  const removeShortlist = (unit: Unit) => {
    if (window.confirm('Remove shortlisted Unit?')) {
      dispatch(filterShortlist(unit.name))
    }
  }

  const removeAllShortlistedUnits = () => {
    dispatch(removeShortlistFromStore())
  }

  return (
    <Container className="overflow-hidden px-10">
      <div className="mx-4 h-full lg:mx-10">
        <Navigation hideNavMenu />
        <div className="no-scrollbar my-6 grid h-80vh grid-cols-2 gap-4 overflow-x-auto border-t-2  border-white bg-opacity-0">
          <div className="my-4 flex w-full">
            <div>
              <Link
                to={session?.activeRoute || 'vision'}
                className="flex w-14 items-center rounded-full bg-white p-2 font-GTWalsheimPro"
              >
                <ArrowTurnSvg className="h-10 w-10 text-black" />
              </Link>
            </div>
            <div className="mx-5 mt-5 w-full xl:mx-20 xl:w-3/5">
              <ShortlistForm {...{ shortlist, projectIdentity }} />
            </div>
          </div>
          <div className="w-full">
            <div className="avavenirLight my-4 flex items-center justify-between text-base text-white lg:text-xl">
              <span>Shortlist</span>
              <button
                type="button"
                className="underline"
                onClick={removeAllShortlistedUnits}
              >
                Clear All
              </button>
            </div>
            <UnitListing units={shortlist} trigger={removeShortlist} />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore, shortlist }: RootStateFirebase) => ({
    shortlist,
    projectIdentity,
    session: getSession(firestore),
  })
)(Shortlist)
