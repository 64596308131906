import React from 'react'

import { SvgProps } from '@components/svg/types'

const CloseCircleSvg = ({
  width,
  height,
  className,
  size,
  styles,
  fill,
}: SvgProps) => {
  const sizeValue = size || 'm'

  const scale = {
    xl: 2.0,
    lg: 1.0,
    m: 0.8,
    s: 0.5,
    xs: 0.5,
  }

  return (
    <svg
      className={className}
      style={{
        ...styles,
        ...{ height: 'auto', transform: `scale(${scale[sizeValue]})` },
      }}
      width={width || '50'}
      height={height || '50'}
    >
      <path
        d="M 27.143 25.486 C 26.686 25.029 26 25.029 25.543 25.486 C 25.086 25.943 25.086 26.629 25.543 27.086 L 32.4 33.943 C 32.571 34.171 32.857 34.286 33.143 34.286 C 33.429 34.286 33.714 34.171 33.943 33.943 C 34.4 33.486 34.4 32.8 33.943 32.343 Z M 20.743 22.4 C 20.971 22.629 21.257 22.743 21.543 22.743 C 21.829 22.743 22.114 22.629 22.343 22.4 C 22.8 21.943 22.8 21.257 22.343 20.8 L 15.657 14.057 C 15.2 13.6 14.514 13.6 14.057 14.057 C 13.6 14.514 13.6 15.2 14.057 15.657 Z M 33.943 14.057 C 33.486 13.6 32.8 13.6 32.343 14.057 L 14.057 32.343 C 13.6 32.8 13.6 33.486 14.057 33.943 C 14.286 34.171 14.571 34.286 14.857 34.286 C 15.143 34.286 15.429 34.171 15.657 33.943 L 33.943 15.657 C 34.4 15.2 34.4 14.514 33.943 14.057 Z M 24 0 C 10.743 0 0 10.743 0 24 C 0 37.257 10.743 48 24 48 C 31.086 48 37.714 44.914 42.286 39.543 C 42.686 39.086 42.629 38.343 42.171 37.943 C 41.714 37.543 40.971 37.6 40.571 38.057 C 36.457 42.914 30.4 45.714 24 45.714 C 12 45.714 2.286 36 2.286 24 C 2.286 12 12 2.286 24 2.286 C 36 2.286 45.714 12 45.714 24 C 45.714 27.943 44.629 31.829 42.629 35.2 C 42.286 35.714 42.457 36.457 43.029 36.743 C 43.543 37.086 44.286 36.914 44.571 36.343 C 46.8 32.629 48 28.343 48 23.943 C 48 10.743 37.257 0 24 0 Z"
        fill={fill || 'hsl(0, 0%, 100%)'}
      ></path>
    </svg>
  )
}

export default CloseCircleSvg
