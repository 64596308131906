import React from 'react'

import type { ProjectIdentity } from '@store/types'

import { Metas, Unit as Shortlist } from '@api/building'
import {
  ShortlistDocument,
  ShortlistFormData,
  selectFromResult,
  useGetShortlistDocumentsQuery,
  usePostShortlistedMutation,
} from '@api/shortlisted'

import InputHandler from '@utilities/form-util'
import { notifyError, notifySuccess } from '@utilities/notifier'

export interface ShortlistFormProps {
  projectIdentity: ProjectIdentity
  shortlist: Array<Shortlist>
}

const PLACEHOLDER_IMAGE = 'https://via.placeholder.com/480x270.png'
const bucketUrlLegacy = process.env.REACT_APP_BUCKET_LEGACY

const ShortlistForm = ({
  shortlist,
  projectIdentity: { projectId, projectUUID },
}: ShortlistFormProps) => {
  const MAXIMUM_DOCUMENT_LENGTH_TO_SEND =
    Number(process.env.REACT_APP_MAXIMUM_DOCUMENT_LENGTH_TO_SEND) || 6
  const [documentsSelected, setDocumentsSelected] = React.useState<
    Array<ShortlistDocument>
  >([])

  const [postShortlisted, result] = usePostShortlistedMutation()
  const [formValid, setFormValid] = React.useState(false)

  const [name, setName] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [email, setEmail] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [phone, setPhone] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [agent, setAgent] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [agentMobile, setAgentMobile] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const [agentEmail, setAgentEmail] = React.useState({
    value: '',
    isValid: true,
    message: '',
  })

  const { documents } = useGetShortlistDocumentsQuery(
    { projectId },
    { selectFromResult }
  )

  React.useEffect(
    () =>
      setFormValid(
        email.value !== '' &&
          email.isValid &&
          phone.value !== '' &&
          phone.isValid &&
          name.value !== '' &&
          name.isValid
      ),
    [email, phone, name]
  )

  const getUnitImageUrl = (metas: Metas) => {
    const { floorImage, viewLineImages } = metas

    if (floorImage) {
      return `${bucketUrlLegacy}/${projectUUID}/floorplans/preview/${floorImage}`
    }

    if (viewLineImages && viewLineImages.length > 0) {
      const [viewLineImage] = viewLineImages
      return `${bucketUrlLegacy}/${projectUUID}/views/${viewLineImage.src}`
    }

    return PLACEHOLDER_IMAGE
  }

  const sendShortlist = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData: ShortlistFormData = {
      projectId,
      title: projectId.split('-').join(' ').toUpperCase(),
      name: name.value,
      email: email.value,
      mobile: phone.value,
      agent: agent.value,
      agentMobile: agentMobile.value,
      agentEmail: agentEmail.value,
      items: shortlist.map((unit) => ({
        name: unit.name,
        configuration: unit.metas.bedConfig,
        price: `$${unit.metas.price}`,
        area: unit.metas.totalArea.toString(),
        image: getUnitImageUrl(unit.metas),
      })),
      documents: documentsSelected.map((doc) => ({
        title: doc.fileName,
        url: `${bucketUrlLegacy}/${projectUUID}/documents/${doc.fileName}`,
      })),
    }
    postShortlisted(formData)
  }

  const validateDocumentLength = (): boolean =>
    documentsSelected.length <= MAXIMUM_DOCUMENT_LENGTH_TO_SEND

  React.useEffect(() => {
    if (!result.isUninitialized) {
      if (result.isSuccess) {
        notifySuccess('Your shortlist has been sent to your email.')
      }

      if (result.isError) {
        notifyError('Shortlist was not sent. Something went wrong.')
      }
    }
  }, [result])

  return (
    <form
      className="white-form mb-10 h-80vh w-full overflow-x-auto"
      onSubmit={sendShortlist}
    >
      <div className="avavenirLight mb-4 text-base lg:text-xl">
        Email Shortlist
      </div>
      <div className="mb-4">
        <label className="input-label" htmlFor="name">
          Name
        </label>
        <input
          onChange={(e) => InputHandler(e, setName)}
          className={`focus:shadow-outline focus:outline-none ${
            name.isValid ? 'input-gray' : 'input-gray-error'
          }`}
          id="name"
          type="text"
          placeholder="John Doe"
          value={name.value}
          required
        />
        <div className="h-4">
          {name.message && (
            <p className="text-xs italic text-red-500">{name.message}</p>
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="input-label" htmlFor="email">
          Email
        </label>
        <input
          onChange={(e) => InputHandler(e, setEmail)}
          className={`focus:shadow-outline focus:outline-none ${
            email.isValid ? 'input-gray' : 'input-gray-error'
          }`}
          id="email"
          type="email"
          placeholder="example@gmail.com"
          value={email.value}
          required
        />
        <div className="h-4">
          {email.message && (
            <p className="text-xs italic text-red-500">Invalid Email Format.</p>
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="input-label" htmlFor="phone">
          Phone
        </label>
        <input
          onChange={(e) => InputHandler(e, setPhone)}
          className={`focus:shadow-outline focus:outline-none ${
            phone.isValid ? 'input-gray' : 'input-gray-error'
          }`}
          id="phone"
          type="phone"
          placeholder="+61 0000 000 000*"
          value={phone.value}
          required
        />
        <div className="h-4">
          {phone.message && (
            <p className="text-xs italic text-red-500">{phone.message}</p>
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="input-label" htmlFor="agent">
          Agent Name
        </label>
        <input
          onChange={(e) => InputHandler(e, setAgent)}
          className="focus:shadow-outline input-gray focus:outline-none"
          id="agent"
          type="text"
          placeholder="Agent Name"
          value={agent.value}
        />
        <div className="h-4">
          {agent.message && (
            <p className="text-xs italic text-red-500">{agent.message}</p>
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="input-label" htmlFor="agentEmail">
          Agent Email
        </label>
        <input
          onChange={(e) => InputHandler(e, setAgentEmail)}
          className={`focus:shadow-outline focus:outline-none ${
            email.isValid ? 'input-gray' : 'input-gray-error'
          }`}
          id="agentEmail"
          type="email"
          placeholder="example@gmail.com"
          value={agentEmail.value}
        />
        <div className="h-4">
          {agentEmail.message && (
            <p className="text-xs italic text-red-500">Invalid Email Format.</p>
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="input-label" htmlFor="agentMobile">
          Agent Phone
        </label>
        <input
          onChange={(e) => InputHandler(e, setAgentMobile)}
          className="focus:shadow-outline input-gray focus:outline-none"
          id="agentMobile"
          type="phone"
          placeholder="+61 0000 000 000"
          value={agentMobile.value}
        />
        <div className="h-4">
          {agentMobile.message && (
            <p className="text-xs italic text-red-500">{agentMobile.message}</p>
          )}
        </div>
      </div>

      <div className="w-full">
        <ul className="mb-4 w-full">
          {documents &&
            documents.map((doc: ShortlistDocument) => (
              <li
                key={doc.id}
                className="flex w-full py-2 font-GTWalsheimPro text-black"
              >
                <div title={doc.name} className="truncate">
                  {doc.name}
                </div>
                <div className="ml-auto">
                  <input
                    className="checkbox-gray ml-auto"
                    type="checkbox"
                    checked={
                      documentsSelected.find((res) => res.id === doc.id) !==
                      undefined
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDocumentsSelected([...documentsSelected, doc])
                        return
                      }
                      setDocumentsSelected([
                        ...documentsSelected.filter((res) => res.id !== doc.id),
                      ])
                    }}
                  />
                </div>
              </li>
            ))}
        </ul>
      </div>
      {!validateDocumentLength() && (
        <div className="mb-3 text-red-500">{`You can attach a maximum of ${MAXIMUM_DOCUMENT_LENGTH_TO_SEND} items.`}</div>
      )}
      <div>
        <button
          className={`focus:shadow-outline ml-auto flex w-3/5 items-center justify-center rounded-full focus:outline-none ${
            formValid && validateDocumentLength()
              ? 'btn-sky-rnd-full'
              : 'btn-gray-rnd-full'
          }`}
          type="submit"
          disabled={!formValid || !validateDocumentLength()}
        >
          {result.isLoading && (
            <div
              style={{ borderTopColor: 'transparent' }}
              className="border-white-400 mr-1 h-3 w-3 animate-spin rounded-full border-2 border-dotted"
            ></div>
          )}
          Send Shortlist
        </button>
      </div>
    </form>
  )
}

export default ShortlistForm
