import React, { ChangeEvent } from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import { connect } from 'react-redux'

import {
  PlayerControlInterface,
  ProjectIdentity,
  RootStateFirebase,
} from '@store/types'

import FirebaseControlQuery from '@utilities/firebase-control-query'

export interface ComponentPropsInterface {
  galleryName: string
  playerSession: PlayerControlInterface | undefined
  projectIdentity: ProjectIdentity
}

const ScrubBar = ({
  galleryName,
  playerSession,
  projectIdentity,
}: ComponentPropsInterface) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })

  const [videoLengthInMilliseconds, setVideoLengthInMilliseconds] =
    React.useState(0)
  const [playerState, setPlayerState] = React.useState('')
  const [isBuffering, setBufferStatus] = React.useState(false)
  const [
    currentVideoPositionInMilliseconds,
    setCurrentVideoPositionInMilliseconds,
  ] = React.useState(-1)
  const [rangeValue, setRangeValue] = React.useState(0)
  const [intervalId, setIntervalId] =
    React.useState<ReturnType<typeof setInterval>>()

  const updateScrubPositionInFirebase = async (value: number) => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.scrubPositionInSeconds`]: value,
    })
  }

  const resetVideoLengthInFirebase = async () => {
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.videoLengthInSeconds`]: 0,
    })
  }

  const resetCurrentVideoAndScrubPositionInSecondsInFirebase = async () => {
    setCurrentVideoPositionInMilliseconds(-1)
    await firebaseControlQuery.update({
      [`${galleryName}.playerControl.currentVideoPositionInSeconds`]: -1,
      [`${galleryName}.playerControl.scrubPositionInSeconds`]: -1,
    })
  }

  const toTimeString = (milliseconds: number): string =>
    new Date(milliseconds).toISOString().slice(11, 19)

  const resetTimer = () => {
    if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(undefined)
    }
  }

  const setTimer = () => {
    if (!intervalId) {
      setIntervalId(
        setInterval(() => {
          setRangeValue((previousValue) => previousValue + 250)
        }, 250)
      )
    }
  }

  const handleOnchange = (valueInMilliseconds: number) => {
    resetTimer()
    setRangeValue(valueInMilliseconds)
  }

  const handleOnChangeComplete = async (valueInMilliseconds: number) => {
    await updateScrubPositionInFirebase(valueInMilliseconds / 1000)
  }

  React.useEffect(() => {
    if (playerState === 'pause') {
      resetTimer()
    }
  }, [playerState])

  React.useEffect(() => {
    if (isBuffering) {
      resetTimer()
    }
  }, [isBuffering])

  React.useEffect(() => {
    if (currentVideoPositionInMilliseconds >= 0) {
      setRangeValue(currentVideoPositionInMilliseconds)
      resetCurrentVideoAndScrubPositionInSecondsInFirebase()
    }
  }, [currentVideoPositionInMilliseconds])

  React.useEffect(() => {
    if (playerState === 'play' && !isBuffering && !intervalId) {
      setTimer()
    }
  }, [currentVideoPositionInMilliseconds, playerState, isBuffering])

  React.useEffect(() => {
    if (rangeValue > videoLengthInMilliseconds) {
      setRangeValue(0)
    }
  }, [rangeValue])

  React.useEffect(() => {
    setVideoLengthInMilliseconds(0)
    return () => {
      resetTimer()
      setRangeValue(0)
      updateScrubPositionInFirebase(-1)
      resetVideoLengthInFirebase()
    }
  }, [])

  React.useEffect(() => {
    if (playerSession) {
      const {
        playerState: playerStateFirebase,
        videoLengthInSeconds: videoLengthInSecondsFirebase,
        isBuffering: isBufferingFirebase,
        currentVideoPositionInSeconds: currentVideoPositionInSecondsFirebase,
      } = playerSession

      setPlayerState(playerStateFirebase)
      setBufferStatus(isBufferingFirebase)
      setVideoLengthInMilliseconds(videoLengthInSecondsFirebase * 1000)
      setCurrentVideoPositionInMilliseconds(
        currentVideoPositionInSecondsFirebase * 1000
      )
    }
  }, [playerSession])

  return (
    <div className="flex items-center">
      <span
        className={`text-sm ${
          videoLengthInMilliseconds > 0 ? 'text-blue-500' : 'text-gray-500'
        }`}
      >
        {videoLengthInMilliseconds > 0 ? toTimeString(rangeValue) : '00:00:00'}
      </span>
      <div className="w-full px-3">
        <InputRange
          maxValue={videoLengthInMilliseconds}
          minValue={0}
          value={rangeValue}
          onChange={(value) => handleOnchange(Number(value))}
          onChangeComplete={(value) => handleOnChangeComplete(Number(value))}
          formatLabel={(value) =>
            videoLengthInMilliseconds > 0
              ? toTimeString(rangeValue)
              : '00:00:00'
          }
        />
      </div>
      <span
        className={`text-sm ${
          videoLengthInMilliseconds > 0 ? 'text-blue-500' : 'text-gray-500 '
        }`}
      >
        {videoLengthInMilliseconds > 0
          ? toTimeString(videoLengthInMilliseconds)
          : '00:00:00'}
      </span>
    </div>
  )
}

export default connect(({ projectIdentity }: RootStateFirebase) => ({
  projectIdentity,
}))(ScrubBar)
