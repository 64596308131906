import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'

import { setOnlineStatus } from '@store/actionSlices/statusIndicator'
import { RootStateTypeExtra } from '@store/types'

import prepareHeaders from '@api/headers'

import {
  fetchResponseFromLocalStorage,
  isExistsInTheOfflineEndpointList,
  isOffline,
  storeResponseToLocalStorage,
} from '@utilities/offline-handler-util'
import { isTokenExpired, renewUserAccessToken } from '@utilities/token-helper'

const mutex = new Mutex()

const baseUrl = process.env.REACT_APP_API_URL

const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders })

const diagnoseResponse = (response: any): boolean => {
  if (!response) return false

  if (response.error && response.error.status === 'FETCH_ERROR') {
    return false
  }

  return true
}

const executeBaseQuery = async (args: any, api: any, extraOptions: any) => {
  if (!isOffline()) {
    const response = await baseQuery(args, api, extraOptions)
    return response
  }

  if (!isExistsInTheOfflineEndpointList(args)) {
    const response = await baseQuery(args, api, extraOptions)
    return response
  }

  const responseFromLocalStorage = fetchResponseFromLocalStorage(args)

  if (responseFromLocalStorage) {
    return responseFromLocalStorage
  }

  const response = await baseQuery(args, api, extraOptions)
  if (diagnoseResponse(response)) {
    storeResponseToLocalStorage(args, response)
  }
  return response
}

const baseQueryInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let isOnline = true

  const { token } = api.getState() as RootStateTypeExtra

  if (isTokenExpired(token)) {
    await mutex.waitForUnlock()

    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        await renewUserAccessToken(token, api, extraOptions)
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
    }
  }

  const response = await executeBaseQuery(args, api, extraOptions)

  if (!diagnoseResponse(response)) {
    isOnline = false
  }

  api.dispatch(setOnlineStatus(isOnline))

  return response
}

export default baseQueryInterceptor
