import React from 'react'
import { connect } from 'react-redux'

import {
  GalleryItemInterface,
  ProjectIdentity,
  RootStateFirebase,
  SessionMap,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Navigation from '@components/navigation'

import {
  selectFromResult as selectFromTeamResult,
  useGetTeamQuery,
} from '@api/team'

import FirebaseControlQuery from '@utilities/firebase-control-query'
import { getSession } from '@utilities/firebase-util'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

const Team = ({ session, projectIdentity }: PagePropsInterface) => {
  const firebaseControlQuery = FirebaseControlQuery({ projectIdentity })
  const galleryName = 'team'

  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>()
  const [activeGalleryItemIndex, setActiveGalleryItemIndex] = React.useState(0)
  const [isSlideShowPlaying, setSlideShowPlayingStatus] =
    React.useState<boolean>(false)
  const [slideShowTransitionTime, setSlideShowTransitionTime] =
    React.useState<number>(3000)

  const [galleryItemsCount, setGalleryItemsCount] = React.useState<number>(0)

  const teamPayload = useGetTeamQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromTeamResult }
  )

  const handleItemClick = async (index: number) => {
    firebaseControlQuery.update({
      [`${galleryName}.galleryControl.isPlaying`]: false,
      [`${galleryName}.galleryControl.activeItemIndex`]: index,
    })
  }

  React.useEffect(() => {
    if (teamPayload) {
      setGalleryItemsCount(teamPayload.teamData.length)
    }
  }, [teamPayload])

  React.useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    let index = activeGalleryItemIndex

    if (isSlideShowPlaying) {
      if (galleryItemsCount > 1) {
        if (index === galleryItemsCount - 1) {
          index = 0
        } else {
          index += 1
        }

        const timeout = setTimeout(() => {
          setActiveGalleryItemIndex(index)
        }, slideShowTransitionTime)
        setTimeoutId(timeout)
      }
    }
  }, [isSlideShowPlaying, activeGalleryItemIndex, galleryItemsCount])

  React.useEffect(() => {
    if (session) {
      const {
        team: {
          galleryControl: {
            activeItemIndex: activeItemIndexFirebase,
            isPlaying: isPlayingFirebase,
            transitionTime: transitionTimeFirebase,
          },
        },
      } = session
      setActiveGalleryItemIndex(activeItemIndexFirebase)
      setSlideShowPlayingStatus(isPlayingFirebase)
      setSlideShowTransitionTime(transitionTimeFirebase)
    }
  }, [session])

  React.useEffect(
    () => () => {
      firebaseControlQuery.update({
        [`${galleryName}.galleryControl.isPlaying`]: false,
        [`${galleryName}.galleryControl.transitionTime`]:
          slideShowTransitionTime,
        [`${galleryName}.galleryControl.activeTabIndex`]: 0,
        [`${galleryName}.galleryControl.activeItemIndex`]: 0,
      })
    },
    []
  )

  return (
    <Container className="overflow-hidden px-10">
      <div className="mx-4 h-full lg:mx-10">
        <Navigation />
        <DataHandler payload={{ ...teamPayload, data: teamPayload.teamData }}>
          <div className="background-none relative my-6 flex h-4/6 flex-wrap items-center justify-center border-t-2 border-white bg-opacity-0 sm:h-5/6">
            <div className="flex flex-wrap items-center justify-center">
              {teamPayload.teamData.map(
                (item: GalleryItemInterface, index: number) => (
                  <button
                    key={item.title}
                    className={`mx-2 my-3 rounded-full px-7 py-3 font-bold ipad-p:px-6 ipad-air-2-p:px-4 ipad-air-2-p:py-2 ${
                      activeGalleryItemIndex !== index
                        ? 'cursor-hand bg-white text-gray-700 box-shadow'
                        : 'cursor-default bg-black text-white box-shadow-active'
                    }`}
                    onClick={() => handleItemClick(index)}
                    type="button"
                    tabIndex={index}
                    disabled={activeGalleryItemIndex === index}
                  >
                    <span className="whitespace-nowrap">{item.title}</span>
                  </button>
                )
              )}
            </div>
          </div>
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(Team)
