import React from 'react'
import { connect } from 'react-redux'

import { ProjectIdentity, RootStateFirebase, SessionMap } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'
import Navigation from '@components/navigation'

import {
  selectFromResult as selectFromGalleryResult,
  useGetGalleryQuery,
} from '@api/gallery'

import { getSession } from '@utilities/firebase-util'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

const Gallery = ({ session, projectIdentity }: PagePropsInterface) => {
  const galleryPayload = useGetGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromGalleryResult }
  )

  return (
    <Container>
      <div className="mx-4 lg:mx-10">
        <Navigation />
        <div className="my-6 border-b-2"></div>
        <DataHandler
          payload={{ ...galleryPayload, data: galleryPayload.galleries }}
        >
          <GalleryHandler
            galleryName="design"
            galleries={galleryPayload.galleries}
            gallerySession={session?.design}
            enableSlideShow
          />
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(({ projectIdentity, firestore }: RootStateFirebase) => ({
  session: getSession(firestore),
  projectIdentity,
}))(Gallery)
